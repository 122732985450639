import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/styles';

const CustomTabs = styled(Tabs)(({theme}) => ({
    '& .MuiTabs-indicator': {

        backgroundColor: theme.palette['--color-primary'],
    },
}));

const TabStyle = styled(Tab)(({ theme, selected }) => ({
    color: selected ? theme.palette["--color-primary"] : theme.palette["--color-search"],
}));

NavTabs.propTypes = {
    items: PropTypes.array.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    tabValue: PropTypes.number.isRequired,
};

export default function NavTabs({ items, handleTabChange, tabValue }) {
    const theme = useTheme();
    return (
        <CustomTabs
            sx={{ ml: 3.5 }}
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            textColor={theme.palette['--color-primary']}
            indicatorColor={theme.palette['--color-primary']}
            aria-label="nav tabs">
            {items.map(item => {
                return <TabStyle label={item.label} selected={tabValue === item.id} />;
            })}
        </CustomTabs>
    );
}
