import React from 'react';
import { useState } from 'react';
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
    Stack, Select,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, Typography, Grid, Box, Button, useTheme
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useTranslation } from 'react-i18next';
import {IdentityService} from 'src/api/services';
import BaseButton from 'src/components/buttons/BaseButton';
import {styled} from "@mui/system";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {IdentityType, IdentityTypeSelect, IdentityVerificationStatus, PersonalIdentityType} from "../../../../constants";
import {getSelectOptions, OrganisationIdentityType} from "../../../../constants";
import BaseSelect from 'src/components/BaseSelect';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function AddPersonalIdentityForm({ setModalStatus, setSnackbarStatus, setMessage, successCallback, countries }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [identityType, setIdentityType] = useState(1);

    const IdentitySchema = Yup.object().shape({
        email: Yup.string()
            .email(t("form.__i18n_ally_root__.validation.email"))
            .required(t("email-is-required")),

        companyName: Yup.string()
            .when([], {
            is: () => identityType === 1,
            then: Yup.string().required(t("organization-is-required")),
            otherwise: Yup.string(),
          })
    });

    const formik = useFormik({
        initialValues: {
            identityName: '',
            email: '',
            companyName: '',
            externalId: '',
            firstName: '',
            lastName: ''
          },
          validationSchema: IdentitySchema,
          onSubmit: (values, {setSubmitting}) => {

            const payload = {
                identity_name: values.identityName,
                compliancy_type: identityType,
                contact_email: values.email,
                external_id: values.externalId,
                first_name: values.firstName,
                last_name: values.lastName,
                company_name: values.companyName
            }

            IdentityService.createIdentity(payload)
            .then((response) => {
                /* throw new Error("Something went wrong!"); */
                if (response.data.meta.code === 200) {
                    setMessage(t('Identity added successfully'));
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw (t("could-not-be-added"));
                }
            })
            .catch((err) => {
                setMessage(t('could-not-be-added', {name: values.firstName}));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            })
            .finally(() => {
                setSubmitting(false);
            })

          }

    })


/*     const addIdentity =() => {
        
        let payload = {
            identity_name: itentityName,
            compliancy_type: identityType,
            contact_email: email,
            external_id: externalId,
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
        }

        IdentityService.createIdentity(payload)
        .then((response) => {
            if (response.data.meta.code === 200) {
                setMessage(t('Identity added successfully'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw (t("could-not-be-added"));
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-added', {name: firstName}));
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        });
    }
 */
    const theme = useTheme();
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;


    return (
        <Stack sx={{display: "flex"}} spacing={2}>
                <Typography sx={{ textAlign: 'left', color: theme.palette['--main-text-color'] }}>
                    {'Identity Type'}
                </Typography>

                <RadioGroup row
                            name="controlled-radio-buttons-group"
                            value={identityType}
                            onChange={(event) => setIdentityType(event.target.value)}
                >
                    {IdentityType().map((option) => (
                        <FormControlLabel sx={{color: theme.palette['--main-text-color']}} value={option.value} control={<Radio/>} label={option.name}/>
                    ))}
                </RadioGroup>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label={'Identity Name'}
                            name="firstName"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('identityName')}
                        />
            
                        <TextField
                            fullWidth
                            label={'External/Site ID'}
                            name="lastName"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('externalId')}
                        />

                        <TextField
                            fullWidth
                            label={'Contact E-mail'}
                            name="lastName"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                        />
                        
                        {identityType == 2 ? (<>
                            <TextField
                            fullWidth
                            label={'First Name'}
                            name="telephone"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('firstName')}
                        />      
                        
                        <TextField
                            fullWidth
                            label={'Last Name'}
                            name="telephone"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('lastName')}
                        />
                        </>) : (<>
                        <TextField
                            fullWidth
                            label={'Company Name'}
                            name="telephone"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            {...getFieldProps('companyName')}
                            error={Boolean(touched.companyName && errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                        />
                        </>)}


                        <Stack sx={{ display: "block", alignItems: "right"  }} direction="row" spacing={2}>
                            <BaseButton
                                type="submit"
                                variant="contained"
                                disabled={loading}
                                onClick={() => setModalStatus(false)}
                                >
                                {t('cancel')}
                            </BaseButton>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                {t('Save')}

                            </LoadingButton>
                            {/* <BaseButton
                                type="submit"
                                color="--color-primary"
                                variant="contained"
                                loading={loading}
                                >
                                {t('Save')}
                            </BaseButton> */}
                        </Stack>
                    </Form>
                </FormikProvider>

        </Stack>
  );
}
