import api from "src/api/Api";
import urls from "src/api/Urls";


export const listIdentities = (params) => {
    return api.get(urls.list_identities, { params: params });
}

export const createIdentity = (params) => {
    return api.post(urls.create_identity, params);
}

export const uploadFile = (payload, onUploadProgress) => {
    return api.post(urls.upload_file, payload, onUploadProgress);
}

export const downloadFile = (params) => {
    return api.post(urls.download_file, params, {responseType: 'blob'});
}

export const listAddresses = (params) => {
    return api.get(urls.list_addresses, { params: params });
}

export const listSubCustomers = (params) => {
    return api.get(urls.list_sub_customers, { params: params });
}

export const createSubCustomer = (params) => {
    return api.post(urls.create_sub_customer, params);
}

export const updateSubCustomer = (params, id) => {
    return api.put(urls.update_sub_customer(id), params);
}

export const deleteSubCustomer = (params, id) => {
    return api.delete(urls.delete_sub_customer(id), { params: params });
}

export const createAddress = (params) => {
    return api.post(urls.create_address, params);
}

export const updateAddress = (params, id) => {
    return api.put(urls.update_address(id), params);
}

export const deleteAddress = (params, id) => {
    return api.delete(urls.delete_address(id), { params: params });
}

export const deleteIdentity = (params, id) => {
    return api.delete(urls.delete_identity(id), { params: params });
}

export const updateIdentity = (params, id) => {
    return api.put(urls.update_identity(id), params);
}

//-------------------------------------------------------------------------

export const fetchComplianceRequirements = (params) => {
    return api.get(urls.get_compliancy_requirement, {params});
}

