import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Reports from "./reports/Reports";
// ----------------------------------------------------------------------


export default function ReportsTab() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: 'Reports', component: <Reports /> }
    ];

    const TITLE = 'Reports';

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
