// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid, Typography, List, ListItem, ListItemIcon
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "src/components/buttons/BaseButton";

import {makeStyles} from '@mui/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // You can import the desired bullet point icon


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function ProductPortingInfoForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();


    
    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const classes = useStyles();



    useEffect(() => {

        console.log(formData);
        }, []);

    return (
        <>
            <TableFilterContainer>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h5" align="left">Individual</Typography>
                        <br/>

                            <>
                            {formData.porting_pers_descriptions && (
                                <>
                                <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {"Descriptions"}
                                        </Typography>
                                        <br/>

                                    {formData.porting_pers_descriptions.map((item, index) => (
                                    <>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {item.header}
                                        </Typography>
                                    <Typography sx={{ textAlign: 'left' }}>
                                        <ul>
                                        {item.description.map((i,key) => {
                                            return <div key={key}>{'• '+i}</div>
                                        })}
                                        </ul>
                                    </Typography>
                                    </>
                                ))}
                                </>
                            )}
                            <br/>
                            {formData.porting_pers_fields && (
                                <>
                                <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {"Fields"}
                                        </Typography>
                                        <br/>
                                    {formData.porting_pers_fields.map((item, index) => (
                                    <>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Field Type: '+item.field_type}
                                    </Typography>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Field Name: '+item.name}
                                    </Typography>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Reusable: '+item.reusable}
                                    </Typography>
                                    <br/>
                                    </>
                                    
                                ))}
                                </>
                            )}
                            </>
                        <br/>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="h5" align="left">Company</Typography>
                        <br/>
                            <>
                            {formData.porting_comp_descriptions && (
                                <>
                                <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {"Descriptions"}
                                        </Typography>
                                        <br/>

                                    {formData.porting_comp_descriptions.map((item, index) => (
                                    <>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {item.header}
                                        </Typography>
                                    <Typography sx={{ textAlign: 'left' }}>
                                        <ul>
                                        {item.description.map((i,key) => {
                                            return <div key={key}>{'• '+i}</div>
                                        })}
                                        </ul>
                                    </Typography>
                                </>
                                ))}
                                </>
                            )}
                            <br/>
                            {formData.porting_comp_fields && (
                                <>
                                <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {"Fields"}
                                    </Typography>
                                    <br/>
                                    {formData.porting_comp_fields.map((item, index) => (
                                    <>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Field Type: '+item.field_type}
                                    </Typography>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Field Name: '+item.name}
                                    </Typography>
                                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                                        {'Reusable: '+item.reusable}
                                    </Typography>
                                    <br/>
                                    </>
                                    
                                ))}
                                </>
                            )}
                            </>
                        <br/>
                    </Grid>
                </Grid>

            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Close'}
                        onClick={() => setModalStatus(false)}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

