// ----------------------------------------------------------------------

export default function TextField(theme) {
    return {
        MuiTextField: {
            styleOverrides: {
                root: {
                color: theme.palette['--color-search'],
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                        color: theme.palette['--color-search'],
                    },
                    '.MuiOutlinedInput-input': {
                        color: theme.palette['--color-search']
                      }
                }
            }
        },
    };
}
