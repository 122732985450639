import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useTranslation } from 'react-i18next';
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

export default function BuyNumberForm({ formData, setModalStatus, setOpenBuyNumber, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);


    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {"You are buying "+ formData.did}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </BaseButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={setOpenBuyNumber}
                    >
                        {'Buy'}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
