import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {Box, Grid, FormControl, Typography, InputLabel, Stack} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import {DefaultPaginationData, getSelectOptions} from 'src/constants';
import SearchButton from 'src/components/buttons/SearchButton';
import dayjs from "dayjs";
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import {CommonService, ChannelService} from 'src/api/services';
import BaseCard from 'src/components/BaseCard';
import BaseSelect from "../../../components/BaseSelect";
import BaseButton from "../../../components/buttons/BaseButton";
import {rowArrayToObject} from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import RemoveDialog from "./removechannel";
import UpdateDialog from "./updatedialog";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function YourChannels({ }) {
    const { t } = useTranslation();

    const tableHead = [
        { key: "country", label: t("Country") },
        { key: "product", label: t("Product") },
        { key: "quantity", label: t("Quantity") },
        { key: "effective_start", label: t("Effective Start") },
        { key: "effective_end", label: t('Effective End') },
        { key: "actions", label: t('actions') },
    ];

    const tableFieldMapping = {
        country: { key: "country", index: 0 },
        product: { key: "product", index: 1 },
        quantity: { key: "quantity", index: 2 },
        effective_start: { key: "effective_start", index: 3 },
        effective_end: { key: "effective_end", index: 4 },
        product_id: { key: "product_id", index: 5, noRender:true },
    };

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [countries, setCountries] = useState([]);
    const [filterCountry, setFilterCountry] = useState("");
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const TitleStyle = styled(Typography)(({ theme }) => ({
        color: theme.palette.background.contrastText,
        display: "inline"
    }));

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchYourChannels = () => {
        setData([]);
        const params = {
            size:10,
            page: paginationData.page + 1,
            country_code: filterCountry ? filterCountry == 0 ? undefined : filterCountry : undefined,
        };
        setLoadingData(true);
        ChannelService.yourChannels(params)
            .then((response) => {
                if (!response.data || !response.data.data) {
                    throw(t("session-expired"));
                }
                let items = [];
                setTotalCount(response.data.data.count);
                setData([]);
                for (const idx in response.data.data.items) {
                    let item = new Array(tableHead.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in tableFieldMapping) {
                            item[tableFieldMapping[key].index] = {
                                ...tableFieldMapping[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchYourChannels();
        fetchCountries();
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if(d.key==="effective_start" || d.key==="effective_end"){
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "updateChannel") {
            setOpenUpdateDialog(!openUpdateDialog);
        }
        if (modalType === "removeChannel") {
            setOpenRemoveDialog(!openRemoveDialog);
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        console.log(data[index])
        return (
            <Stack direction="row" justifyContent='start' spacing={2}>
                <BaseButton color={'--color-primary'} label={'Update'}
                            onClick={() => modalHandler("updateChannel", index)}/>
                <BaseButton color={'--color-warning'} label={'Remove'}
                            onClick={() => modalHandler("removeChannel", index)}/>
            </Stack>
        )
    }
    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseModal title={'Remove Channel'} open={openRemoveDialog} setOpen={setOpenRemoveDialog} children={<RemoveDialog successCallback={fetchYourChannels} formData={selectedRow} setModalStatus={setOpenRemoveDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Update Channel'} open={openUpdateDialog} setOpen={setOpenRemoveDialog} children={<UpdateDialog successCallback={fetchYourChannels} formData={selectedRow} setModalStatus={setOpenUpdateDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
                <TableFilterContainer>
                    <Grid sx={{ alignItems: "center" }} container spacing={4}>
                        <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                                <BaseSelect
                                    label={'country'}
                                    labelId="filter-country-label"
                                    name="country"
                                    color="secondary"
                                    value={filterCountry }
                                    onChange={event => { setFilterCountry(event.target.value) }}
                                >
                                    {getSelectOptions(countries)}
                                </BaseSelect>
                            </FormControl>
                        </Grid>

                        <SearchButtonContainer item md={2} xs={12}>
                            <SearchButton onClick={() => { fetchYourChannels() }} />
                        </SearchButtonContainer>
                    </Grid>
                    <br/>
                    <SearchButtonContainer item md={12} xs={12}>
                        <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                            <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                            </TitleStyle>
                            </Box>
                        </Box>
                    </SearchButtonContainer>
                </TableFilterContainer>
            </BaseCard>
            <br />
            <BaseTable
                head={tableHead}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />

        </>
    );

}

