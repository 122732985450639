import React from 'react';
import { useState, useEffect } from 'react';
import {Box, IconButton, Stack, useTheme} from '@mui/material';

import { DefaultPaginationData,
    getColorByValue,
    getLabelByValue,
    MembersStatus} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer, BaseTable } from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import AddPersonalIdentityForm from './Forms/EditPersonalIdentity';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseModal from 'src/components/BaseModal';
import { IdentityService } from 'src/api/services';
import BaseCard from 'src/components/BaseCard';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import {Download} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import PortoutDialog from "../number/Forms/PortoutDialog";
import AddSubCustomerForm from "./Forms/AddSubCustomerForm";
import EditSubcustomerForm from './Forms/EditSubcustomerForm';
import DeleteSubcustomerDialog from './Forms/DeleteSubcustomerDialog';

// ----------------------------------------------------------------------


export default function SubCustomers() {
    const {t} = useTranslation();
    const [openAddMemberModal, setAddMemberModalStatus] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const TABLE_HEAD = [
        {key: "subcustomer_name", label: t('Subcustomer/Site Name')},
        {key: "name", label: t('Company/Individual Name')},
        {key: "identity_type", label: t('Identity Type')},
        {key: "address_line_1", label: t('Address Line 1')},
        {key: "country", label: t('Country')},
        {key: "trunk_name", label: t('Trunk Name')},
        {key: "host_address", label: t('Trunk Host Address')},
        {key: "action", label: t('actions')}
    ];

    const TABLE_FIELD_MAPPING = {
        subcustomer_name: {key: "subcustomer_name", index: 0},
        name: {key: "name", index: 1},
        identity_type: {key: "identity_type", index: 2},
        address_line_1: {key: "address_line_1", index: 3},
        country: {key: "country", index: 4},
        trunk_name: {key: "trunk_name", index: 5},
        host_address: {key: "host_address", index: 6},
        document: {key: "document", index: 7, noRender: true},
        document_name: {key: "document_name", index:8, noRender: true},
        company_name: {key: "company_name", index:9, noRender: true},
        identity_id: {key: "identity_id", index:10, noRender: true},
        address_id: {key: "address_id", index:11, noRender: true},
        id: {key: "id", index:12, noRender: true},
        trunk_id: {key: "trunk_id", index:13, noRender: true},
    };


    const fetchSubCustomers = () => {
        const params = {
            size: paginationData.rowsPerPage,
            page: paginationData.page + 1,
            identity_type: 1
        };
        setLoadingData(true);
        IdentityService.listSubCustomers(params)
            .then((response) => {
                if (!response.data || !response.data.data) {
                    throw(t("session-expired"));
                }
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })

    };

    const downloadFile = (index) => {
        setLoading(true);
        IdentityService.downloadFile({guid: data[index][7]['value']})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data[index][8]['value']);
                document.body.appendChild(link);
                link.click();
                setLoading(false);

            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }



    useEffect(() => {
        fetchSubCustomers();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        console.log(rowData)
        rowData.map((d, idx) => {
            if (d.key === "name") {
                formatted.push({
                    ...d,
                    value: rowData[2]['value'] != 1 ? rowData[9]['value'] : rowData[1]['value'],
                });
            } else if (d.key === "identity_type") {
                formatted.push({
                    ...d,
                    value: d.value != 1 ? t('Company') : t('Individual')
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    };


    const modalHandler = (modalType, index) => {
        if (modalType === "addMember") {
            setAddMemberModalStatus(!openAddMemberModal);
        }
        else if (modalType === "editDialog") {
            setOpenEditDialog(!openEditDialog);
            setSelectedRow(data[index]);
        }
        else if (modalType === "deleteDialog") {
            setOpenDeleteDialog(!openDeleteDialog);
            setSelectedRow(data[index]);
        }
    };

    const theme = useTheme();


    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent='start' spacing={1}>
                        <>
                            {data[index][7]['value'] && <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <Download
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => downloadFile(index)}/>
                            </IconButton>}

                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <EditIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("editDialog", index)}/>
                            </IconButton>

                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <DeleteIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("deleteDialog", index)}/>
                            </IconButton>
                        </>
                </Stack>
            );
      };
    return (
        <>
            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>

                <BaseCard>
                <TableFilterContainer >
                    <Box alignItems={"center"} sx={{
                        height: 50,
                        display: 'flex'
                        }}>
                        <BaseButton
                            label= {t("+ Add New Sub Customer")}
                            onClick={() => modalHandler("addMember")}
                        />
                    </Box>
                </TableFilterContainer>
                </BaseCard>
                <BaseModal sx={{width:600}} title={t("Add New Sub Customer")} open={openAddMemberModal} setOpen={setAddMemberModalStatus} children={<AddSubCustomerForm successCallback={fetchSubCustomers} setModalStatus={setAddMemberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} />
                <BaseModal sx={{width:600}} title={t("Edit Sub Customer")} open={openEditDialog} setOpen={setOpenEditDialog} children={<EditSubcustomerForm formData={selectedRow} successCallback={fetchSubCustomers} setModalStatus={setOpenEditDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} />
                <BaseModal title={t("Delete Sub Customer")} open={openDeleteDialog} setOpen={setOpenDeleteDialog} children={<DeleteSubcustomerDialog  formData={selectedRow} successCallback={fetchSubCustomers} setModalStatus={setOpenDeleteDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} />
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}