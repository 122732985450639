import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl, Box, CircularProgress, InputLabel, Select, MenuItem
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import {makeStyles} from '@mui/styles';
import BaseButton from "../../../components/buttons/BaseButton";
import {Form, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import * as ChannelService from "../../../api/services/Channels";

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function UpdateDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    const BuySchema = Yup.object().shape({
        Quantity: Yup.number()
            .typeError('Quantity must be a number.')
            .min(formData.quantity+1, 'Quantity should be greater than previous value.')
            .required('Please enter a number.')
            .test('is-valid-number', 'Invalid number', (value) => !isNaN(value)),
    });


    const formik = useFormik({
        initialValues: {
            Quantity: formData.quantity,
        },
        validationSchema: BuySchema,
        onSubmit: (values, { setSubmitting }) => {
            const payload = {
                product_id: formData.product_id,
                quantity: parseInt(values.Quantity)
            };
            ChannelService.buyChannel(payload)
                .then((response) => {
                    if(response.data.status == true){
                        setMessage("Buy request has been successfully submitted");
                        setSnackbarStatus(true);
                        setModalStatus(false);
                    }else{
                        setMessage(response.data.meta.msg);
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                })
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return  <>
           <TableFilterContainer>
               <FormikProvider value={formik}>
                   <Form autoComplete="off" onSubmit={handleSubmit}>
                       <Grid item md={3} xs={12}>
                           <div align={'left'}>{"Product: " + formData.product}</div>
                           <br/>
                           <FormControl fullWidth>
                               <TextField
                                   fullWidth
                                   label={t("Quantity")}
                                   {...getFieldProps("Quantity")}
                                   error={Boolean(touched.Quantity && errors.Quantity)}
                                   helperText={touched.Quantity && errors.Quantity}
                                   sx={{ mb:1 }}
                               />
                           </FormControl>
                       </Grid>
                       <br/>
                       <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={1}>
                           <BaseButton sx={{marginRight:1}}
                               label={'Cancel'}
                               onClick={() => setModalStatus(false)}
                               color={"--color-secondary"}
                           />
                           <BaseButton
                               label={'Buy Channel'}
                               onClick={handleSubmit}
                               color={"--color-primary"}
                           />
                       </Grid>
                   </Form>
               </FormikProvider>
           </TableFilterContainer></>;
}

