import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Typography
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseSnackbar from 'src/components/BaseSnackbar';

import {
    DefaultPaginationData,
    NumberStatus,
    SetType,
    getSelectOptions,
    getLabelByValue,
    Capability,DidType
} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import {PricingService, CommonService, NumberService} from 'src/api/services';
import {useStore} from "../../../store/Store";
import BaseCard from 'src/components/BaseCard';
import BaseSelect from 'src/components/BaseSelect';
import BaseButton from 'src/components/buttons/BaseButton';



const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function InboundRates() {
    const { t } = useTranslation();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [providerCodes, setProviderCodes] = useState([]);
    const [filterProvider, setFilterProvider] = useState("");
    const [numberTypes, setNumberTypes] = useState([]);
    const [store] = useStore();
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;


    const checkDisplay = (field) => {
        return store.portalSettings.services.tariff_fields.inbound[field];
    }

    const TABLE_HEAD = [
        { key: "product", label: t("Product")},
        { key: "country", label: t("country") },
        { key: "did_type", label: t("type") },
        { key: "capability", label: t("capability") },
        checkDisplay("call_rounding") ? { key: "rounding", label: t("Call Rounding") } : null,
        checkDisplay("nrc") ? { key: "nrc", label: t('NRC') } : null,
        checkDisplay("mrc") ? { key: "mrc", label: t('MRC') } : null,
        checkDisplay("peak_voice_rate") ? { key: "minute_peak_rate", label: t('Peak Voice Rate') } : null,
        checkDisplay("offpeak_voice_rate") ? { key: "minute_offpeak_rate", label: t('Off Peak Voice Rate') } : null,
        checkDisplay("weekend_peak_voice_rate") ? { key: "minute_weekend_rate", label: t('Weekend Voice Rate') } : null,
        checkDisplay("mobile_origin_rate") ? { key: "minute_mobile_rate", label: t('Mobile Origin Rate') } : null,
        checkDisplay("payphone_origin_rate") ? { key: "minute_payphone_rate", label: t('Payphone Origin Rate') } : null,
        checkDisplay("connect_fee") ? { key: "connect_fee", label: t('Connect Fee') } : null,
        checkDisplay("sms_rate") ? { key: "sms_rate", label: t('SMS Rate') } : null,
        checkDisplay("port_in_fee") ? { key: "portin_fee", label: t('Port-In Fee') } : null,
        checkDisplay("port_out_fee") ? { key: "portout_fee", label: t('Port-Out Fee') } : null,
    ].filter(Boolean);

    const TABLE_FIELD_MAPPING = TABLE_HEAD.reduce((mapping, field, index) => {
        mapping[field.key] = { key: field.key, index };
        return mapping;
    }, {});
    
    TABLE_FIELD_MAPPING.initial_time = { key: "initial_time", index: TABLE_HEAD.length, noRender: true };
    

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                if(response.status == 200){
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ code: item[1]["code"], name: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                    })
                    setCountries(items);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const downloadFile = (download_all) => {
        let payload;
        if(download_all){
            payload = {
                download_all: true
            }
        } else {
            payload = {
                country: filterCountry ? filterCountry : "",
                did_type: filterNumberTypes == 0 ? "" : filterNumberTypes,
                capability: filterCapability ? filterCapability : "",
                provider_code: filterProvider ? filterProvider : undefined,
                page: paginationData.page + 1,
                size:10
            }
        }
        PricingService.download_portal_tariffs(payload)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Inbound_Voice_Rates.csv');
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    }

    const fetchInboundRates = () => {
        const params = {
            country: filterCountry ? filterCountry : "",
            did_type: filterNumberTypes == 0 ? "" : filterNumberTypes,
            capability: filterCapability ? filterCapability : "",
            provider_code: filterProvider ? filterProvider : undefined,
            page: paginationData.page + 1,
            size:10
        };

        if(params.capability === "0"){
            params.capability = "";
        }

        setLoadingData(true);
        PricingService.listIboundsTariffs(params)
            .then((response) => {
                let items = [];
                if(response.status == 200){
                    setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
                if(response.data.data.count === 0) throw(t("There is no inbound rate."));
                }

            })
            .catch((err) => {
                setMessage('Error while getting data');
                setSnackbarStatus(true);

            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchProviderCodes = () => {
        NumberService.getProviderCodes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchInboundRates();
        fetchCountries();
        fetchProviderCodes();
        fetchDIDTypes();
        return () => {
            setData([]);
        }
    }, [paginationData]);



    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()), chipColor: d.value === 3 ? "success" : "error",
                });
            }  else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "rounding") {
                const initialTime = rowData.find((d) => d.key === "initial_time");

                formatted.push({
                    ...d,
                    value: initialTime.value + "/" + d.value.toString(),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {countries.map((country, index) => {
                                    return <MenuItem key={country.code} value={country.iso_code_2digit}>{country.name}</MenuItem>;
                                })}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{t('type')}</InputLabel>
                            <BaseSelect
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('capability')}</InputLabel>
                            <BaseSelect
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    {providersEnabled && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProvider}
                                onChange={event => { setFilterProvider(event.target.value) }}
                            >

                                {getSelectOptions(providerCodes)}

                            </BaseSelect>
                        </FormControl>
                    </Grid>)}

                    <Grid item md={12} xs={12} spacing={2} sx={{ textAlign: "left" }}>
                    <SearchButton onClick={() => { fetchInboundRates() }} />

                    <BaseButton
                            onClick={() => { downloadFile(false) }}
                            label={'Export Current Page'}
                            sx={{ marginLeft: 1 }}
                        />
                        <BaseButton
                            onClick={() => { downloadFile(true) }}  
                            label={'Export All'}
                            sx={{ marginLeft: 1 }}
                        />
                    </Grid>

                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />

                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
