import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import PortIn from "./portin";
import {useState} from "react";
import AddPortInRequest from "./AddPortInRequest";
// ----------------------------------------------------------------------


export default function PortInSidebar() {
    const { t } = useTranslation();
    const [page, setSelectedPage] = useState(0);
    const change = () => {
        setSelectedPage(1);
    }
    const TABS = [
        { id: 0, label: "Port-In", component: page == 0 ? <PortIn changePage={change} /> : <AddPortInRequest callback={setSelectedPage} /> },
    ];

    const TITLE = t('Port-In');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
