import { useState } from 'react';
// material
import {
    FormControl,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
// app
import { PortingService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import  {useStore} from "../../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

export default function ReschedulePortingForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));

    const [store, dispatch] = useStore();

    const deallocationApproval = store.portalSettings.services.access_management.deallocation_approval ?? false;

    const deallocateNumber = () => {
        setLoading(true);
        let payload = {}

        if(filterStart.isBefore(dayjs(new Date()).startOf('day'))){
            setMessage('Scheduled at cannot be in the past');
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
            return;
        }

        if(deallocationApproval){
            payload = {
                id: formData.id,
                scheduled_at:filterStart.format('YYYY-MM-DDTHH:mm:ss')
            };

        }else{
            payload = {
                id: formData.did_id
            };
        }

        PortingService.reschedule(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage('Request has been successfully submitted', {number: formData.number_value});
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw {message:"Request could not be changed", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography component="h6">
                {'You are about to reschedule porting request. Are you sure?'}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>

                {deallocationApproval && (  <Grid container justifyContent="left">

                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker onChange={(date)=> setFilterStart(date)} value={filterStart} label="Preferred Scheduled At" inputFormat="DD/MM/YYYY"   format="DD/MM/YYYY" />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>)}


                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Reschedule')}
                        onClick={deallocateNumber}color={"--color-primary"}
                    />

                </Stack>
            </Stack>
        </>
    );
}
