// ----------------------------------------------------------------------
import React, {useEffect, useState} from 'react';
import {styled} from '@mui/system';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import {TableFilterContainer} from 'src/components/table';
import {NumberService} from 'src/api/services';
import {useTranslation} from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}



const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function ResubmitForm({ formData, fetchItems, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [purchaseFor, setPurchaseFor] = useState("Individual");


    const fetchRequiredDocs = () => {
        NumberService.getRejectedDocuments({order_id:formData.order_id})
            .then((response) => {
                    setData(response.data.data);
            })
            .catch((err) => {
                setMessage("Could not be updated");
                setSnackbarStatus(true);
                setModalStatus(false);
            })
    }

    const reSubmitDocs = () => {
        const form = new FormData();

        form.append('order_id', formData.order_id);
        form.append('document_mapping', '{}');
      
        NumberService.resubmitDocs(form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((response) => {
          if(response.data.status == true){
            setMessage("Documents submitted");
            setSnackbarStatus(true);
            setModalStatus(false);
            successCallback(false);
            fetchItems();
          }
        })
        .catch((err) => {
          setMessage("Could not be submitted");
          setSnackbarStatus(true);
          setModalStatus(false);
        });
      };


    useEffect(() => {
        fetchRequiredDocs();
    }, []);


    const changeIsIndividual = () => {
        if(purchaseFor == "Individual"){
            setPurchaseFor("Company")
        }else{
            setPurchaseFor("Individual")
        }
    }

  



    return (
        <>
            <TableFilterContainer>

                <Typography sx={{ textAlign: 'left' }}>
                    Selected Number <strong></strong>

                </Typography>
                <br/>

                <Typography sx={{ textAlign: 'left' }}>
                  <strong>You are resubmit {formData.did_id}</strong><br/>
                  This request needs some documents to be uploaded. Necessary documents are listed below.
                </Typography>
                <br/>

                


                <Grid item md={2} xs={12}>
                    <Typography sx={{ textAlign: 'left' }}>
                        {
                            "Upload type"
                        }
                    </Typography>
                    <FormControl fullWidth>
                        <RadioGroup row
                            name="controlled-radio-buttons-group"
                            value={purchaseFor}
                            onChange={changeIsIndividual}
                        >
                            {[{"name": "Individual", "value": "Individual" }, {"name": "Company", "value": "Company"}].map((option) => (
                                <FormControlLabel value={option.name} control={<Radio/>} label={option.name}/>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <br/>
                
                {data.map((item) => (
                                            <>
                                                <Typography sx={{ textAlign: 'left' }}>
                                                    <strong>Document</strong><br/>
                                                    {item.document}
                                                </Typography>
                                                <br />
                                                <Typography sx={{ textAlign: 'left' }}>
                                                    <strong>Comment</strong><br/>
                                                    {item.comments}
                                                </Typography>
                                                <br />
                                            </>
                                ))}

            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                        
                        <BaseButton
                            label={'Resubmit'}  sx={{ marginRight: 1 }}
                            onClick={() => reSubmitDocs()} color={"--color-search"}
                        />

                    <BaseButton
                        label={'Cancel'}
                        onClick={setModalStatus(false)} color={"primary"}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

