import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import {useStore} from "../../../store/Store";
import Products from './Products';

// ----------------------------------------------------------------------


export default function ProductsSidebar() {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const externalSMSEnabled = store.portalSettings.services.access_management.external_sms ?? false;

    const TABS = [
        { id: 0, label: "Products", component: <Products/> }
    ];

    const TITLE = 'Products';

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
