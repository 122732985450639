// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import {makeStyles} from '@mui/styles';
import * as NumberService from "../../../../api/services/Numbers";
import { useStore } from 'src/store/Store';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function ViewDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const classes = useStyles();
    const [store, dispatch] = useStore();
    const advancedTranslationEnabled = store.portalSettings.services.access_management.advanced_translation ?? false;

    useEffect(() => {
        const params = {did_id:formData.did_id}
        NumberService.numberDetail(params)
            .then((response) => {
                let items = [];
                setData(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                //setLoadingData(false);
            })
        console.log(formData);
    }, []);

    return (
        <>
            <TableFilterContainer>
                    <Grid container justifyContent="left">
                        <div>Billing ID</div>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <TextField
                                value={data.billing_dnis ? data.billing_dnis.landline : " "}
                                label={"Landline"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>


                        {advancedTranslationEnabled && (
                            <>
                            <FormControl fullWidth>
                            <TextField
                                value={data.billing_dnis ? data.billing_dnis.mobile : " "}
                                label={"Mobile"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}

                            />
                            </FormControl>
                            <FormControl fullWidth>
                            <TextField
                                value={data.billing_dnis ? data.billing_dnis.payphone : " "}
                                label={"Payphone"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                            </FormControl>
                            </>
                        )}

                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div>DNIS</div>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <TextField
                                value={data.translation_dnis ? data.translation_dnis.landline : " "}
                                label={"Landline"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>
                        {advancedTranslationEnabled && (
                            <>
                            <FormControl fullWidth>
                            <TextField
                                value={data.translation_dnis ? data.translation_dnis.mobile : " "}
                                label={"Mobile"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}

                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                value={data.translation_dnis ? data.translation_dnis.payphone : " "}
                                label={"Payphone"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>
                            </>
                        )}
                    </Grid>
            </TableFilterContainer>
            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton 
                        label={'Close'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

