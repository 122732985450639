import api from "src/api/Api";
import urls from "src/api/Urls";



export const getCountries = (params) => {
    return api.get(urls.countries, { params: params });
}

export const getRegisterCountries = (params) => {
    return api.get(urls.register_countries, { params: params });
}

export const getCities = (params) => {
    return api.get(urls.cities, { params: params });
}

export const getStates = (params) => {
    return api.get(urls.states, { params: params });
}

export const getFile = (uuid) => {
    return api.get(urls.get_file(uuid), { responseType: 'blob', timeout: 30000 });
}

export const getDocsSpec = () => {
    return api.get(urls.documentation);
}

export const getDashboard = (params) => {
    return api.get(urls.dashboard, { params: params });
}
