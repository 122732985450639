
import React from 'react';
import { useState, useEffect} from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { SenderIDService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/BaseModal';
import {BaseTable, TableFilterContainer} from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    getLabelByValue,
    getColorByValue,
    getSelectOptions,
    SenderIDStatus
} from 'src/constants/index';
import dayjs from 'dayjs';
import ShowPortOuts from './Forms/ShowPortOuts';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box,FormControl,Grid,IconButton, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BaseCard from 'src/components/BaseCard';
import { useTheme } from '@mui/material/styles';
import { CalendarMonth, Edit, UploadFile } from '@mui/icons-material';
import PortinResubmit from './resubmit';
import BaseDialog from 'src/components/BaseDialog';
import ReschedulePortingForm from './Forms/CancelForm';
import PortinResubmitForm from './resubmit';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseSelect from 'src/components/BaseSelect';
import BaseTextField from 'src/components/BaseTextField';
import { styled } from '@mui/system';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function SenderIDInventory({changePage}) {
    const { t } = useTranslation();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [openShowModal, setShowModalStatus] = useState(false);
    const [openResubmit, setOpenResubmit] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openScheduleModal, setRescheduleModal] = useState(false);
    const navigate = useNavigate();

    const [filterCountry, setFilterCountry] = useState(undefined);
    const [filterStatus, setStatus] = useState("");
    const [filterSenderId, setSenderId] = useState("");

    const [countries, setCountries] = useState([]);

    const setResubmit = () => {
        fetchSenderIDRequests();
        setOpenResubmit(!openResubmit);
    }


    const TABLE_HEAD = [
        { key: "id", label: t('id')},
        { key: "sender_id", label: t('Sender ID')},
        { key: "country", label: t('Country')},
        { key: "status", label: t('Status')},
    ];


    const TABLE_FIELD_MAPPING = {
        id : { key: "id", index: 0 },
        sender_id: { key: "sender_id", index: 1 },
        country: { key: "country", index: 2 },
        status: { key: "status", index: 3 }
    };


    const fetchSenderIDRequests = () => {
        const params = {
            size: paginationData.rowsPerPage,
            page: paginationData.page + 1,
            country_id: filterCountry ? filterCountry != 0 ? filterCountry : undefined : undefined,
            status: filterStatus ? filterStatus : undefined,
            sender_id: filterSenderId ? filterSenderId : undefined,
        };

        if(params.status === 0 || params.status === "0"){
            delete params.status;
        }

        setLoadingData(true);
        SenderIDService.getInventory(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    const fetchSenderCountries = () => {
        SenderIDService.getCountries()
            .then((response) => {
                let items = [];
                for (const idx in response.data.data.items) {
                    items.push({
                        value: response.data.data.items[idx].id,
                        label: response.data.data.items[idx].country,
                    });
                }
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    useEffect(() => {
        fetchSenderIDRequests();
        fetchSenderCountries();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if(d.key === "requested_at"){
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            } else if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SenderIDStatus(), d.value),
                    chipColor: getColorByValue(SenderIDStatus(), d.value),
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    };

    const modalHandler = (modalType, index = undefined) => {

        if (modalType === "edit") {
            setResubmit(true);
        }

        if (modalType === "reschedule") {
            setRescheduleModal(true);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };


    }
    const theme = useTheme();

    const getActionItems = (index) => {

        return(
            <>
            <Grid direction="row" container spacing={1} justifyContent="left">
           {/* <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <VisibilityIcon
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-success']}}
                    onClick={() => modalHandler("show", index)}/>
            </IconButton>

            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <CalendarMonth
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-warning']}}
                    onClick={() => modalHandler("reschedule", index)}/>
            </IconButton>

            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <UploadFile
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-warning']}}
                    onClick={() => modalHandler("edit", index)}/>
            </IconButton>
            */}
            </Grid>
            </>
        );
    };

    const DIALOG_PROPERTIES = {
        fullWidth: true,
        maxWidth: "lg",
        scroll: "body",
    }

    const sortByOptions = () => {
        return [
            { value: "id", label: t('id')},
            { value: "requested_at", label: t('Requested At')},
            { value: "scheduled_at", label: t('Scheduled At')},
        ];
    }


    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseModal title={t("Porting")+"#"+selectedRow.id} open={openShowModal} setOpen={setShowModalStatus} children={<ShowPortOuts formData={selectedRow}/>} />

            <BaseDialog closeCallback={setOpenResubmit} dialogProperties={DIALOG_PROPERTIES} title={t("Porting")+"#"+selectedRow.id} open={openResubmit} setOpen={setResubmit} children={<PortinResubmitForm callback={setResubmit} formData={selectedRow} setMessage={setMessage} setSnackbarStatus={setSnackbarStatus} setModalStatus={setOpenResubmit}  successCallback={fetchSenderIDRequests} />} />

            <BaseModal title={t('Reschedule Porting Request')} open={openScheduleModal} setOpen={setRescheduleModal} children={<ReschedulePortingForm successCallback={fetchSenderIDRequests} formData={selectedRow} setModalStatus={setRescheduleModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                <BaseCard>
                <TableFilterContainer >
                <Grid sx={{ alignItems: "center", justifyItems: "center", alignContent: "center" }} container spacing={2}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('Country')}</InputLabel>
                            <BaseSelect
                                label={t('Country')}
                                labelId="filter-status-label"
                                name="country"
                                color="secondary"
                                value={filterCountry}
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                  
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('Status')}</InputLabel>
                            <BaseSelect
                                label={t('Status')}
                                labelId="filter-status-label"
                                name="status"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setStatus(event.target.value) }}
                            >
                                {getSelectOptions(SenderIDStatus())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField  value={filterSenderId}
                                            label={t('Sender ID')}
                                            name="sender_id"
                                            onChange={setSenderId}
                            />
                        </FormControl>
                    </Grid>

          
                    <SearchButtonContainer item md={2} xs={12}>
                    <SearchButton onClick={() => { fetchSenderIDRequests() }} />
                    </SearchButtonContainer>
                </Grid>
                </TableFilterContainer>
                </BaseCard>
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={null}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>

    );
}

