import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Verifications from './verifications';
import Products from './products';


export default function VerificationSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('Verifications'), component: <Verifications /> },
        { id: 0, label: t('Products'), component: <Products /> },
    ];

    const TITLE = t('Verifications');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
