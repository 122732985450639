import React, { useEffect, useState } from 'react';
import {
    Grid,
    FormControl,
    Box,
    Typography,
    InputLabel,
    Select, Stack, IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import {
    DefaultPaginationData, DidType, getColorByValue,
    getLabelByValue,
    getSelectOptions,
    PreOrderStatus
} from 'src/constants/index';
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from 'src/components/buttons/SearchButton';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import * as OrderService from "../../../api/services/Orders";
import BaseCard from 'src/components/BaseCard';
import BaseSelect from 'src/components/BaseSelect';
import {rowArrayToObject} from "../../../utils/Util";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import * as CommonService from "../../../api/services/Common";
import * as NumberService from "../../../api/services/Numbers";
import { Upload } from '@mui/icons-material';
import BaseDialog from 'src/components/BaseDialog';
import NumberRequestForm from '../number/Forms/NumberRequestForm';
import PreOrderResubmitForm from './Forms/PreorderResubmitForm';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

export default function PreOrders() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t('id') },
        { key: "country", label: t('country') },
        { key: "did_type", label: 'DID Type' },
        { key: "capability", label: t('capability') },
        { key: "quantity", label: 'Quantity' },
        { key: "requested_at", label: 'Requested At' },
        { key: "finalized_at", label: 'Finalized At' },
        { key: "status", label: 'Status' },
        { key: "actions", label: 'Actions' },
    ];

    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        country: { key: "country", index: 1 },
        did_type: { key: "did_type", index: 2 },
        capability: { key: "capability", index: 3 },
        quantity: { key: "quantity", index: 4 },
        requested_at: { key: "requested_at", index: 5 },
        finalized_at: { key: "finalized_at", index: 6 },
        status: { key: "status", index: 7 },
        product_id: { key: "product_id", index: 8, noRender: true },
        identity_id: { key: "identity_id", index: 9, noRender: true },
        address_id: { key: "address_id", index: 10, noRender: true }
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterDidType, setFilterDidType] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));
    const [filterEnd, setFilterEnd] = useState(dayjs(new Date()).endOf('day'));

    const [openPreorderFileUpload, setOpenPreorderFileUpload] = useState(false);



    const fetchPreOrders = () => {
        const params = {
            status: filterStatus ? filterStatus : undefined,
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            did_type: filterDidType ? filterDidType : undefined,
            country: filterCountry && filterCountry != 0 ? filterCountry : undefined,
            start_datetime:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
            end_datetime:filterEnd.format('YYYY-MM-DDTHH:mm:ss'),
            size:10,
        };
        setLoadingData(true);
        OrderService.getPreOrders(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_FIELD_MAPPING.length).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })


    }

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })

                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchPreOrders();

        return () => {
            setData([]);
        }
    }, [paginationData]);


    useEffect(() => {
        fetchCountries();
        fetchDIDTypes();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "finalized_at" || d.key === "requested_at") {
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });

            } else if (d.key === 'status') {

                formatted.push({
                    ...d,
                    value: getLabelByValue(PreOrderStatus(), d.value.toString()),
                    chipColor: getColorByValue(PreOrderStatus(), d.value.toString()),

                });

            }else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const theme = useTheme();
    const navigate = useNavigate();

    const modalHandler = (modalType, index = undefined) => {
        const detailsID = data[index][0].value;

        if (modalType === "detailsDialog") {
            navigate(`/pre-orders/detail/${detailsID}`);
        }

        if (modalType === "resendDialog") {
            setOpenPreorderFileUpload(true);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" justifyContent='start' spacing={2}>
                    <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <VisibilityIcon
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-success']}}
                            onClick={() => modalHandler("detailsDialog", index)}/>
                    </IconButton>
                {data[index][7].value == 6 && 
                     (<IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <Upload
                                    sx = {{ 
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("resendDialog", index)}/>
                            </IconButton>)
                }
            </Stack>
        )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />

            <BaseDialog title={'Pre-Order Documents and Verification'} dialogProperties={DIALOG_PROPERTIES} closeCallback={() => setOpenPreorderFileUpload(false)} open={openPreorderFileUpload} setOpen={setOpenPreorderFileUpload} children={<PreOrderResubmitForm closeCallback={() => setOpenPreorderFileUpload(false)} successCallback={() => fetchPreOrders()} formData={selectedRow} setModalStatus={setOpenPreorderFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} extraData={{quantity: selectedRow.quantity}} />} />

            <BaseCard>
                <TableFilterContainer>
                    <Grid sx={{ alignItems: "center" }} container spacing={4}>
                        <Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                                <BaseSelect
                                    label={'country'}
                                    labelId="filter-country-label"
                                    name="country"
                                    color="secondary"
                                    value={filterCountry }
                                    onChange={event => { setFilterCountry(event.target.value) }}
                                >
                                    {getSelectOptions(countries)}
                                </BaseSelect>
                            </FormControl>
                        </Grid>
                        <Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-number-type-label">{t('Number Type')}</InputLabel>
                                <BaseSelect
                                    labelId="filter-number-type-label"
                                    name="number-type"
                                    color="secondary"
                                    value={filterDidType}
                                    onChange={event => { setFilterDidType(event.target.value) }}
                                >
                                    {getSelectOptions(numberTypes)}
                                </BaseSelect>
                            </FormControl>
                        </Grid>
                        <Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-status-label">{t('common.__i18n_ally_root__.status')}</InputLabel>
                                <BaseSelect
                                    label={t('common.__i18n_ally_root__.status')}
                                    labelId="filter-status-label"
                                    name="status"
                                    color="secondary"
                                    value={filterStatus}
                                    onChange={event => { setStatus(event.target.value) }}
                                >
                                    {getSelectOptions(PreOrderStatus())}
                                </BaseSelect>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    onChange={(date)=> setFilterStart(date)} value={filterStart} label="Start Date-Time" />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                    format="DD/MM/YYYY HH:mm"
                                    onChange={(date)=> setFilterEnd(date)} value={filterEnd} label="End Date-Time" />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <SearchButtonContainer item md={2} xs={12}>
                            <SearchButton onClick={() => { fetchPreOrders() }} />
                        </SearchButtonContainer>
                    </Grid>
                    <br/>
                    <SearchButtonContainer item md={12} xs={12}>
                        <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                            <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                            </TitleStyle>
                            </Box>

                        </Box>
                    </SearchButtonContainer>
                </TableFilterContainer>
            </BaseCard>
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
