import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import VoiceEndpoints from "./endpoints/VoiceEndpoints";
import SMSEndpoints from "./endpoints/SMSEndpoints";
import { useStore } from 'src/store/Store';



export default function EndpointsSidebar() {
    const { t } = useTranslation();

    const [store] = useStore();
    const displaySMSEndpoints = store.portalSettings.services.portal_features.display_sms_endpoints ?? false;


    let TABS = [
        { id: 0, label: 'Voice Endpoints', component: <VoiceEndpoints /> }
    ];

    if (displaySMSEndpoints) {
        TABS.push({ id: 1, label: 'SMS Endpoints', component: <SMSEndpoints /> });
    }

    const TITLE = 'Endpoints';

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
