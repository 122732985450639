// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl,
    Stack,
    FormGroup, Checkbox, FormControlLabel
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {NumberService} from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));


export default function UpdateSMSEndpointForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const [name, setName] = useState("");
    const [endpoint, setEndpoint] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [body, setBody] = useState("");
    const [id, setId] = useState("");
    const [type, setType] = useState("");

    const [isDefault, setIsDefault] = useState(false);


    const updateEndpoint = () => {
        NumberService.updateSMSEndpoint( formData.id,{name:name, id_field:id, from_field:from, to_field:to, body_field:body, type_field:type, is_default: isDefault})
            .then((response) => {
                if (response.status === 200) {
                    setMessage("SMS endpoint updated");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw "endpoint could not be updated";
                }
            })
            .catch((err) => {
                setMessage("Could not be updated");
                setSnackbarStatus(true);
                setModalStatus(false);
            })
    }

    useEffect(() => {
        setName(formData.name);
        setEndpoint(formData.http_post_url);
        setFrom(formData.from_field);
        setTo(formData.to_field);
        setBody(formData.body_field);
        setId(formData.id_field);
        setType(formData.type_field);
        setIsDefault(formData.is_default);
    }, []);


    return (
        <>
            <TableFilterContainer>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={name}
                            label={t('name')}
                            name="name"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setName(event.target.value) }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={endpoint}
                            label={"HTTP Endpoint"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setEndpoint(event.target.value) }}
                            disabled={true}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={from}
                            label={"From Field"}
                            name="priority"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setFrom(event.target.value) }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={to}
                            label={"To Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setTo(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={body}
                            label={"Body Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setBody(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={id}
                            label={"ID Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setId(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={type}
                            label={"Type Field"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setType(event.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mt: 1 }}>
                    <FormGroup>
                        <StyledCheckboxLabel label={"Default"} control={
                            <Checkbox
                                color="secondary"
                                checked={isDefault}
                                onChange={() => setIsDefault(!isDefault)} />
                        } />
                    </FormGroup>

                </Stack>
            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Update'}
                        onClick={() => { updateEndpoint()}}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

