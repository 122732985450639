import React from 'react';
import {
    List,
    Box, Grid, FormControl, TextField
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import {AccountService, SMSService} from 'src/api/services';
import { useState } from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseButton from "../../../components/buttons/BaseButton";
import {styled} from "@mui/system";
import {useStore} from "../../../store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function SendSMS() {

    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");

    const [fromNumber, setFromNumber] = useState("");
    const [toNumber, setToNumber] = useState("");
    const [smsMessage, setSmsMessage] = useState("");

    const sendSMS = () => {
        if(fromNumber === ""){
            setMessage("From Number is required");
            setSnackbarStatus(true);
            return;
        }
        if(toNumber === ""){
            setMessage("To Number is required");
            setSnackbarStatus(true);
            return;
        }
        if(smsMessage === ""){
            setMessage("Message is required");
            setSnackbarStatus(true);
            return;
        }

        if(isNaN(fromNumber) || isNaN(toNumber)){
            setMessage("From and To Number should be numeric");
            setSnackbarStatus(true);
            return;
        }

        const payload = {
            from_number: fromNumber,
            to_number: toNumber,
            message: smsMessage
        }
        SMSService.sendSMS(payload)
            .then((response) => {
                if(response.data.status == true){
                    setMessage("SMS Sent Successfully");
                    setSnackbarStatus(true);
                }else{
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                }
            })
            .catch((err) => {
                setMessage("Failed to send SMS");
                setSnackbarStatus(true);
            })
    }

    return(
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : "white",
                border:'2em solid white',
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,
            }}>
                <>
                    <List component="nav" aria-label="mailbox folders" >
                        <h1>Send SMS</h1>
                        <br/>

                    </List>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={fromNumber}
                                label={"From Number"}
                                sx={{marginBottom:3 }}
                                onChange={event => { setFromNumber(event.target.value) }}

                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={toNumber}
                                label={"To Number"}
                        sx={{marginBottom:3 }}
                                onChange={event => { setToNumber(event.target.value) }}

                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={smsMessage}
                                label={"Message"}
                                minRows={4}
                                maxRows={4}
                                multiline
                                sx={{marginBottom:3                                 }}
                                onChange={event => { setSmsMessage(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Send'}
                                onClick={ () => sendSMS()} color={"--color-search"}
                            />
                        </SearchButtonContainer>
                    </Grid>
                </>
            </Box>
        </>
    )
}
