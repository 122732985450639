import api from "src/api/Api";
import urls from "src/api/Urls";

export const getNotifications = (params) => {
    return api.get(urls.notifications, { params: params });
}

export const getNotification = (id) => {
    return api.get(urls.notification(id));
}

export const deleteNotification = (id, payload) => {
    return api.patch(urls.delete_notification(id), payload);
}

export const unreadNotification = (id, payload) => {
    return api.patch(urls.unread_notification(id), payload);
}

export const deleteNotificationForever = (id) => {
    return api.delete(urls.delete_notification_forever(id));
}


