import api from "src/api/Api";
import urls from "src/api/Urls";



export const getChangeRequests = (params) => {
    return api.get(urls.list_requests_translations, { params: params});
}

export const getDeallocationRequests = (params) => {
    return api.get(urls.list_requests_deallocations, { params: params});
}
