import { useState } from 'react';
// material
import {
    Box,
    FormControl,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {TableFilterContainer} from "../../../../components/table";
import dayjs from "dayjs";
import  {useStore} from "../../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

export default function CancelRequestForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const sendCancelRequest = () => {
        console.log(formData);
        let payload = {}
        payload = {
            id: formData.id
        };
        NumberService.cancelRequest(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage('Request has been successfully cancelled', {number: formData.number_value});
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw {message:"Request could not be cancelled", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
            });
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography component="h6">
                    {'You are about to cancel this translation change request. Are you sure?'}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>


                <Stack sx={{ display: "block" }} direction="row" spacing={2}>


                    <BaseButton
                        label={'Close'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Submit')}
                        onClick={sendCancelRequest}color={"--color-primary"}
                    />

                </Stack>
            </Stack>
        </>
    );
}
