import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {Box, Grid, FormControl, Typography, InputLabel, Stack, IconButton} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import {DefaultPaginationData, getSelectOptions} from 'src/constants';
import SearchButton from 'src/components/buttons/SearchButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import BaseSnackbar from 'src/components/BaseSnackbar';

import { useTranslation } from 'react-i18next';
import {CommonService, ChannelService} from 'src/api/services';
import BaseCard from 'src/components/BaseCard';
import BaseDateTimePicker from 'src/components/BaseDateTimePicker';
import BaseSelect from "../../../components/BaseSelect";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BaseButton from "../../../components/buttons/BaseButton";
import {rowArrayToObject} from "../../../utils/Util";
import BaseModal from "../../../components/BaseModal";
import ScheduleDialog from "../number/Forms/ScheduleDialog";
import BuyDialog from "./buydialog";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function BuyChannels({ }) {
    const { t } = useTranslation();

    const tableHead = [
        { key: "country", label: t("Country") },
        { key: "currency", label: t("Currency") },
        { key: "mrc", label: t("MRC") },
        { key: "nrc", label: t("NRC") },
        { key: "product", label: t("Product") },
        { key: "action", label: t('actions') },
    ];

    const tableFieldMapping = {
        country: { key: "country", index: 0 },
        currency: { key: "currency", index: 1 },
        mrc: { key: "mrc", index: 2 },
        nrc: { key: "nrc", index: 3 },
        product: { key: "product", index: 4 },
        product_id: { key: "product_id", index: 5, noRender:true },
    };

    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));
    const [filterEnd, setFilterEnd] = useState(dayjs(new Date()).endOf('day'));
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [countries, setCountries] = useState([]);
    const [filterCountry, setFilterCountry] = useState("");
    const [openBuyDialog, setOpenBuyDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const TitleStyle = styled(Typography)(({ theme }) => ({
        color: theme.palette.background.contrastText,
        display: "inline"
    }));

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
   
        if (urlParams.get("sell_product_id")) {
            const sellProductId = urlParams.get("sell_product_id");
            const sellProductName = urlParams.get("sell_product_name");
            let rowDataFromUrl = [
                {},
                {},
                {},
                {},
                { key: "product", index: 4, value: urlParams.get("sell_product_name") },
                { key: "product_id", index: 5, noRender:true, value: parseInt(urlParams.get("sell_product_id")) }
            ];
            setSelectedRow(rowArrayToObject(rowDataFromUrl));
        }
        if (urlParams.get("buy") === "true") {
            setOpenBuyDialog(true);
        }
    }, []);
    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchAvailableChannels = () => {
        const params = {
            size:10,
            page: paginationData.page + 1,
            country_code: filterCountry ? filterCountry == 0 ? undefined : filterCountry : undefined,
        };
        setLoadingData(true);
        ChannelService.listAvailableChannels(params)
            .then((response) => {
                if (!response.data || !response.data.data) {
                    throw(t("session-expired"));
                }
                let items = [];
                setTotalCount(response.data.data.count);

                for (const idx in response.data.data.items) {
                    let item = new Array(tableHead.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in tableFieldMapping) {
                            item[tableFieldMapping[key].index] = {
                                ...tableFieldMapping[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchAvailableChannels();
        fetchCountries();
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if(d.key==="timestamp"){
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "buyChannel") {
            setOpenBuyDialog(!openBuyDialog);
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" justifyContent='start' spacing={2}>
               <BaseButton color={'--color-primary'} label={'Buy'}
                                                            onClick={() => modalHandler("buyChannel", index)}/>
            </Stack>
        )

    }
    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseModal title={'Buy Channel'} open={openBuyDialog} setOpen={setOpenBuyDialog} children={<BuyDialog successCallback={fetchAvailableChannels} formData={selectedRow} setModalStatus={setOpenBuyDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchAvailableChannels() }} />
                    </SearchButtonContainer>
                </Grid>
                <br/>
                <SearchButtonContainer item md={12} xs={12}>
                    <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                        <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                        </TitleStyle>
                        </Box>
                    </Box>
                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />
            <BaseTable
                head={tableHead}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />

        </>
    );

}

