import { useState, useEffect } from 'react';
import { RedocStandalone, MenuStore } from 'redoc';
import { CommonService } from 'src/api/services';
import { useStore } from 'src/store/Store';


export default function Documentation() {
    const [spec, setSpec] = useState({});
    const [store] = useStore();

  /*  const theme = {
        colors: {
            accent: {
                main: store.palette.primary.main,
                light: store.palette.primary.light,
                dark: store.palette.primary.dark,
                contrastText: store.palette.primary.contrastText,
            },
            border: {
                light: store.palette.secondary.light,
                dark: store.palette.secondary.dark,
            },
            primary: {
                main: store.palette.primary.main,
                light: store.palette.primary.light,
                dark: store.palette.primary.dark,
                contrastText: store.palette.primary.contrastText,
            },
            secondary: {
                main: store.palette.secondary.main,
                light: store.palette.secondary.light,
                dark: store.palette.secondary.dark,
                contrastText: store.palette.secondary.contrastText,
            },
            success: {
                main: store.palette.success.main,
                light: store.palette.success.light,
                dark: store.palette.success.dark,
                contrastText: store.palette.success.contrastText,
            },
            error: {
                main: store.palette.error.main,
                light: store.palette.error.light,
                dark: store.palette.error.dark,
                contrastText: store.palette.error.contrastText,
            },
            warning: {
                main: store.palette.warning.main,
                light: store.palette.warning.light,
                dark: store.palette.warning.dark,
                contrastText: store.palette.warning.contrastText,
            },
            text: {
                primary: store.palette.primary.darker,
                secondary: store.palette.secondary.darkmost,
                light: store.palette.primary.light,
            },
            background: {
                main: store.palette.background.main,
                light: store.palette.background.light,
                dark: store.palette.background.dark,
                contrastText: store.palette.background.contrastText,
            },
        },
        sidebar: {
            activeTextColor: store.palette.primary.darkmost,
            backgroundColor: store.palette.primary.lightmost,
        },
        rightPanel: {
            backgroundColor: store.palette.secondary.darker,
            textColor: store.palette.secondary.contrastText,
        },
        typography: {
            heading1: {
                color: store.palette.primary.darker,
            },
            heading2: {
                color: store.palette.primary.darker,
            },
            heading3: {
                color: store.palette.primary.darker,
            },
            rightPanelHeading: {
                color: store.palette.secondary.contrastText,
            },
        }
    };*/


    useEffect(() => {
        CommonService.getDocsSpec()
            .then((response) => {
                const receivedSpec = response.data;
                setSpec(receivedSpec);
                console.log('Received spec:', receivedSpec);
            })
            .catch((err) => {
                console.error('Error fetching spec:', err);
            });
    }, []);

    return (
        <div>
            {Object.keys(spec).length > 0 ? (
                <RedocStandalone spec={spec} />
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}