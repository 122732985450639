import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Stack,
    Typography, Box, ListItemButton, ListItemIcon, IconButton,
    Container
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    Capability,
    DefaultPaginationData,
    getLabelByValue
} from 'src/constants/index';
import {CartService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import {Icon} from "@iconify/react";
import InboxIcon from '@mui/icons-material/Inbox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {MarkEmailRead, MarkEmailUnread, ShoppingCartCheckout} from "@mui/icons-material";
import NotificationInfoForm from "./NotificationInfoForm";
import DeleteNotificationForm from "./DeleteNotificationForm";
import BaseCard from 'src/components/BaseCard';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from '@mui/material/styles';
import { countBy, set } from 'lodash';
import FileUploadForm from '../number/Forms/FileUploadForm';
import CountdownTimer from './CountownTimer';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const ListItemStyle = styled(({ bgColor, ...props }) => <ListItemButton disableGutters {...props} />)(
    ({ theme, bgColor }) => ({
        ...theme.typography.body2,
        height: 52,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2.5),
        alignItems: 'center',
        color: theme.palette.background.contrastText,
        backgroundColor: bgColor ? theme.palette['--sidebar-background-color-alpha-20'] : 'transparent',
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'primary'
});

const BoxStyle = styled(Box)({
    marginTop: "20px",
    marginBottom: "auto",
});
export default function ShoppingCart() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "did", label: "DID" },
        { key: "country", label: "Country" },
        { key: "currency", label: "Currency" },
        { key: "capability", label: "Capability" },
        { key: "did_type", label: "Type" },
        { key: "mrc", label: "MRC" },
        { key: "nrc", label: "NRC" },
        { key: "actions", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        did: { key: "did", index: 0 },
        country: { key: "country", index: 1,  },
        currency: { key: "currency", index: 2, },
        capability: { key: "capability", index: 3 },
        did_type: { key: "did_type", index: 4 },
        mrc: { key: "mrc", index: 5 },
        nrc: { key: "nrc", index: 6},
        actions: { key: "actions", index: 7, noRender: true  },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [cartData, setCartData] = useState(undefined);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [activePage, setActivePage] = useState(0);

    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [openFileDialog, setOpenFileDialog] = useState(false);


    const [notificationInfo, setNotificationInfo] = useState(false);
    const [deleteNotificationForever, setDeleteNotificationForever] = useState(false);
    const [addedCartItems, setAddedCartItems] = useState([]);


    useEffect(() => {
        fetchCart();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const clearCart = (cart_id) => {
        CartService.clearCart({cart_id: cart_id})
            .then((response) => {
                if (response.data.status == true) {
                    setCartData([]);
                    setAddedCartItems([]);
                    setData([]);
                    localStorage.removeItem("cart_id");
                } else{
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                //setLoading(false);
            })
    }

    const removeFromCart = (did) => {
        let id = localStorage.getItem("cart_id");
        id = parseInt(id);
        if (addedCartItems.length == 1){
            clearCart(id);
        }else{
            CartService.removeFromCart({did: did})
                .then((response) => {
                    if (response.data.status == true) {
                        setMessage("DID removed from cart");
                        setSnackbarStatus(true);
                        fetchCart();
                    } else{
                        setMessage(response.data.meta.msg);
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    setMessage(err);
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    //setLoading(false);
                })
        }
    }



    const fetchCart = () => {
      setAddedCartItems([]);
      setCartData([]);
      let id = localStorage.getItem("cart_id");
        if (id) {
            id = parseInt(id);

            CartService.getCart({cart_id: id})
                .then((response) => {
                    if (response.data.status == true) {
                        setCartData(response.data.data);
                        setAddedCartItems(response.data.data.did_list);
                        let items = [];
      
                        for (let i in response.data.data.did_list) {
                          let item = new Array(TABLE_HEAD.length - 1).fill({});
      
                          item[TABLE_FIELD_MAPPING["did"].index] = { key: "did", value: response.data.data.did_list[i] };
      
                          item[TABLE_FIELD_MAPPING["nrc"].index] = { key: "nrc", value: response.data.data.nrc };
      
                          item[TABLE_FIELD_MAPPING["mrc"].index] = { key: "mrc", value: response.data.data.mrc };
      
                          item[TABLE_FIELD_MAPPING["capability"].index] = { key: "capability", value: response.data.data.capability };
      
                          item[TABLE_FIELD_MAPPING["did_type"].index] = { key: "did_type", value: response.data.data.did_type };
      
                          item[TABLE_FIELD_MAPPING["country"].index] = { key: "country", value: response.data.data.country };
      
                          item[TABLE_FIELD_MAPPING["currency"].index] = { key: "currency", value: response.data.data.currency };
      
                          items.push(item);
      
                        }
      
                        setData(items);
                        setTotalCount(response.data.data.quantity);
                    } else {
                        if(response.data.meta.msg != "Cart not found") {
                            setMessage(response.data.meta.msg);
                            setSnackbarStatus(true);
                        }
                    }
                })
                .catch((err) => {
                    setMessage(err);
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    //setLoading(false);
                })
        }
  }

    const formatRowData = (rowData) => {
        return rowData;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "deleteNotificationForever") {
        } else if (modalType === "notificationInfo") {
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }
    const theme = useTheme();
    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent="start">
                    <BaseButton
                        label={'Remove'}
                        StartIcon={DeleteIcon}
                        onClick={() => removeFromCart(data[index][0].value)}
                    />
                </Stack>
            )
    }

    const clearPage = () => {
        setData([]);
        setTotalCount(0);
        setCartData([]);
    }


    return (
        <>
            <BaseDialog title={"Finalize Purchase"} dialogProperties={DIALOG_PROPERTIES} closeCallback={setOpenFileUpload} open={openFileUpload} setOpen={setOpenFileUpload} children={<FileUploadForm successCallback={clearPage} formType="add" formData={{
                product_id: cartData ? cartData.product_id : 0,
                cart_id: cartData ? cartData.cart_id : 0,
                valid_until: cartData ? cartData.valid_until : 0,
            }} setModalStatus={setOpenFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />

            <BaseDialog title={"Update Notification"} dialogProperties={DIALOG_PROPERTIES} closeCallback={ () => fetchCart(activePage)} open={notificationInfo} setOpen={setNotificationInfo} children={<NotificationInfoForm successCallback={ () =>  fetchCart(activePage)} formType="add" formData={selectedRow} setModalStatus={setNotificationInfo} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={"Are you sure?"} open={deleteNotificationForever} setOpen={setDeleteNotificationForever} children={<DeleteNotificationForm successCallback={ () =>  fetchCart(activePage)} formData={selectedRow} setModalStatus={setDeleteNotificationForever} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>

         
                <Grid container>
                   
                    <Grid item sx={{ width: '100%' }}>

                        <BaseTable 
                            head={TABLE_HEAD}
                            data={[...data].map((d, idx) => { return formatRowData(d); })}
                            actionItemPrep={getActionItems}
                            select={true}
                            pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: false }}
                            loadingData={loadingData}
                            hideDids={true}
                        />
                    </Grid>
                </Grid>
     
            </TableFilterContainer>
     
            </BaseCard>
           
            {cartData && cartData.valid_until ? (
                <>
            <br/>

            <Grid container xs={12} direction={"row"} justifyContent={"right"} alignItems={"center"}>
                    <CountdownTimer validUntil={cartData ? cartData.valid_until : -1} />
            </Grid>
            </>
            )
            : null}

            <br/>

            {cartData && cartData.quantity > 0 ? (
                <Grid container xs={12} direction={"row"} justifyContent={"right"} alignItems={"center"}>

                <Typography variant="h6" sx={{color: theme.palette.text.secondary, marginRight:2}}>{t('Total DIDs')}: {totalCount}</Typography>

                <Typography variant="h6" sx={{color: theme.palette.text.secondary, marginRight:2}}>{t('Total Amount')}: {cartData ? cartData.total_amount : 0}
                  {" "}{cartData ? cartData.currency : ""}
                </Typography>

                <BaseButton
                        children={<ShoppingCartCheckout sx={{marginRight:0.5}}/>}
                        label={'Order'}
                        onClick={() => setOpenFileUpload(true)}
                        color={"--color-success"}
                />
            </Grid>
            ) : null}
        </>
    );
}
