import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { styled } from '@mui/material/styles';
import {Box, Stack, AppBar, Toolbar, IconButton, Typography, Badge} from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { useStore } from 'src/store/Store';
import { useTranslation } from 'react-i18next';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FolderIcon from '@mui/icons-material/Folder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useTheme } from '@mui/styles';
import {Email, EmailOutlined, FolderOutlined, ShoppingCart, ShoppingCartOutlined} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette["--main-background-color"],

}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: theme.palette.background.main,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const MenuCollapseIconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const StyledBalanceBox = styled(Box)(({ theme }) => ({
  color: theme.palette['--main-text-color'],
  backgroundColor: theme.palette.background.main,
  border: `2px solid ${theme.palette['--main-text-color']}`,
  padding: theme.spacing(0.5, 0.75),
  borderRadius: "6px",
  ...theme.typography.subtitle2,
}));

const StyledNotificationBox = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.background.main,
  ...theme.typography.subtitle2,
  textDecoration: "none",
}));

// ----------------------------------------------------------------------

AppNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function AppNavbar({ onOpenSidebar }) {
  const { t } = useTranslation();
  const [store, dispatch] = useStore();
  const theme = useTheme();

  const updateBalance = () => {
    /*
    ProfileService.getProfile(store.user.id)
      .then((response) => {
        if (response.status http://localhost:4200/dashboard=== 200) {
          dispatch({
            type: "UPDATE_BALANCE",
            payload: {
              balance: response.data.data._balance,
            }
          });
        } else { throw "get user profile failed" }
      })
      .catch((err) => {
        console.log(err);
      })

     */
    dispatch({
      type: "UPDATE_BALANCE",
      payload: {
        balance: store.meta.current_balance,
      }
    });
  }


  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color:  theme.palette['--color-primary'] }}>
            <MenuIcon />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        {store.portalSettings.services.portal_features.display_balance && <StyledBalanceBox>{`${t("credit") + " :"}  ${store.meta.current_balance ? store.meta.currency + " " + store.meta.current_balance.toFixed(2) : "0.00"}`}</StyledBalanceBox>
           }
          {store.portalSettings.services.portal_features.display_billing_account_id && <StyledBalanceBox>{`${t("Billing Account ID") + " :"}  ${store.detail.billing_account_id}`}</StyledBalanceBox>
           }
          <LanguagePopover />
          <Link to="/panel/docs" target="_blank">
            <IconButton
              sx={{
                padding: 0,
                width: 44,
                height: 44,
                fontSize: "30px"
              }}
            >
              <FolderOutlined style={{ color: theme.palette['--main-text-color'] }} fontSize="large" />
            </IconButton>
          </Link>

          <Link to="/shopping-cart">
            <IconButton
              sx={{
                padding: 0,
                width: 44,
                height: 44,
                fontSize: "30px"
              }}
            >
            <ShoppingCartOutlinedIcon style={{ color: theme.palette['--main-text-color'] }} fontSize="large" />
            </IconButton>
          </Link>

         {/*
          <StyledNotificationBox>
            <Link style={{ color: theme.palette['--main-text-color'] }} to="/notifications"><IconButton
              sx={{
                padding: 0,
                width: 50,
                height: 44,
                fontSize: "15px"
              }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
          >

            <Badge badgeContent={store.meta.unread_notification_count ? store.meta.unread_notification_count : 0 } color="error">
              <EmailOutlined style={{ color: theme.palette['--main-text-color'] }}  fontSize="large" />
            </Badge>

          </IconButton>   
          </Link>  
          </StyledNotificationBox>
         
         */}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
