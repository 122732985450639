import {useEffect} from "react";
import api from "../api/Api";
import {useNavigate} from "react-router-dom";
import {useStore} from "./Store";
export default function WithAxios({children, setOpenSnackbar, setMessage}) {
    const navigate = useNavigate();
    const [store, dispatch] = useStore();

    function reloadPageWithoutCache() {
        window.location.href = window.location.href.split('?')[0] + '?cache_date=' + new Date().getTime();
      }

    useEffect(() => {
        api.interceptors.response.use(
            (response) => {
               try {
                   if(response.data.meta.code == 401){
                       setMessage(response.data.meta.msg);
                       setOpenSnackbar(true);
                       localStorage.removeItem("token");
                       localStorage.removeItem("user");
                       navigate("/login", { replace: true });
                       return Promise.reject(response);
                   }else{
                       dispatch({
                           type: "UPDATE_META",
                           payload: {
                               meta: response.data.meta,
                           }
                       });
                   }

                if(localStorage.getItem("portal_version") == null && response.data.meta.portal_version != null){
                    localStorage.setItem("portal_version", response.data.meta.portal_version);
                }

                if(response.data.meta.portal_version != null && localStorage.getItem("portal_version") != response.data.meta.portal_version){
                    localStorage.setItem("portal_version", response.data.meta.portal_version);
                    setMessage("Portal has been updated, page will reload");
                    setOpenSnackbar(true);
                    setTimeout(function(){
                        reloadPageWithoutCache();
                    }, 3000);
                    window.location.reload(true);
                }
                   return Promise.resolve(response);
               }catch (e) {
                   console.log(e);
                   return Promise.resolve(response);
               }
            },
            (error) => {
                if (!error.response) {

                } else {

                    const response = error.response.data;
                    setMessage(response.meta.msg);
                    setOpenSnackbar(true);
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("meta");
                    navigate("/login", { replace: true });
                }
                return Promise.reject(error);
            },
        );
    }, [setMessage])
    return <>{children}</>
}