import api from "src/api/Api";
import urls from "src/api/Urls";


export const listOrganizationUsers = (params) => {
    return api.get(urls.list_team_members, { params: params });
}


export const addOrganizationUser = (payload) => {
    return api.post(urls.invite_team_member, payload);
}


export const updateSelectedLanguage = (id, selectedLanguageId) => {
    return api.post(urls.update_selected_language(id), selectedLanguageId);
}

export const ResetTeamMemberPassword = (payload) => {
    return api.post(urls.resetPassword_team_member, payload);
}
export const acceptInvitation = (payload) => {
    return api.post(urls.accept_invitation, payload);
}

export const setNewPassword = (payload) => {
    return api.post(urls.set_new_password, payload);
}

