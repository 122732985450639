import api from "src/api/Api";
import urls from "src/api/Urls";


export const listLogsSms = (params) => {
    return api.get(urls.list_logs_sms, { params: params });
}

export const listLogsVoice = (params) => {
    return api.get(urls.list_logs_voice, { params: params });
}

export const listLogsForwardedVoice = (params) => {
    return api.get(urls.list_forwarded_voice, { params: params });
}

export const listLogsOutboundVoice = (params) => {
    return api.get(urls.list_outbound_voice, { params: params });
}

export const listLogsOutboundSms = (params) => {
    return api.get(urls.list_outbound_sms, { params: params });
}