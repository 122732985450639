import api from "src/api/Api";
import urls from "src/api/Urls";



export const createPaypalOrder = (payload) => {
    return api.post(urls.create_paypal_order, payload);
}

export const finalizePaypalOrder = (payload) => {
    return api.post(urls.finalize_paypal_order, payload);
}


export const bankTransferRequest = (payload) => {
    return api.post(urls.bank_transfer_request, payload);
}

export const cardOrderRequest = (payload) => {
    return api.post(urls.card_order_request, payload);
}

