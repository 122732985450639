import { useState } from 'react';
// material
import {
    FormControl,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
// app
import { SenderIDService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import  {useStore} from "../../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
// ----------------------------------------------------------------------

export default function CancelForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const cancelRequest = () => {

        let payload = {"id": formData.id}

        SenderIDService.cancelRequest(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage('Request has been successfully submitted', {number: formData.number_value});
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw {message:"Request could not be changed", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
            });
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography component="h6">
                {'You are about to cancel Sender-ID request. Are you sure?'}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>

                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        label={'Exit'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Cancel Request')}
                        onClick={cancelRequest}color={"--color-primary"}
                    />
                </Stack>
            </Stack>
        </>
    );
}
