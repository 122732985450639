import React from 'react';
import { Select, MenuItem } from '@mui/material';

const BaseSelect = ({ label, labelId, name, value, onChange, options, children, sx, disabled }) => {
  return (
    <Select
      disabled={disabled}
      label={label}
      labelId={labelId}
      name={name}
      value={value}
      onChange={onChange}
      sx={{ ...sx, backgroundColor: 'white' }}
    >
      {children}
    </Select>
  );
};

export default BaseSelect;
