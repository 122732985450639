import api from "src/api/Api";
import urls from "src/api/Urls";

export const addToCart = (payload) => {
    return api.post(urls.add_to_cart, payload);
}

export const removeFromCart = (payload) => {
    return api.post(urls.remove_from_cart, payload);
}

export const getCart = (params) => {
    return api.get(urls.get_cart, { params: params});
}

export const clearCart = (params) => {
    return api.post(urls.clear_cart, params);
}