import React, {useState, useEffect} from 'react';
import {styled} from '@mui/system';
import {
    Grid,
    Stack,
    Typography, Box, IconButton
} from '@mui/material';
import {BaseTable, TableFilterContainer} from 'src/components/table';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import {rowArrayToObject} from 'src/utils/Util';
import {
    DefaultPaginationData,
    SetType,
    getLabelByValue,
    Capability, DefaultStatus
} from 'src/constants/index';
import * as NumberService from "../../../api/services/Numbers";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseButton from "../../../components/buttons/BaseButton";
import {useTranslation} from "react-i18next";
import DeleteSMSEndpointForm from "./Forms/DeleteSMSEndpointForm";
import UpdateSMSEndpointForm from "./Forms/UpdateSMSEndpointForm";
import AddSMSEndpointForm from "./Forms/AddSMSEndpointForm";
import BaseCard from 'src/components/BaseCard';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTheme} from "@mui/styles";


const SearchButtonContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({theme}) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function SMSEndpoints() {
    const {t} = useTranslation();
    const TABLE_HEAD = [
        {key: "name", label: "Name"},
        {key: "http_post_url", label: "HTTP Endpoint"},
        {key: "from_field", label: "From Field"},
        {key: "to_field", label: "To Field"},
        {key: "body_field", label: "Body Field"},
        {key: "id_field", label: "ID Field"},
        {key: "is_default", label: "Default"},
        {key: "actions", label: "Actions"},
    ];

    const TABLE_FIELD_MAPPING = {
        //id: { key: "id", cellComponentType: "th", index: 0 },
        name: {key: "name", index: 0},
        http_post_url: {key: "http_post_url", index: 1},
        from_field: {key: "from_field", index: 2},
        to_field: {key: "to_field", index: 3},
        body_field: {key: "body_field", index: 4},
        id_field: {key: "id_field", index: 5},
        is_default: {key: "is_default", index: 6},
        type_field: {key: "type_field", index: 7, noRender:true},
        id: {key: "id", index: 8, noRender:true},
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [addSMSEndpointDialog, setAddSMSEndpointDialog] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState("");
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterDirectionType, setDirectionType] = useState("");
    const [filterSetType, setSetType] = useState("");
    const [filterNumber, setNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");

    const fetchNumbers = () => {
        const params = {
            country: filterCountry ? filterCountry : undefined,
            capability: filterCapability ? filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        NumberService.getSMSEndpoints(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }





    useEffect(() => {
        fetchNumbers();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "is_default") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(DefaultStatus(), d.value),
                    chipColor: d.value === true ? "--color-success" : "--color-warning",
                });
            } else if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "edit") {
            setSearchNumbersDialogStatus(!openSearchNumbersDialog);
        } else if (modalType === "delete") {
            setDeallocateModalStatus(!openDeallocateModal);
        }

        if (index) {
            setSelectedRow(rowArrayToObject(data[index]))
        }
        ;
    }
    const theme = useTheme();

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>

                <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                    <EditIcon
                        sx = {{
                            width:30,
                            height:30,
                            borderRadius:0.5,
                            color: "white",
                            backgroundColor: theme.palette['--color-warning']}}
                        onClick={() => modalHandler("edit", index)} />
                </IconButton>

                <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                    <DeleteIcon
                        sx = {{
                            width:30,
                            height:30,
                            borderRadius:0.5,
                            color: "white",
                            backgroundColor: theme.palette['--color-danger']}}
                        onClick={() => modalHandler("delete", index)} />
                </IconButton>


            </Stack>
        )

    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseDialog title={"Update SMS Interconnection"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<UpdateSMSEndpointForm successCallback={fetchNumbers} formType="add" formData={selectedRow} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={"Add SMS Endpoint"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={addSMSEndpointDialog} setOpen={setAddSMSEndpointDialog} children={<AddSMSEndpointForm successCallback={fetchNumbers} formType="add" formData={{}} setModalStatus={setAddSMSEndpointDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={"Are you sure?"} open={openDeallocateModal} setOpen={setDeallocateModalStatus} children={<DeleteSMSEndpointForm successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setDeallocateModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>
                <br/>
                <SearchButtonContainer item md={12} xs={12}>
                    <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                        <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                        </TitleStyle>
                        </Box>
                        <BaseButton
                            label={'Add SMS Endpoint'}
                            onClick={() => setAddSMSEndpointDialog(true)}
                        />
                    </Box>
                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br/>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => {
                    return formatRowData(d);
                })}
                actionItemPrep={getActionItems}
                pagination={{
                    paginationData: {...paginationData, totalCount: totalCount},
                    setPaginationData: setPaginationData
                }}
                loadingData={loadingData}
            />
        </>
    );
}
