import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function DeallocateNumberForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const deallocateNumber = () => {
        setLoading(true);
        console.log(formData);
        const payload = {
            did_id: formData.did_id,
        };

        NumberService.deallocateNumber(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage(t('has-been-successfully-deallocated', {number: formData.number_value}));
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw {message:"number could not be deallocated", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {t('are-you-sure-deallocate-this-number')}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={deallocateNumber}
                    >
                        {t('deallocate')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
