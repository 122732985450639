import api from "src/api/Api";
import urls from "src/api/Urls";


export const getProducts = (params) => {
    return api.get(urls.get_products, { params: params });
}

export const getProduct = (params) => {
    return api.get(urls.get_product, { params: params });
}
