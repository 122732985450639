import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import PersonalIdentities from "./personalidentities";
import Addresses from "./addresses";


export default function Addresstab() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('Addresses'), component: <Addresses /> },
    ];

    const TITLE = t('Addresses');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
