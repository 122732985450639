// ----------------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl, Box
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import {makeStyles} from '@mui/styles';
import * as NumberService from "../../../../api/services/Numbers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function PortoutDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {

    }, []);

    return (
        <>
            <Grid container sx={{m:1}} justifyContent="center">
                <div>You will submit a port-out request if applicable</div>
            </Grid>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="center" container spacing={1}>
                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <Box sx={{m:1}}/>
                    <BaseButton
                        label={'OK'}
                        onClick={() => successCallback()} color={"--color-primary"}
                    />
            </Grid>
        </>
    );
}

