import api from "src/api/Api";
import urls from "src/api/Urls";



export const listPortings = (params) => {
    return api.get(urls.listPorting, { params: params });
}

export const listPortOutDetails = (id) => {
    return api.get(urls.listPortOutDetail(id));
}

export const listSettings = (params) => {
    return api.get(urls.listSettings, {params:params});
}

export const add = (payload) => {
    return api.post(urls.add, payload);
}

export const resubmit = (payload) => {
    return api.post(urls.update, payload);
}

export const reschedule = (payload) => {
    return api.post(urls.reschedule_porting, payload);
}

export const translate = (id, payload) => {
    return api.post(urls.translate(id), payload);
}


export const listPortingDidTypes = (params) => {
    return api.get(urls.listPortInDidTypes, {params:params});
}