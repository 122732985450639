import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BaseCard = ({ children }) => {
  const theme = useTheme();

  const backgroundColor = theme.palette['--card-background-color'];

  return (
    <Card elevation={4} sx={{ backgroundColor }}>
      {children}
    </Card>
  );
};

BaseCard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseCard;
