import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  Grid,
  CardContent,
  FormControlLabel,
  Stack,
  Button,
  Container,
  Box,
  FormControl,
  InputLabel
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import BaseSnackbar from "src/components/BaseSnackbar";
import { useStore } from "src/store/Store";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import Logo from "../../Logo";
// ----------------------------------------------------------------------
import {CommonService, CustomerService} from "src/api/services";
import BaseCard from "../../BaseCard";
import {useTheme} from "@mui/styles";
import BaseSelect from "src/components/BaseSelect";
import { getSelectOptions } from "src/constants";
export default function RegisterForm({
                                       setModalStatus,
                                       setSnackbarStatus,
                                       setExternalMessage,
                                       successCallback,
                                       formType = "register",
                                     }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [store, dispatch] = useStore();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [logo, setLogo] = useState("");
  const [filterCountry, setFilterCountry] = useState(0);
  const [countries, setCountries] = useState([]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const fetchCountries = () => {
    CommonService.getRegisterCountries({})
        .then((response) => {
            let items = [];
            Object.entries(response.data.data).forEach((item) => {
                items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["id"] });
            })
            setCountries(items);
        })
        .catch((err) => {
            console.log(err);
        })
};

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    useEffect(() => {
        if (store.logo) {
            setLogo(store.logo);
        }
        fetchCountries();
    }, [store.manualUpdateTriggeredForPalette, store.logo]);



    const isMobile = width <= 768;

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, t("too-short-0"))
        .max(50, t("too-long-0"))
        .required(t("first-name-is-required")),
    lastName: Yup.string()
        .min(2, t("too-short-1"))
        .max(50, t("too-long-1"))
        .required(t("last-name-is-required")),
    email: Yup.string()
        .email(t("form.__i18n_ally_root__.validation.email"))
        .required(t("email-is-required")),
    companyName: Yup.string()
        .min(2, t("too-short-2"))
        .max(50, t("too-long-2"))
        .required(t("organization-is-required")),
    password: Yup.string()
        .min(8, t("too-short-3"))
        .required(t("password-is-required")),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ).required(t("password-is-required")),
    address1: Yup.string()
        .min(2, t("too-short-4"))
        .max(50, t("too-long-3"))
        .required(t("Address is required")),
    address2: Yup.string()
        .min(2, t("too-short-5"))
        .max(50, t("too-long-4"))
        .required(t("Address is required")),
  });

  const getFormData = (values) => {
    return {
      company_name: values.companyName,
      customer_type: values.customerType,
      email: values.email,
      name: values.firstName,
      password: values.password,
      surname: values.lastName,
      telephone: values.telephone,
      address1: values.address1,
      address2: values.address2,
    };
  };
  const formik = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      firstName: "",
      lastName: "",
      companyName: "",
      telephone: "",
      email: "",
      password: "",
      confirmPassword: "",
      customerType: "0"
        },
      validationSchema: RegisterSchema,
      onSubmit: (values, { setSubmitting }) => {
      const payload = getFormData(values);

      if (filterCountry) {
        payload.country_id = parseInt(filterCountry);
      } else {
        setMessage(t("Country is required"));
        setOpenSnackbar(true);
        setSubmitting(false);
        return;
      }

      CustomerService.registerCustomer(payload)
          .then((response) => {
            if (response.data.meta.code === 200) {
              if (formType === "register") {
                setMessage(t("you-have-successfully-registered")+ "\nPlease check your email for approval.");
                setOpenSnackbar(true);
              } else {
                if (setExternalMessage) {
                  setExternalMessage(
                      t(
                          "customer-and-its-initial-user-has-been-successfully-created"
                      )
                  );
                }
                if (setSnackbarStatus) {
                  setSnackbarStatus(true);
                }
                if (setModalStatus) {
                  setModalStatus(false);
                }
                if (successCallback) {
                  successCallback();
                }
              }
            } else {
              throw {
                message: "failed",
                response: response,
              };
            }
          })
          .catch((err) => {
            setMessage(err.response.data.meta.msg);
            setOpenSnackbar(true);
          }).finally((err) =>{
            setSubmitting(false);
      });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const openLoginForm = () => {
    navigate("/login");
  };
    const theme = useTheme();

  return (
      <>
        <BaseSnackbar
            open={openSnackbar}
            message={message}
            setOpen={setOpenSnackbar}
        />


        <Container maxWidth="xs"
                   sx={ isMobile ? {
                     height: "20vh",
                     position: "relative",
                     top: '10vh',
                     left: 0,

                   } : { left: "5%", bottom: "10vh", position: window.innerHeight < 800 ? "relative" : "absolute" }}
        >


            <Grid

          >
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                component={Card}
            >

                <BaseCard>
                    <CardContent sx={{
                        marginX:1
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingY: 3,
                        }}
                    >
                        <Logo sx={{ width:200}} src={logo} /></Box>


                  <Grid item mb={1}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack direction="column"  spacing={2}>
                    <Stack direction="row"  spacing={2}>

                      <TextField
                          fullWidth
                          label={t("first-name-0")}
                          {...getFieldProps("firstName")}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          sx={{ mb:1}}
                      />

                      <TextField
                          fullWidth
                          label={t("last-name-0")}
                          {...getFieldProps("lastName")}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          sx={{ mb:1}}

                      />
                      </Stack>
                      <Stack direction="row"  spacing={2}>

                      <TextField
                          fullWidth
                          label={t("Company name")}
                          {...getFieldProps("companyName")}
                          error={Boolean(touched.companyName && errors.companyName)}
                          helperText={touched.companyName && errors.companyName}
                          sx={{ mb:1}}

                      />

                      <TextField
                          fullWidth
                          label={"Telephone"}
                          {...getFieldProps("telephone")}
                          /* error={Boolean(touched.telephone && errors.telephone)}
                    helperText={touched.telephone && errors.telephone} */
                          sx={{ mb:1}}

                      />
                      </Stack>
                    <Stack direction="row"  spacing={2}>

                      <TextField
                          fullWidth
                          type="email"
                          label={"Company E-Mail"}
                          {...getFieldProps("email")}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          sx={{ mb:1}}

                      />

                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>

                     

                    </Stack>

                    <Stack direction="row"  spacing={2}>
                    <TextField
                          fullWidth
                          type="address1"
                          label={"Address Line 1"}
                          {...getFieldProps("address1")}
                          error={Boolean(touched.address1 && errors.address1)}
                          helperText={touched.address1 && errors.address1}
                          sx={{ mb:1}}

                      />

                      <TextField
                          fullWidth
                          type="address2"
                          label={"Address Line 2"}
                          {...getFieldProps("address2")}
                          error={Boolean(touched.address2 && errors.address2)}
                          helperText={touched.address2 && errors.address2}
                          sx={{ mb:1}}

                      />

            

                    </Stack>
                    <Stack direction="row"  spacing={2}>
                    <TextField
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          label={t("password")}
                          {...getFieldProps("password")}
                          sx={{ mb:1}}

                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                      edge="end"
                                      onClick={() => setShowPassword((prev) => !prev)}
                                  >
                                    <Icon
                                        icon={showPassword ? eyeFill : eyeOffFill}
                                    />
                                  </IconButton>
                                </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                      />

                      <TextField
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          label={"Confirm Password"}
                          {...getFieldProps("confirmPassword")}
                          error={Boolean(
                              touched.confirmPassword && errors.confirmPassword
                          )}
                          helperText={
                              touched.confirmPassword && errors.confirmPassword
                          }
                          sx={{ mb:1}}
                      />

                       

                    </Stack>
                      <FormControlLabel
                          control={
                            <Checkbox
                                sx={{color: theme.palette['--main-text-color'],   '&.Mui-checked': {
                                        color: theme.palette['--main-text-color'],
                                    },}}
                                checked={checked}
                                onChange={(event) => setChecked(event.target.checked)}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="I agree with terms and conditions" sx={{mb:1, color: theme.palette['--main-text-color']}}
                      />

                      <Stack
                          direction={"row"}
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            justifyItems:'center',

                          }}
                      >
                        <LoadingButton
                            sx={{'&:hover': {
                                    backgroundColor: theme.palette['--color-primary']
                                },backgroundColor:theme.palette['--color-primary']}}
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                          {t("register-0")}
                        </LoadingButton>
                        <Box sx={{ml:1}} ><p>or</p></Box>
                        <Button
                            sx={{'&:hover': {
                                    color: theme.palette['--color-search'],  borderColor: theme.palette['--color-search']
                                },color: theme.palette['--color-search'],  borderColor: theme.palette['--color-search']}}
                            onClick={() => openLoginForm()}
                        >
                          Sign in
                        </Button>
                      </Stack>
                    </Stack>
                    </Form>
                  </FormikProvider>
                </Grid>
              </CardContent>
                </BaseCard>
            </Grid>
          </Grid>
        </Container>
      </>
  );
}
