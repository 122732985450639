import api from "src/api/Api";
import urls from "src/api/Urls";


export const listIboundsTariffs = (params) => {
    return api.get(urls.list_inbound_rates, { params: params });
}
export const listOutboundVoiceTariffs = (params) => {
    return api.get(urls.list_outBound_voice_rates, { params: params });
}
export const listOutboundSmsTariffs = (params) => {
    return api.get(urls.list_outBound_sms_rates, { params: params });
}


export const download_outbound_voice_tariffs = (params) => {
    return api.get(urls.download_outbound_voice_tariffs, {params: params,responseType: 'blob'});
}

export const download_portal_tariffs = (params) => {
    return api.get(urls.download_portal_tariffs, {params: params,responseType: 'blob'});
}

