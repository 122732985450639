// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, Select, TextField, MenuItem, FormControl, InputLabel, Stack, IconButton, Tooltip } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import { rowArrayToObject } from 'src/utils/Util';
import { DefaultPaginationData, Capability, DidType, NumberSearchType, getSelectOptions, getLabelByValue } from 'src/constants/index';
import { NumberService } from 'src/api/services';
import BaseModal from 'src/components/BaseModal';
import OrderNumberForm from './OrderNumberForm'
import { useTranslation } from 'react-i18next';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function SearchNumberForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "did", label: t('number') },
        { key: "capability", label: t('capability') },
        { key: "did_type", label: t('type') },
        { key: "mrc", label: "MRC" },
        { key: "nrc", label: "NRC" },
        { key: "sms_price", label: t('sms-price') },
        { key: "voice_peak_offpeak_weekend_price", label: t('voice-price-peak-offpeak-weekend') },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        did: { key: "did", index: 0 },
        capability: { key: "capability", index: 1 },
        did_type: { key: "did_type", index: 2 },
        mrc: { key: "mrc", index: 3 },
        nrc: { key: "nrc", index: 4 },
        sms_price: { key: "sms_price", index: 5 },
        voice_peak_offpeak_weekend_price: { key: "voice_peak_offpeak_weekend_price", index: 6 },
        currency: { key: "currency", index: 7, noRender: true },
        voice_offpeak_price: { key: "voice_offpeak_price", index: 8, noRender: true },
        voice_price: { key: "voice_price", index: 9, noRender: true },
        voice_weekend_price: { key: "voice_weekend_price", index: 10, noRender: true },
        extra_params: { key: "extra_params", index: 11, noRender: true },
    };
    
    const [countries, setCountries] = useState([]);
    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);

    const [filterCapability, setCapability] = useState("");
    const [filterType, setType] = useState("");
    const [filterNumberSearch, setNumberSearch] = useState("");
    const [filterNumber, setNumber] = useState("");
    const [filterCountry, setFilterCountry] = useState("0");
    const [searchedCountry, setSearchedCountry] = useState("0");

    const fetchNumbers = () => {
        const params = {
            country: filterCountry !== "0" ? filterCountry : undefined,
            capability: filterCapability !== "0" ? filterCapability : undefined,
            number_type: filterType !== "0" ? filterType : undefined,
            number_regex: filterNumberSearch !== "0" ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        NumberService.searchNumbers(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.count);
                for (const idx in response.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setSearchedCountry(filterCountry);
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        if (countries.length > 0 && filterCountry != "0") {
            fetchNumbers();
        }
        return () => {
            setData([]);
        }
    }, [paginationData, countries, filterCountry]);


    useEffect(() => {
        NumberService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data).forEach(([key, value]) => {
                    Object.entries(value).forEach(([code, name]) => {
                        if (code === "null") {
                            items.push({ code: "0", name: name });
                        } else {
                            items.push({ code: code, name: name });
                        }
                    })
                })
                setCountries(items);
                if (items.length > 0) {
                    setFilterCountry(items[0].code);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "mrc" || d.key === "nrc" || d.key === "sms_price") {
                formatted.push({
                    ...d,
                    value: rowData[7].value + d.value,
                });
            } else if (idx === 6) {
                formatted.push({
                    key: "voice_peak_offpeak_weekend_price",
                    index: 6,
                    value: rowData[7].value + rowData[9].value + '/' + rowData[8].value + '/' + rowData[10].value,
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const orderNumberModalHandler = (index = undefined) => {
        setOrderNumberModalStatus(!openOrderNumberModal);
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
        return (
            <Stack direction="row" spacing={2}>
                <Tooltip title={t('buy-number')}>
                    <IconButton color="secondary" size="small" aria-label="edit-sms-tariff" onClick={() => orderNumberModalHandler(index)}>
                        <ShoppingCartIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    return (
        <>
            <BaseModal title={t('are-you-sure')} open={openOrderNumberModal} setOpen={setOrderNumberModalStatus} children={<OrderNumberForm successCallback={fetchNumbers} formData={{...selectedRow, country: searchedCountry}} setModalStatus={setOrderNumberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="country-label">{t('country-2')}</InputLabel>
                            <Select
                                label={t('country-2')}
                                labelId="country-label"
                                color="secondary"
                                value={filterCountry}
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {countries.map((country, idx) => {
                                    return <MenuItem key={country.code} value={country.code}>{country.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-capability-label">{t('capability-0')}</InputLabel>
                            <Select
                                label={t('capability-1')}
                                labelId="filter-capability-label"
                                name="status"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => { setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{t('type')}</InputLabel>
                            <Select
                                label={t('type')}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterType}
                                onChange={event => { setType(event.target.value) }}
                            >
                                {getSelectOptions(DidType())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-number-search-label">{t('number-search')}</InputLabel>
                            <Select
                                label={t('number-search-0')}
                                labelId="filter-number-search-label"
                                name="number-search"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions(NumberSearchType())}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={filterNumber}
                                label={t('number-0')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setNumber(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
            </TableFilterContainer>

            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}

