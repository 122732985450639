import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BaseTextField = ({ value, label, name, onChange, sx, disabled = false }) => {
  const theme = useTheme();

  const color = theme.palette['--color-search'];

  return (
    <TextField
    disabled={disabled}
    value={value}
    label={label}
    name={name}
    multiline={true}
    rowsMax={3}
    variant="outlined"
    InputLabelProps={{
        style: { color: color },
      }}
    sx={{...sx, backgroundColor: 'white', borderRadius: '10px' }}
    onChange={event => { onChange(event.target.value) }}
/>
  );
};

BaseTextField.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseTextField;
