import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import {useState} from "react";
import AddPortInRequest from "./AddSenderIDRequest";
import SenderIDRequests from './requests';
import AddSenderIDRequest from './AddSenderIDRequest';
// ----------------------------------------------------------------------


export default function SenderIDSidebar() {
    const { t } = useTranslation();
    const [page, setSelectedPage] = useState(0);
    const change = () => {
        setSelectedPage(1);
    }
    const TABS = [
        { id: 0, label: "Sender-ID Requests", component: page == 0 ? <SenderIDRequests changePage={change} /> : <AddSenderIDRequest callback={setSelectedPage} /> },
    ];

    const TITLE = t('Sender-ID Requests');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
