
import React from 'react';
import { useState, useEffect} from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { PortingService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseModal from 'src/components/BaseModal';
import {BaseTable, TableFilterContainer} from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    getLabelByValue,
    SetType,
    PortOrderStatus,
    PortInStatus,
    getColorByValue,
    getSelectOptions,
    NumberStatus,
    NumberSearch
} from 'src/constants/index';
import dayjs from 'dayjs';
import ShowPortOuts from './Forms/ShowPortOuts';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box,FormControl,Grid,IconButton, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BaseCard from 'src/components/BaseCard';
import { useTheme } from '@mui/material/styles';
import { CalendarMonth, Edit, UploadFile } from '@mui/icons-material';
import PortinResubmit from './PortinResubmit';
import BaseDialog from 'src/components/BaseDialog';
import ReschedulePortingForm from './Forms/RescheduleForm';
import PortinResubmitForm from './PortinResubmit';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseSelect from 'src/components/BaseSelect';
import BaseTextField from 'src/components/BaseTextField';
import { styled } from '@mui/system';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function PortIn({changePage}) {
    const { t } = useTranslation();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [openShowModal, setShowModalStatus] = useState(false);
    const [openResubmit, setOpenResubmit] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openScheduleModal, setRescheduleModal] = useState(false);
    const navigate = useNavigate();


    const setResubmit = () => {
        fetchPortings();
        setOpenResubmit(!openResubmit);
    }


    const TABLE_HEAD = [
        { key: "id", label: t('id')},
        { key: "country", label: t('country')},
        { key: "numbers", label: t('numbers')},
        { key: "type_name", label: t('DID Type')},
        { key: "authorization", label: t('Authorization Code')},
        { key: "requested_at", label: t("Requested At") },
        { key: "schedule", label: t("Scheduled At") },
        { key: "status", label: t('Status') },
        { key: "action", label: t('actions')}
    ];


    const TABLE_FIELD_MAPPING = {
        id : { key: "id", index: 0 },
        country: { key: "country", index: 1 },
        numbers: { key: "numbers", index: 2 },
        type_name: { key: "type_name", index: 3 },
        pac: { key: "authorization", index: 4},
        requested_at: { key: "requested_at", index: 5 },
        scheduled_at : { key: "schedule", index: 6},
        status: {key: "status", index: 7},
        action: {key: "actions", index:8},
        porting_settings_id: { key: "porting_settings_id", index: 9, noRender: true },
        identity_id: { key: "identity_id", index: 10, noRender: true },
        address_id: { key: "address_id", index: 11, noRender: true }
    };

    const fetchPortings = () => {
        const params = {
            size: paginationData.rowsPerPage,
            porting_type: 1,
            page: paginationData.page + 1,
            status: filterStatus ? filterStatus : undefined,
            number: filterNumber ? filterNumber : undefined,
            sort_by: sortBy ? sortBy : undefined,
        };

        if(params.status === 0 || params.status === "0"){
            delete params.status;
        }

        setLoadingData(true);
        PortingService.listPortings(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    useEffect(() => {
        fetchPortings();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(PortInStatus(), d.value.toString()), chipColor: getColorByValue(PortInStatus(), d.value.toString()),
                });
            } else if(d.key === "schedule"){
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY') : "",
                });
            }else if(d.key === "numbers"){
                formatted.push({
                    ...d,
                    value: d.value ? d.value.join(', ') : "",
                });
            }else if(d.key === "requested_at"){
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    };

    const modalHandler = (modalType, index = undefined) => {

        const detailsID = data[index][0].value;

        if (modalType === "show") {
            navigate(`/port-in/details/${detailsID}`);
        }

        if (modalType === "edit") {
            setResubmit(true);
        }

        if (modalType === "reschedule") {
            setRescheduleModal(true);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };


    }
    const theme = useTheme();

    const getActionItems = (index) => {

        return(
            <>
            <Grid direction="row" container spacing={1} justifyContent="left">
            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <VisibilityIcon
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-success']}}
                    onClick={() => modalHandler("show", index)}/>
            </IconButton>
            {data[index][7].value == 1 ? <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <CalendarMonth
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-warning']}}
                    onClick={() => modalHandler("reschedule", index)}/>
            </IconButton> : <></>
                }
            {data[index][7].value == 6 && <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                <UploadFile
                    sx = {{
                        width:30,
                        height:30,
                        borderRadius:0.5,
                        color: "white",
                        backgroundColor: theme.palette['--color-warning']}}
                    onClick={() => modalHandler("edit", index)}/>
            </IconButton>
                }
            </Grid>
            </>
        );
    };

    const DIALOG_PROPERTIES = {
        fullWidth: true,
        maxWidth: "lg",
        scroll: "body",
    }

    const sortByOptions = () => {
        return [
            { value: "id", label: t('id')},
            { value: "requested_at", label: t('Requested At')},
            { value: "scheduled_at", label: t('Scheduled At')},
        ];
    }



    const [filterStatus, setStatus] = useState(0);
    const [filterNumber, setNumber] = useState("");
    const [sortBy, setSortBy] = useState("id");


    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseModal title={t("Porting")+"#"+selectedRow.id} open={openShowModal} setOpen={setShowModalStatus} children={<ShowPortOuts formData={selectedRow}/>} />

            <BaseDialog closeCallback={setOpenResubmit} dialogProperties={DIALOG_PROPERTIES} title={t("Porting")+"#"+selectedRow.id} open={openResubmit} setOpen={setResubmit} children={<PortinResubmitForm callback={setResubmit} formData={selectedRow} setMessage={setMessage} setSnackbarStatus={setSnackbarStatus} setModalStatus={setOpenResubmit}  successCallback={fetchPortings} />} />

            <BaseModal title={t('Reschedule Porting Request')} open={openScheduleModal} setOpen={setRescheduleModal} children={<ReschedulePortingForm successCallback={fetchPortings} formData={selectedRow} setModalStatus={setRescheduleModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                <BaseCard>
                <TableFilterContainer >
                <Grid sx={{ alignItems: "center", justifyItems: "center", alignContent: "center" }} container spacing={2}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('Status')}</InputLabel>
                            <BaseSelect
                                label={t('Status')}
                                labelId="filter-status-label"
                                name="status"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setStatus(event.target.value) }}
                            >
                                {getSelectOptions(PortInStatus())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField  value={filterNumber}
                                            label={t('number-0')}
                                            name="number"
                                            onChange={setNumber }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Sort By'}</InputLabel>
                            <BaseSelect
                                label={'Sort By'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={sortBy}
                                onChange={event => {setSortBy(event.target.value) }}
                            >
                                {getSelectOptions(sortByOptions(), ['0'], false)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                    <SearchButton onClick={() => { fetchPortings() }} />
                    </SearchButtonContainer>
                </Grid>
                    <Box alignItems={"center"} sx={{
                        height: 50,
                        display: 'flex'
                    }}>
                        <BaseButton
                            label= {t("+ Add New Port-In Request")}
                            onClick={() => changePage()}
                        />
                    </Box>
                </TableFilterContainer>
                </BaseCard>
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>

    );
}

