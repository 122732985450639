import api from "src/api/Api";
import urls from "src/api/Urls";

export const getPreOrders = (params) => {
    return api.get(urls.list_preorders, { params: params });
}

export const getOrders = (params) => {
    return api.get(urls.list_orders, { params: params });
}

export const getOrderDetail = (id) => {
    return api.get(urls.purchaseorder_detail(id));
}

export const getPreOrderDetail = (id) => {
    return api.get(urls.preorder_detail(id));
}

