// ----------------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Box
} from '@mui/material';
import BaseButton from "../../../../components/buttons/BaseButton";
import {makeStyles} from '@mui/styles';
import * as IdentityService from "../../../../api/services/Identities";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));



export default function DeleteSubcustomerDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const getValueFromFormData = (key) => {
        const filtered = formData.filter((item) => item.key === key);
        if(filtered.length > 0) {
            return filtered[0].value;
        }
        return "";
    }
    const deleteSubcustomer = () => {
        IdentityService.deleteSubCustomer({}, getValueFromFormData("id"))
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Sub customer deleted successfully");
                    setSnackbarStatus(true);
                    successCallback();
                    setModalStatus(false);
                } else { throw "Error while deleting sub customer"; }
            })
            .catch((err) => {
                setMessage(err.toString());
                setSnackbarStatus(true);
                setModalStatus(false);
            })
    }

    return (
        <>
            <Grid container sx={{m:1}} justifyContent="center">
                You are about to delete the sub customer. Are you sure?
            </Grid>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={1}>
                <BaseButton
                    label={'Cancel'}
                    onClick={() => setModalStatus(false)} color={"--color-search"}
                />
                <Box sx={{m:1}}/>
                <BaseButton
                    label={'OK'}
                    onClick={deleteSubcustomer} color={"--color-primary"}
                />
            </Grid>
        </>
    );
}

