import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl,
    Button, SvgIcon
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { rowArrayToObject } from 'src/utils/Util';
import {DefaultPaginationData} from 'src/constants';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, AppBar, Toolbar } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import {makeStyles, useTheme} from "@mui/styles";
import BaseButton from "../../components/buttons/BaseButton";
import {CreditService} from 'src/api/services';
import RevolutCheckout from '@revolut/checkout'
import {useNavigate} from "react-router-dom";
import { useStore } from 'src/store/Store';

const useStyles = makeStyles(theme => ({
    customButton: {
        '&.MuiButton-outlinedPrimary': {
            borderColor: theme.palette['--color-primary'],
            border: '4px solid'
        },
    },
}));

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function AddCredit({ reportView = false, dateRange }) {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openEditModal, setEditModalStatus] = useState(false);
    const [openDeleteModal, setDeleteModalStatus] = useState(false);
    const [openTestModal, setTestModalStatus] = useState(false);
    const [openAddServiceModal, setAddServiceModalStatus] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [selectedMethod, setSelectedMethod] = useState(0);

    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [contact, setContact] = useState("");
    const [amount, setAmount] = useState("");
    const [address, setAddress] = useState("");

    const searchParams = new URLSearchParams(window.location.search);

    const payerID = searchParams.get('PayerID') ?? null;
    const paymentToken = searchParams.get('token') ?? null;
    const paymentID = searchParams.get('paymentId') ?? null;


    const classes = useStyles();
    const navigate = useNavigate();


    const paypalEnabled = store.portalSettings.services.payment_methods.paypal ?? false;
    const revolutEnabled = store.portalSettings.services.payment_methods.revolut ?? false;
    const invoiceEnabled = store.portalSettings.services.payment_methods.invoice_request ?? false;


    const paypalOrder = () => {
        const params = {"email":email,"amount":amount,"company":company,"address":address,"contact":contact}
        CreditService.createPaypalOrder(params)
            .then((response) => {
                if (response.data.meta.code === 200) {
                    window.location.href = response.data.redirect_url;
                } else {
                    throw {message:"Something went wrong", response:response};
                }
            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
            });
    }

    const bankOrder = () => {
        const params = {"email":email,"amount":amount,"company":company,"address":address,"contact":contact}
        CreditService.bankTransferRequest(params)
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Request Invoice is done");
                    setSnackbarStatus(true);
                } else {
                    throw {message:"Something went wrong", response:response};
                }
            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
            });
    }

    const openRevolutModal = (token, name, email, address, company) => {
        console.log("paramvalues", email, name, address, company);
        console.log("mytoken", token);
        console.log("XXXXXXX invoice");

        RevolutCheckout(token, 'sandbox').then(function (instance) {
            instance.payWithPopup({
                name: name,
                email: email,
                phone: '+447950630319',
                locale: 'en',
                billingAddress: {
                    countryCode: 'US',
                    region: company,
                    city: 'London',
                    streetLine1: address,
                    streetLine2: '1 Canada Square',
                    postcode: 'EC2V 6DN'
                },
                shippingAddress: {
                    countryCode: 'US',
                    region: company,
                    city: 'London',
                    streetLine1: address,
                    streetLine2: '1 Canada Square',
                    postcode: 'EC2V 6DN'
                },
                onSuccess() {
                    console.log("HERE");
                    /*
                    localStorage.setItem("mytoken", token);
                    creditobj.manageCreditService.transactionorder_status(token).pipe(
                        tap(res => {
                            console.log("ynttoken_123", res);
                        }),
                        finalize(() => {
                            console.log("finalized");
                        })
                    ).subscribe();
                    */
                },
                onError(message) {

                },
                onCancel() {

                },
            });
        });
    }


    const cardOrder = () => {
        const params = {"email":email, "amount":amount, "company":company,"address":address,"name":contact}
        CreditService.cardOrderRequest(params)
            .then((response) => {
                if (response.data.meta.code === 200) {
                    openRevolutModal(response.data.data.public_id, params.name, params.email, params.address, params.company);
                } else if(response.data.meta.code === 404){
                    setMessage("Revolut Merchant API key not found!");
                    setSnackbarStatus(true);
                } else {
                    throw {message:"Something went wrong", response:response};
                }

            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
            });
    }

    const runOrder = () => {
        if(selectedMethod == 0){
            paypalOrder();
        }else if(selectedMethod == 1){
            cardOrder();
        }else{
            bankOrder();
        }
    }

    const finalizePaypalOrder = () => {
        const params = {
            payment_id: paymentID,
            payer_id: payerID,
        }

        CreditService.finalizePaypalOrder(params)
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                    navigate('/transactions');
                } else {
                    throw {message:"Something went wrong", response:response};
                }
            })
            .catch((err) => {
                setMessage(err.response.data.meta.msg);
                setSnackbarStatus(true);
                navigate('/add-credit');
            });
    }

    useEffect(() => {

        if(paymentID && payerID){
            finalizePaypalOrder();
        }else if(paymentToken){
            setMessage("Transaction is cancelled");
            setSnackbarStatus(true);
            navigate('/add-credit', { replace: true });
        }

        return () => {
            setData([]);
        }
    }, [paginationData, dateRange]);



    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "add") {
            setAddServiceModalStatus(!openAddServiceModal);
        } else if (modalType === "edit") {
            setEditModalStatus(!openEditModal);
        } else if (modalType === "delete") {
            setDeleteModalStatus(!openDeleteModal);
        } else if (modalType === "test") {
            setTestModalStatus(!openTestModal)
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const theme = useTheme();

    return (
        <>
            {!reportView ? <><BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
                <TableFilterContainer>
                    <Grid container sx={{paddingY:3, paddingX:3}} justifyContent="space-between">

                        <Grid container  justifyContent="left" md={3} xs={12}>

                            <Typography sx={{fontSize:20}}  >
                                Choose payment method below
                            </Typography>
                        </Grid>


                        <Grid item md={4} xs={12}>
                                <Card>
                                    <AppBar position="static" sx={{backgroundColor:theme.palette['--color-primary']}}>
                                        <Toolbar>
                                            <AccountBalanceWalletIcon sx={{marginRight:1}}/> <Typography sx={{fontSize:20}} color="white" >
                                               Balance
                                            </Typography>
                                        </Toolbar>
                                    </AppBar>
                                    <CardContent style={{ backgroundColor: theme.palette['--sidebar-background-color'] }}>
                                        <Toolbar>
                                            <Typography sx={{fontSize:24}} color="white" >
                                                You have {store.meta.current_balance ? store.meta.currency + " " + store.meta.current_balance : "0.00"} credit left
                                            </Typography>
                                        </Toolbar>
                                    </CardContent>
                                </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={6} sx={{paddingY:3, paddingX:3}} >
                        {paypalEnabled && (                        <Grid item xs={4}>
                            <Button disableRipple
                                    variant={selectedMethod == 0 ? "outlined" : "contained"} className={classes.customButton}
                                    style={{ width: '100%', height: '200px', fontSize: '24px', color: "#29437C", backgroundColor: "#F3F9FD" }}
                                    onClick={() => setSelectedMethod(0)}
                            >
                                <Grid item >
                                    <SvgIcon  sx={{ fontSize: 110 }} >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333333 199007" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"><path d="M197991 54837c-4872-5548-13680-7930-24941-7930h-32687c-2302 0-4274 1663-4622 3946l-13622 86322c-268 1703 1047 3248 2776 3248h20195l5076-32153-163 1006c347-2281 2300-3946 4601-3946l9590 4c18842 0 33587-7639 37907-29786 124-658 243-1295 328-1914 1274-8177 0-13764-4438-18794v-2z" fill="#003087"/><path d="M208161 78610c-4911 21800-20072 33304-43967 33304l-7948-2-5528 35091-6661 4-347 2259c-225 1498 926 2834 2425 2834h17015c2011 0 3722-1459 4050-3451l163-861 3209-20321 204-1110c307-1992 2032-3452 4050-3452l2523-1c16473 0 29378-6698 33160-26052 1421-7357 881-13600-2341-18242h-6z" fill="#009cde"/><path d="M31366 0h270600c8631 0 16474 3528 22156 9210 5683 5683 9211 13526 9211 22156v136275c0 8629-3529 16472-9211 22155-5683 5682-13526 9211-22155 9211H31367c-8629 0-16473-3528-22156-9211C3529 184114 1 176272 1 167641V31366c0-8631 3528-16474 9210-22156S22737 0 31368 0zm270600 10811H31366c-5647 0-10785 2315-14513 6043s-6043 8866-6043 14513v136275c0 5646 2315 10784 6043 14512 3729 3729 8867 6044 14513 6044h270600c5645 0 10783-2315 14512-6044 3728-3729 6044-8867 6044-14511V31368c0-5645-2315-10784-6043-14513-3728-3728-8867-6043-14513-6043z" fill="gray" fill-rule="nonzero"/></svg>
                                    </SvgIcon>
                                    <Typography sx={{fontSize:18, fontWeight:"bold"}} color="#29437C" >
                                        Pay with PayPal
                                    </Typography>
                                </Grid>

                            </Button>

                        </Grid>)}


                        {revolutEnabled && (<Grid item xs={4} justifyContent="start">
                            <Button disableRipple
                                    variant={selectedMethod == 1 ? "outlined" : "contained"} className={classes.customButton}
                                    style={{ width: '100%', height: '200px', fontSize: '24px', color: "#29437C", backgroundColor: "#F3F9FD" }}
                                    onClick={() => setSelectedMethod(1)}

                            >
                                <Grid item>
                                    <PaymentIcon  sx={{ fontSize: 100 }} />
                                    <Typography sx={{fontSize:18, fontWeight:"bold"}} color="#29437C" >
                                        Pay with Credit Card
                                    </Typography>
                                </Grid>

                            </Button>

                        </Grid>)}

                        {invoiceEnabled && (<Grid item xs={4} justifyContent="start">
                            <Button disableRipple
                                    variant={selectedMethod == 2 ? "outlined" : "contained"} className={classes.customButton}
                                    style={{ width: '100%', height: '200px', fontSize: '24px', color: "#29437C", backgroundColor: "#F3F9FD" }}
                                    onClick={() => setSelectedMethod(2)}

                            >
                                <Grid item>
                                    <AccountBalanceIcon  sx={{ fontSize: 80 }} />
                                    <Typography sx={{fontSize:18, fontWeight:"bold"}} color="#29437C" >
                                        Pay With Bank Transfer
                                    </Typography>
                                </Grid>

                            </Button>

                        </Grid>)}
                    </Grid>
                    <br />
                    <br />

                    <Grid container justifyContent="space-between" spacing={2} sx={{paddingY:3, paddingX:3}}>
                        <Grid item xs={6} spacing={2}>
                            <FormControl fullWidth>
                                <TextField
                                    value={email}
                                    label={"E-mail"}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setEmail(event.target.value) }}
                                />
                            </FormControl>
                            <br/>
                            <br/>

                            <FormControl fullWidth>
                                <TextField
                                    value={company}
                                    label={"Company"}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setCompany(event.target.value) }}

                                />
                            </FormControl>
                            <br/>
                            <br/>
                            <FormControl fullWidth>
                                <TextField
                                    value={contact}
                                    label={"Contact"}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setContact(event.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} spacing={2}>
                            <FormControl fullWidth>
                                <TextField
                                    value={amount}
                                    label={"Amount of Credit"}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setAmount(parseInt(event.target.value)) }}
                                />
                            </FormControl>
                            <br/>
                            <br/>

                            <FormControl fullWidth>
                                <TextField
                                    value={address}
                                    label={"Address"}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setAddress(event.target.value) }}
                                />
                            </FormControl>
                            <br/>
                            <br/>
                            <Grid container justifyContent="flex-end">
                                <SearchButtonContainer item>
                                    <BaseButton
                                        label={'Submit'}
                                        onClick={() => runOrder()}
                                    />
                                </SearchButtonContainer>
                            </Grid>
                        </Grid>
                    </Grid>

                </TableFilterContainer>


                <br />



            </> : null}

        </>
    );
}
