// ----------------------------------------------------------------------

export default function InputLabel(theme) {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette['--color-search'],
                },
                focus: {
                    'background-color': theme.palette['--color-search'],
                    color: theme.palette['--color-search'],
                  }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&.MuiSelect-select': {
                        color: theme.palette['--color-search']
                    },
                },
            },
        },

    };
}
