import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    FormControl,
    InputLabel,
    Stack,
    MenuItem,
    Typography, FormGroup, Checkbox, FormControlLabel
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import {
    DefaultPaginationData,
    NumberStatus,
    SetType,
    getSelectOptions,
    getLabelByValue,
    Capability
} from 'src/constants/index';
import { SearchNumberForm, DeallocateNumberForm } from '../Forms';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import * as NumberService from "../../../../api/services/Numbers";
import * as CommonService from "../../../../api/services/Common";
import BaseCard from "../../../../components/BaseCard";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));


const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));

export default function VoiceRouting() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "number", label: t('number') },
        { key: "capability", label: "Capability" },
        { key: "number_type", label: "Type" },
        { key: "trunk_id", label: "Route" },
        { key: "action", label: t('actions') },
    ];

    const TABLE_FIELD_MAPPING = {
        //id: { key: "id", cellComponentType: "th", index: 0 },
        number: { key: "number", index: 0 },
        //country: { key: "country", index: 0 },
        capability: { key: "capability", index: 1 },
        number_type: { key: "number_type", index: 2 },
        trunk_id: { key: "trunk_id", index: 3 },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [data, setData] = useState([]);
    const [updatedData, setUpdatedData] = useState([]);
    const [interconnections, setInterconnections] = useState([]);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState("");
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterDirectionType, setDirectionType] = useState("");
    const [filterSetType, setSetType] = useState("");
    const [filterNumber, setNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [isDefault, setIsDefault] = useState(false);

    const generateValueLabelArray = (data) => {
        if (!data || !data.items) {
            return [];
        }

        let valueLabelArray = data.items.map((item) => ({
            value: item.id.toString(),
            label: item.name
        }));


        return valueLabelArray;
    };

    const fetchNumbers = () => {
        const params = {
            country: filterCountry ? filterCountry : undefined,
            capability: filterCapability ? filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        NumberService.listRouting(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }

                    });
                    item.push({
                        "dataIndex" : parseInt(idx),
                        "id" : response.data.data.items[idx]["id"],
                        "updateItem" : false,
                        "noRender": true
                    });
                    items.push(item);
                }
                setData(items);

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const submitUpdateRouting = () => {

        setLoadingData(true);

        NumberService.updateRoutingVoice(generateUpdatedItems())
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage(t('Routing has been updated'));
                    setSnackbarStatus(true);
                    setLoadingData(false);
                    fetchNumbers();
                } else {
                    throw (t("Routing couldn't be updated"));
                }
            })
            .catch((err) => {
                setMessage(t('Routing couldn\'t be updated'));
                setSnackbarStatus(true);
            });
    }




    const fetchInterconnection = () => {
        setLoadingData(true);
        NumberService.listInterconnection()
            .then((response) => {
                setInterconnections(response.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };



    useEffect(() => {
        fetchCountries();
        fetchNumbers();
        fetchDIDTypes();
        fetchInterconnection();
        return () => {
            setData([]);
        }
    }, [paginationData]);



    const formatRowData = (rowData) => {

        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()), chipColor: d.value === 3 ? "success" : "error",
                });
            } else if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            }  else if (d.key === "trunk_id") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(generateValueLabelArray(interconnections), d.value.toString()),
                    dropdownValues: generateValueLabelArray(interconnections),
                    selected: d.value,
                    dataIndex: rowData[4].dataIndex,
                    updateCallback: updateTrunkId
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    }


    const updateDataItem = (index) => {
        setData((prevData) => {
            const newData = [...prevData];
            newData[index][4]["updateItem"] = !data[index][4]["updateItem"];
            return newData;
        });
    };

    const updateTrunkId = (index, newValue) => {
        const updatedData = data.map((item, itemIndex) => {
            if (itemIndex === index) {
                item[4]["updateItem"] = true;
                return item.map((field) => {
                    if (field.key === 'trunk_id') {
                        return { ...field, value: newValue,  };
                    }
                    return field;
                });
            }

            return item;
        });

        setData(updatedData);
    };

    const generateUpdatedItems = () => {
        return data
            .filter((item) => item.find((field) => field.updateItem))
            .map((item) => {
                const updateItem = item.find((field) => field.updateItem);
                return {
                    number_id: updateItem.id,
                    trunk_id: parseInt(item.find((field) => field.key === "trunk_id").value),
                };
            });
    };



    const getActionItems = (index) => {
            return (
                <Stack direction="row" spacing={2}>
                    <Stack direction="row" justifyContent="start" alignItems="center" sx={{ mt: 1 }}>
                        <FormGroup>
                            <StyledCheckboxLabel label={""} control={
                                <Checkbox
                                    color="secondary"
                                    checked={data[index][4]["updateItem"]}
                                    onChange={() => updateDataItem(index)} />
                            } />
                        </FormGroup>
                    </Stack>
                </Stack>
            )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseDialog title={t('search-numbers')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<SearchNumberForm successCallback={fetchNumbers} formType="add" formData={{}} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={t('deallocate-number-0')} open={openDeallocateModal} setOpen={setDeallocateModalStatus} children={<DeallocateNumberForm successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setDeallocateModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <Select
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {countries.map((country, index) => {
                                    return <MenuItem key={country.code} value={country.iso_code_2digit}>{country.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Features'}</InputLabel>
                            <Select
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{'Type'}</InputLabel>
                            <Select
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />

                </SearchButtonContainer>

            </TableFilterContainer>
            </BaseCard>
            <br />

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
            <Grid container justifyContent="flex-end">
                <SearchButtonContainer item>
                    <BaseButton
                        label={'Submit'}
                        onClick={() => { submitUpdateRouting()}}
                    />
                </SearchButtonContainer>
            </Grid>

        </>
    );
}
