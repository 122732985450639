import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
// components
import Page from '../components/Page';
import { TabGroup, TabPanel } from 'src/components/navTabs';
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const TitleTypographyStyle = styled(Typography)(({ theme }) => ({
    marginBottom: 1.5,
    color: theme.palette['--main-text-color'],
}));

// ----------------------------------------------------------------------

const tabObjectPropType = PropTypes.exact({
    id: PropTypes.number,  // starting from 0
    label: PropTypes.string,
    component: PropTypes.node
});

PortalLayout.propTypes = {
    tabs: PropTypes.arrayOf(tabObjectPropType).isRequired,
    title: PropTypes.string.isRequired,
};


export default function PortalLayout({ tabs, title }) {
    const [tabValue, setTabValue] = useState(0);
    const location = useLocation();
    const [store, dispatch] = useStore();

    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    }

    return (
        <RootStyle title={`${title}`}>
            <Box sx={{ ml: 2, width: "100%" }}>
                {tabs.length > 1 ? (
                <TitleTypographyStyle sx={{ mt: -3, mb: 1.5, ml: 4 }} variant="h4">{`${title} / ${tabs[tabValue].label}`}</TitleTypographyStyle>
                ): (
                <TitleTypographyStyle sx={{ mt: -3, mb: 1.5, ml: 4 }} variant="h4">{`${title}`}</TitleTypographyStyle>
                )}
                {tabs.length > 1 && (<>
                    <TabGroup items={tabs} handleTabChange={handleTabChange} tabValue={tabValue} />
                </>)}
                <TabPanel value={tabValue} >
                    {typeof tabs[tabValue].component === "string" ? <TitleTypographyStyle>{tabs[tabValue].component}</TitleTypographyStyle> : tabs[tabValue].component}
                </TabPanel>
            </Box>
        </RootStyle>
    );
}
