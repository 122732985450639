import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import AddCredit from "./AddCredit";
// ----------------------------------------------------------------------


export default function AddCreditSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Payment", component: <AddCredit /> },
    ];

    const TITLE =  "Add Credit";
    
    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
