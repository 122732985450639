import React from 'react';
import {
    List,
    Box, Grid, FormControl, TextField
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import {AccountService} from 'src/api/services';
import { useState } from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseButton from "../../../components/buttons/BaseButton";
import {styled} from "@mui/system";
import {useStore} from "../../../store/Store";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function ChangePassword() {

    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");

    const [currentPassword, setcurrentPassword] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const [repeatedPassword, setrepeatedPassword] = useState("");

    const updatePassword = () => {
        if(newPassword == repeatedPassword){
            const payload = {
                password: newPassword,
            }
            AccountService.updateProfile(payload)
                .then((response) => {
                    if(response.data.status == true){
                        setMessage("Password changed");
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setMessage(err);
                    setSnackbarStatus(true);
                })
        }
    }

    return(
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : "white",
                border:'2em solid white',
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,
            }}>
                <>
                    <List component="nav" aria-label="mailbox folders" >
                        <h1>Change Password</h1>
                        <br/>

                    </List>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={currentPassword}
                                type={'password'}
                                label={"Current Password"}
                                sx={{marginBottom:3 }}
                                onChange={event => { setcurrentPassword(event.target.value) }}

                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={newPassword}
                                type={'password'}
                                label={"New Password"}
                        sx={{marginBottom:3 }}
                                onChange={event => { setnewPassword(event.target.value) }}

                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={repeatedPassword}
                                type={'password'}
                                label={"Repeat Password"}
                                sx={{marginBottom:3 }}
                                onChange={event => { setrepeatedPassword(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Save'}
                                onClick={ () => updatePassword()} color={"--color-search"}
                            />
                        </SearchButtonContainer>
                    </Grid>
                </>
            </Box>
        </>
    )
}
