import { useState } from 'react';
// material
import {
    FormControl,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
// app
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import  {useStore} from "../../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
import {editRequest} from "../../../../api/services/Numbers";
// ----------------------------------------------------------------------

export default function ScheduleForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));

    const [store, dispatch] = useStore();

    const deallocationApproval = store.portalSettings.services.access_management.deallocation_approval ?? false;



    const sendCancelRequest = () => {
        setLoading(true);
        let payload = {}
        payload = {
            id: formData.id,
            scheduled_at:filterStart.format('YYYY-MM-DDTHH:mm:ss')
        };
        NumberService.editRequest(payload)
            .then((response) => {
                if (response.data.meta.msg === "Ok") {
                    setMessage('Request has been successfully updated', {number: formData.number_value});
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw {message:"Request could not be updated", response:response};
                }
            })
            .catch((err) => {
                if(err.response === undefined){
                    setMessage("Connection failed");
                }else{
                    setMessage(err.response.data.meta.msg);
                }
                setSnackbarStatus(true);
                setModalStatus(false);
            });
    }


    return (
        <>
            <Stack spacing={1}>
                <Typography component="h6">
                    {'You are about to change scheduled date of translation request.'}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>

               <Grid container justifyContent="left">

                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                            format='DD/MM/YYYY'
                            onChange={(date)=> setFilterStart(date)} value={filterStart} label="Preferred Scheduled At" />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>


                <Stack sx={{ display: "block" }} direction="row" spacing={2}>


                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Save')}
                        onClick={sendCancelRequest}color={"--color-primary"}
                    />

                </Stack>
            </Stack>
        </>
    );
}
