import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

// material
import {
    TextField,
    Box,
    Grid,
    Container,
    Card,
    CardContent,
    Button, InputAdornment, IconButton
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// app
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { UserService } from "src/api/services";
import Logo from "../../Logo";
import { useStore } from "src/store/Store";
import BaseCard from "../../BaseCard";
import {useTheme} from "@mui/styles";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// ----------------------------------------------------------------------

export default function AcceptInvitation() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const { t } = useTranslation();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [store, dispatch] = useStore();
    const [logo, setLogo] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [email, setEmail] = useState(queryParams.get('email'));
    const [activationKey, setActivationKey] = useState(queryParams.get('activation_key'));
    const [password, setPassword] = useState('');

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    useEffect(() => {
        if (store.logo) {
            setLogo(store.logo);
        }
    }, [store.manualUpdateTriggeredForPalette, store.logo]);

    const isMobile = width <= 768;

    const RegisterSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("form.__i18n_ally_root__.validation.email"))
            .required(t("email-is-required")),
    });
    const getFormData = (values) => {
        return {
            email: values.email,
            activation_key: values.activation_key,
            password: values.password,
        };
    };
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const formik = useFormik({
        initialValues: {
            email: email,
            activation_key: activationKey,
            password: password,
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            const payload = getFormData(values);
            UserService.acceptInvitation(payload)
                .then((response) => {
                    if (response.data.meta.code == 200) {
                        throw "Password created. Please login.";
                    } else {
                        if (response.data.meta.msg) {
                            throw response.data.meta.msg;
                        }else {
                            throw "Password could not be created.";                  
                        }
                    }
                })
                .catch((err) => {
                    setMessage(err);
                    setOpenSnackbar(true);
                    formik.setSubmitting(false);
                    if (err == "Password created. Please login.") {
                        navigate("/login?accept_invitation=true");
                    }
                });
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const theme = useTheme();

    return (
        <>
            <BaseSnackbar
                open={openSnackbar}
                message={message}
                setOpen={setOpenSnackbar}
            />
            <Container maxWidth="xs"
                       sx={ isMobile ? {
                           height: "20vh",
                           position: "relative",
                           top: '10vh',
                           left: 0,
                       } : {   position:"absolute", left: "5%", bottom: "12vh" }}
            >
                <Grid
                    component={Card}
                >

                    <BaseCard>
                    <CardContent sx={{
                            marginX:1
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingY: 3,
                            }}
                        >
                            <Logo sx={{ width:200}} src={logo} /></Box>

                        <Grid item mb={1}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <TextField
                                        fullWidth
                                        autoComplete="email"
                                        type="email"
                                        label={t("E-mail Address")}
                                        {...getFieldProps("email")}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                        sx={{ marginBottom: 2 }}
                                        disabled={true}
                                    />


                                    <TextField
                                        fullWidth
                                        autoComplete="Activation Key"
                                        type="activation_key"
                                        label={t("Activation Key")}
                                        {...getFieldProps("activation_key")}
                                        error={Boolean(touched.activation_key && errors.activation_key)}
                                        helperText={touched.activation_key && errors.activation_key}
                                        sx={{ marginBottom: 2 }}
                                        disabled={true}
                                    />
                                    <TextField
                                        fullWidth
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        label={t('password')}
                                        {...getFieldProps('password')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleShowPassword} edge="end">
                                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}  sx={{marginBottom:3 }}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                    />
                                    <LoadingButton
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        underline="true"
                                        loading={isSubmitting}
                                        sx={{'&:hover': {
                                                backgroundColor: theme.palette['--color-primary']
                                            },backgroundColor:theme.palette['--color-primary'], marginBottom:2}}

                                    >
                                        {"Create Password"}
                                    </LoadingButton>
                                </Form>
                            </FormikProvider>

                        </Grid>
                    </CardContent>
                    </BaseCard>
                </Grid>
            </Container>
        </>
    );
}
