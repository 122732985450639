import api from "src/api/Api";
import urls from "src/api/Urls";

export const listAvailableChannels = (params) => {
    return api.get(urls.list_available_channels, { params: params });
}

export const yourChannels = (params) => {
    return api.get(urls.list_your_product_channels, { params: params });
}

export const buyChannel = (payload) => {
    return api.post(urls.buy_product_channel, payload);
}

export const removeChannel = (payload) => {
    return api.post(urls.remove_your_product_channel, payload);
}
