import React from 'react';
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {useTheme} from '@mui/styles';

export default function BaseDateTimePicker({ onChange, value, label }) {

  const theme = useTheme();
  return (
      <DateTimePicker
      format="DD/MM/YYYY HH:mm"
      sx={{
        "& input": {
          color: theme.palette['--color-search'] + " !important", 
        },
      }}
      onChange={onChange} value={value} label={label} />
  );
}
