import PropTypes from 'prop-types';
// material
import { useStore } from 'src/store/Store';
// ----------------------------------------------------------------------
import { Button, useTheme } from '@mui/material';
import { ThemeProvider, styled } from '@mui/system';

BaseButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    StartIcon: PropTypes.node,
    EndIcon: PropTypes.node,
    sx: PropTypes.object,
    color: PropTypes.string,
    props: PropTypes.object,
};

export default function BaseButton({ label, onClick, StartIcon, EndIcon, sx, color, props = {}, children }) {
    const [store] = useStore();
    const theme = useTheme();

    const textColor = theme.palette["--text-color-on-search"] ;
    const backgroundColor = color != undefined ? theme.palette[color] : theme.palette["--color-search"];

    return (
        
        <Button
            variant="contained"
            elev
            onClick={onClick ? onClick : null}
            sx={{backgroundColor, color: textColor, boxShadow: 0,   '&:hover': {
                backgroundColor,
            }, ...sx }}
            startIcon={StartIcon ? <StartIcon /> : null}
            endIcon={EndIcon ? <EndIcon /> : null}
            {...props}
        >
        {children}{label}
        </Button>
    );
}
