import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { useStore } from '../../store/Store';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import {useTheme} from '@mui/styles';
// ----------------------------------------------------------------------


export default function AccountPopover() {
  const { t } = useTranslation();
  const MENU_OPTIONS = [
    {
      label: t('profile-0'),
      icon: <AccountCircleIcon/>,
      linkTo: 'profile'
    },
    {
      label: "Change Password",
      icon: <VpnKeyIcon/>,
      linkTo: 'change-password'
    }
  ];
  const [store, dispatch] = useStore();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    const admin = store.admin;
    (async () => {
      await dispatch({ type: "LOGOUT" });
    })();
    navigate(admin ? '/admin/login' : '/login', { replace: true });
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <SettingsIcon style={{ color: theme.palette['--main-text-color'] }} fontSize="large"/>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {store.detail && store.detail.name + " " + store.detail.surname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {store.detail && store.detail.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.icon}
            <Box sx={{mr:1}}/>
            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth onClick={handleLogout} color="inherit" variant="outlined">
            {t('Sign out')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
