import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import BuyChannels from "./buychannels";
import YourChannels from "./yourchannels";
// ----------------------------------------------------------------------


export default function Channels() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Buy Channels", component: <BuyChannels /> },
        { id: 1 , label: "Your Channels", component: <YourChannels /> },
    ];

    const TITLE =  "Channels";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
