import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';
import { useTheme } from '@mui/material/styles';


export default function BaseSearchableSelect({ label, options, state, setState }) {
  const theme = useTheme();

  const color = theme.palette['--color-search'];

  return (
    <Autocomplete
      fullWidth
      options={options}
      value={state}
      onChange={(event, newValue) => {
        if (newValue) {
          setState(newValue.value);
        }
      }}
      renderInput={(params) => <TextField InputLabelProps={{
        style: { color: color },
      }} {...params} label={label} />}
    />
  );
}

