import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import OrganisationIdentities from "./organisationidentities";
import PersonalIdentities from "./personalidentities";


export default function Identity() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('Personal Identities'), component: <PersonalIdentities /> }
    ];

    const TITLE = t('Identities');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
