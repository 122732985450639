import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { NumberService } from 'src/api/services';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function OrderNumberForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const buyNumber = () => {
        setLoading(true);
        const payload = {
            number_value: formData.did,
            direction_type: 1,
            did_id: 0,
            set_type: 2,
            country: formData.country,
            extra_params: formData.extra_params,
        };
        NumberService.addNumber(payload)
            .then((response) => {
                if (response.status === 201) {
                    setMessage(t('has-been-successfully-bought', {number: formData.did}));
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    setLoading(false);
                    successCallback();
                } else {
                    throw "number could not be bought";
                }
            })
            .catch((err) => {
                setMessage(t('could-not-be-bought', {number: formData.did}));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Stack spacing={3}>
                <Typography component="h6">
                {t('are-you-sure-buy-this-number')}
                </Typography>
                <Typography component="subtitle1" variant="h6">
                    {formData.did}
                </Typography>
                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        onClick={() => setModalStatus(false)}
                    >
                        {t('cancel')}
                    </LoadingButton>
                    <LoadingButton
                        type="submit"
                        color="secondary"
                        variant="contained"
                        loading={loading}
                        onClick={buyNumber}
                    >
                        {t('buy')}
                    </LoadingButton>
                </Stack>
            </Stack>
        </>
    );
}
