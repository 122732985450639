import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, FormControl} from '@mui/material';
import {TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import { TextField } from '@mui/material';
import dayjs from "dayjs";
import {NotificationService} from 'src/api/services';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function NotificationInfoForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [message, setMessageContent] = useState("");
    const [date, setDate] = useState("");
    const [subject, setSubject] = useState("");

    const markAsRead = () => {
        NotificationService.getNotification(formData["id"])
            .then((response) => {
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }

    useEffect(() => {
        markAsRead();
        setDate(dayjs(formData.created_at).format('MM/DD/YYYY HH:mm'));
        setSubject(formData.subject);
        setMessageContent(formData.clean_message);
    }, []);

    const close = () => {
        successCallback();
        setModalStatus(false);
    }

    return (
        <>
            <TableFilterContainer>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={subject}
                            label={"Subject"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={message}
                            label={"Message"}
                            maxRows={4}
                            multiline={true}
                            name="priority"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                            }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={date}
                            label={"Date"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                            }}
                        />
                    </FormControl>
                </Grid>

            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Close'}
                        onClick={() => close()} color={"--color-search"}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

