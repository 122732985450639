import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import React from "react";
import ViewNotifications from "./ShoppingCart";
import ShoppingCart from './ShoppingCart';
// ----------------------------------------------------------------------


export default function CartFrame() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Shopping Cart", component: <ShoppingCart /> },
    ];

    const TITLE = "Shopping Cart";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
