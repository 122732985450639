import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import VoiceReports from "./voicecdr";
import SmsReports from "./smscdr";
import { useStore } from 'src/store/Store';
import ForwardedVoiceReports from './forwardedvoicecdr';
import OutboundVoiceReports from './outboundvoicecdr';
import OutboundSmsReports from './outboundsmscdr';
// ----------------------------------------------------------------------


export default function CDRsSidebar() {
    const { t } = useTranslation();
    const [store] = useStore();
    const displaySMSCDR = store.portalSettings.services.portal_features.display_sms_cdr ?? false;


    let TABS = [
        { id: 0, label: "Incoming Voice CDR", component: <VoiceReports /> },
        { id: 1, label: "Forwarded Voice CDR", component: <ForwardedVoiceReports /> },
        { id: 2, label: "Outbound Voice CDR", component: <OutboundVoiceReports /> },
    ];

    if (displaySMSCDR) {
        TABS.push({ id: 3, label: "SMS CDR", component: <SmsReports /> });
        TABS.push({ id: 4, label: "Outbound SMS CDR", component: <OutboundSmsReports /> });
    }

    const TITLE =  "CDRs";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
