import { createContext, useContext, useState } from "react";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");

    return (
        <SnackbarContext.Provider value={{ openSnackbar, setOpenSnackbar, message, setMessage }}>
            {children}
        </SnackbarContext.Provider>
    );
};

export const useSnackbar = () => {
    return useContext(SnackbarContext); // Use SnackbarContext here instead of SnackbarProvider
};
