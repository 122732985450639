import PortalLayout from 'src/layouts/PortalLayout';

import { useTranslation } from 'react-i18next';
import VoiceRouting from "./voice";
import SMSRouting from "./sms";
import { useStore } from 'src/store/Store';





export default function RoutingRoutingSidebar() {
    const { t } = useTranslation();
    const [store] = useStore();
    const displaySMSRouting = store.portalSettings.services.portal_features.display_sms_routing ?? false;

    let TABS = [
        { id: 0, label: t('Voice Routing'), component: <VoiceRouting /> },
    ];

    if (displaySMSRouting) {
        TABS.push({ id: 1, label: t('SMS Routing'), component: <SMSRouting /> });
    }

    const TITLE = t('Routing');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
