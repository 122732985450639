import api from "src/api/Api";
import urls from "src/api/Urls";


export const addCreditPaypal = (id, payload) => {
    return api.post(urls.add_credit_paypal(id), payload);
}

export const finalizeCreditPaypal = (params) => {
    return api.get(urls.finalize_credit_paypal, { params: params});
}

export const getTransactions = (params) => {
    return api.get(urls.get_transactions, { params: params});
}