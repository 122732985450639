import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Orders from "./orders/orders";
// ----------------------------------------------------------------------


export default function OrdersSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Orders", component: <Orders /> },

    ];
    
    const TITLE = "Orders";
    
    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
