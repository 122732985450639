import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import BuyNumbers from "./buynumbers/BuyNumbers";
// ----------------------------------------------------------------------


export default function BuyNumbersSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Buy Numbers", component: <BuyNumbers /> },
    ];
    
    const TITLE = "Buy Numbers";
    
    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
