import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import PreOrders from "./orders/preOrders";
// ----------------------------------------------------------------------


export default function PreOrdersSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Pre-Orders", component: <PreOrders /> },

    ];
    
    const TITLE = "Pre-Orders";
    
    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
