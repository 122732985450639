import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useTheme } from '@mui/styles';
import {
    Grid,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Typography,
    Stack,
    IconButton,
    Tooltip
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseSnackbar from 'src/components/BaseSnackbar';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    DefaultPaginationData,
    NumberStatus,
    SetType,
    getSelectOptions,
    getLabelByValue,
    Capability,DidType
} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import { ProductsService, CommonService, NumberService } from 'src/api/services';
import BaseDialog from "../../../components/BaseDialog";
import TranslationInfoForm from "../endpoints/Forms/TranslationInfoForm";
import {rowArrayToObject} from "../../../utils/Util";
import BaseCard from 'src/components/BaseCard';
import BaseSelect from 'src/components/BaseSelect';
import { Add, AddCircle, Edit, ShoppingCart } from '@mui/icons-material';
import NumberRequestForm from '../number/Forms/NumberRequestForm';
import PreOrderNoteDialog from './PreOrderNoteDialog';
import BaseModal from 'src/components/BaseModal';



const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function PreOrderProductList() {
    const { t } = useTranslation();
    const theme = useTheme();

    const TABLE_HEAD = [
        { key: "product", label: t("name") },
        { key: "country", label: t("country") },
        { key: "did_type", label: t("DID Type") },
        { key: "capability", label: t("capability") },
        { key: "provider_codes", label: t("Providers") },
        { key: "doc_required", label: t("Product Compliance Ind./Comp.") , tooltip: t("Product Compliance Individual/Company")},
        { key: "actions", label: t("actions") }
    ];
      

    const TABLE_FIELD_MAPPING = {
        product: { key: "product", index: 0 },
        country: { key: "country", index: 1 },
        did_type: { key: "did_type", index: 2 },
        capability: { key: "capability", index: 3 },
        provider_codes: { key: "provider_codes", index: 4 },
        doc_required: { key: "doc_required", index: 5 },
        comp_doc_required: { key: "comp_doc_required", index: 6, noRender:true },
        porting_comp_doc_required: { key: "porting_comp_doc_required", index: 8, noRender:true },
        cond_explanation: { key: "cond_explanation", index: 9, noRender:true },
        comp_cond_explanation: { key: "comp_cond_explanation", index: 10, noRender:true },
        comp_conditions: { key: "comp_conditions", index: 11, noRender:true },
        conditions: { key: "conditions", index: 12, noRender:true },
        legal_restrictions: { key: "legal_restrictions", index: 13, noRender:true },
        service_restrictions: { key: "service_restrictions", index:14, noRender:true },
        comp_legal_restrictions: { key: "comp_legal_restrictions", index: 15, noRender:true },
        comp_service_restrictions: { key: "comp_service_restrictions", index: 16, noRender:true },
        comp_descriptions: { key: "comp_descriptions", index: 17, noRender:true },
        comp_fields: { key: "comp_fields", index: 18, noRender:true },
        pers_descriptions: { key: "pers_descriptions", index: 19, noRender:true },
        pers_fields: { key: "pers_fields", index: 20, noRender:true },
        porting_pers_fields: { key: "porting_pers_fields", index: 21, noRender:true },
        porting_pers_descriptions: { key: "porting_pers_descriptions", index: 22, noRender:true },
        porting_comp_fields: { key: "porting_comp_fields", index: 23, noRender:true },
        porting_comp_descriptions: { key: "porting_comp_descriptions", index: 24, noRender:true },
        capability_id: { key: "capability_id", index: 25, noRender:true },
        did_type_id: { key: "did_type_id", index: 26, noRender:true },
        id: { key: "id", index: 27, noRender:true },
        country_code: { key: "country_code", index: 28, noRender:true },
        city: { key: "city", index: 29, noRender:true },
        state: { key: "state", index: 30, noRender:true },
        did_type_is_fixed: { key: "did_type_is_fixed", index: 31, noRender:true }

    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [filterProvider, setFilterProvider] = useState("");
    const [providerCodes, setProviderCodes] = useState([]);
    const [openDetail, setOpenDetail] = useState(false);
    const [openPortingDetail, setOpenPortingDetail] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                if(response.status == 200){
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ code: item[1]["code"], name: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                    })
                    setCountries(items);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchProducts = () => {
        const params = {
            country: filterCountry ? filterCountry : "",
            did_type: filterNumberTypes == 0 ? "" : filterNumberTypes,
            capability: filterCapability ? filterCapability : "",
            provider_code: filterProvider ? filterProvider : "",
            page: paginationData.page + 1,
            size:10
        };

        if(params.capability === "0"){
            params.capability = "";
        }

        setLoadingData(true);
        ProductsService.getProducts(params)
            .then((response) => {
                let items = [];
                if(response.status == 200){
                    setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
                }
            })
            .catch((err) => {
                setMessage('Error while getting data');
                setSnackbarStatus(true);

            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    const fetchProviderCodes = () => {
        NumberService.getProviderCodes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchProducts();
        fetchCountries();
        fetchDIDTypes();
        fetchProviderCodes();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    function replaceSquareBrackets(arr) {
        return arr.map(item => {
            if (typeof item.value === 'string') {
                item.value = item.value.replace(/\[\\\"/g, '[').replace(/\\\"\]/g, ']');
            }
            return item;
        });
    }



    const modalHandler = (modalType, index = undefined) => {
        if (index) {
            let temp = data[index];
            temp = rowArrayToObject(temp);
            setSelectedRow(temp);
        };

        if (modalType === "infoDialog") {
            setOpenNotesModal(!openNotesModal);
        }


   
    }


    const getActionItems = (index) => {
                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Make Pre-Order">
                        <IconButton  sx = {{display: "flex", justifyContent: "flex-start"}}>
                            <ShoppingCart
                                sx = {{
                                    width:30,
                                    height:30,
                                    borderRadius:0.5,
                                    color: "white",
                                    backgroundColor: theme.palette['--color-primary']}}
                                onClick={() => modalHandler("infoDialog", index)}/>
                        </IconButton>
                        </Tooltip>
                    </Stack>
                )
    }

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()), chipColor: d.value === 3 ? "success" : "error",
                });
            }  else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "doc_required") {
                let doc_required = rowData.find(item => item.key === "doc_required");
                let comp_doc_required = rowData.find(item => item.key === "comp_doc_required");
                formatted.push({
                    ...d,
                    value: `${doc_required.value == true ? "Yes" : "No"} / ${comp_doc_required.value == true ? "Yes" : "No"}`,
                });
            } else if (d.key === "provider_codes") {
                formatted.push({
                  ...d,
                  value: d.value.map(item => String(item)).join(', ')
                });
              } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const [openPreorderFileUpload, setOpenPreorderFileUpload] = useState(false);

    const [openNotesModal, setOpenNotesModal] = useState(false);

    const [extraData, setExtraData] = useState({});

    const openPreOrderDialog = (data) => {


    setExtraData({
        "provider_code": data.provider ? data.provider : "",
        "country": selectedRow.country_code,
        "state": data.state ? data.state : undefined,
        "city": data.city ? data.city : undefined,
        "number_type": selectedRow.did_type_id.toString(),
        "capability": selectedRow.capability_id.toString(),
        "quantity": parseInt(data.count),
        "note": data.notes
    });

     setOpenPreorderFileUpload(true);

    }

    const closeDialogs = () => {
        setOpenPreorderFileUpload(false);
        setOpenNotesModal(false);
    }


    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                
            
            <BaseModal title={'Pre-Order Request'} open={openNotesModal} setOpen={setOpenNotesModal} children={<PreOrderNoteDialog successCallback={(data) => openPreOrderDialog(data)} formData={selectedRow} setModalStatus={setOpenNotesModal} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />


            <BaseDialog title={'Pre-Order Documents and Verification'} dialogProperties={DIALOG_PROPERTIES} closeCallback={() => setOpenPreorderFileUpload(false)} open={openPreorderFileUpload} setOpen={setOpenPreorderFileUpload} children={<NumberRequestForm successCallback={() => closeDialogs()} extraData={extraData} formData={selectedRow} setModalStatus={setOpenPreorderFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />


            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {countries.map((country, index) => {
                                    return <MenuItem key={country.code} value={country.iso_code_2digit}>{country.name}</MenuItem>;
                                })}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{t('type')}</InputLabel>
                            <BaseSelect
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('capability')}</InputLabel>
                            <BaseSelect
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>                    
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProvider}
                                onChange={event => { setFilterProvider(event.target.value) }}
                            >
                               
                                {getSelectOptions(providerCodes)}

                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchProducts() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />

                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                actionItemPrep={getActionItems}
                loadingData={loadingData}
            />
        </>
    );
}
