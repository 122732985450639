import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import {useStore} from "../../../store/Store";
import PreOrderProductList from './PreOrderProductList';

// ----------------------------------------------------------------------


export default function PreOrderRequestSidebar() {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();

    const TABS = [
        { id: 0, label: "Products", component: <PreOrderProductList/> }
    ];

    const TITLE = 'Available Products For Pre-Order';

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
