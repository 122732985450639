import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ src, sx }) {
  // increase the height/width when smartcpaas logo is available
  // sx={{ width: 40, height: 40, ...sx }}
  return <Box component="img" src={src != null ? `data:;base64,${src}` : null} sx={{ ...sx,  display: 'flex', justifyContent: 'center', alignItems: 'center' }} />;
}
