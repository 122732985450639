import api from "src/api/Api";
import urls from "src/api/Urls";

export const getRequests = (params) => {
    return api.get(urls.sender_id_requests, {params:params});
}

export const getInventory = (params) => {
    return api.get(urls.sender_id_inventory, {params:params});
}

export const addRequest = (payload) => {
    return api.post(urls.sender_id_request, payload);
}

export const getCountries = (params) => {
  return api.get(urls.sender_id_country, {params:params});
}

export const cancelRequest = (payload) => {
  return api.post(urls.sender_id_cancel, payload);
}


export const getRule = (params) => {
  return api.get(urls.sender_id_rule, {params:params});
}

export const resubmit = (payload) => {
  return api.post(urls.sender_id_resubmit, payload);
}

