import React from 'react';
import { useState, useEffect } from 'react';
import {Box, IconButton, Stack, useTheme} from '@mui/material';
import { DefaultPaginationData,
    getColorByValue,
    getLabelByValue,
    MembersStatus} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer, BaseTable } from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseCard from 'src/components/BaseCard';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// ----------------------------------------------------------------------


export default function Verifications() {
    const {t} = useTranslation();
    const [openAddMemberModal, setAddMemberModalStatus] = useState(false);
    const [openEditMemberModal, setEditMemberModalStatus] = useState(false);
    const [openDeleteMemberModal, setDeleteMemberModalStatus] = useState(false);

    const [selectedMember, setSelectedMember] = useState([]);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    const TABLE_HEAD = [
        {key: "Verification_line_1", label: t("Product Name"), alignRight: false},
        {key: "postal Code", label: t("Identity"), alignRight: false},
        {key: "additional_info_notes", label: t("Address"), alignRight: false},
        {key: "validity", label: t("Validity"), alignRight: false},
        {key: "actions", label: t("Actions"), alignRight: false},
    ];

    const TABLE_FIELD_MAPPING = {
        "name": {index: 0, key: "name"},
        "Verification_line_1": {index: 1, key: "Verification_line_1"},
        "Verification_line_2": {index: 2, key: "Verification_line_2"},
        "city": {index: 3, key: "city"},
        "country": {index: 4, key: "country"},
        "postal_code": {index: 5, key: "postal_code"},
        "additional_info_notes": {index: 6, key: "additional_info_notes"},
        "id": {index: 7, key: "id", noRender: true},
        "country_id": {index: 8, key: "country_id", noRender: true},
    };




    useEffect(() => {
    }, []);

    useEffect(() => {
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(MembersStatus(), d.value.toString()), chipColor: getColorByValue(MembersStatus(), d.value.toString()),
                });
            }else {
                formatted.push(d);
            }
        })
        return formatted;
    };


    const modalHandler = (modalType, index) => {
        if (modalType === "addMember") {
            setAddMemberModalStatus(!openAddMemberModal);
        }
        if (modalType === "editVerification") {
            setEditMemberModalStatus(!openEditMemberModal);
            setSelectedMember(data[index]);
        }
        if (modalType === "deleteVerification") {
            setDeleteMemberModalStatus(!openDeleteMemberModal);
            setSelectedMember(data[index]);
        }
    };

    const theme = useTheme();

    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent='start' spacing={1}>
                        <>
                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <EditIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("editVerification", index)}/>
                            </IconButton>

                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <DeleteIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("deleteVerification", index)}/>
                            </IconButton>
                        </>
                </Stack>
            );
      };
    return (
        <>
            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                <BaseCard>
                <TableFilterContainer >
                    <Box alignItems={"center"} sx={{
                        height: 50,
                        display: 'flex'
                        }}>
                        <BaseButton
                            label= {t("+ Add New Verification")}
                            onClick={() => modalHandler("addMember")}
                        />
                    </Box>
                </TableFilterContainer>
                </BaseCard>
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
