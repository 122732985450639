import React from 'react';
import {
    List,
    Box, Grid, FormControl, TextField, Typography, InputAdornment, IconButton
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import {AccountService, ProfileService} from 'src/api/services';
import { useState } from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseButton from "../../../components/buttons/BaseButton";
import {styled} from "@mui/system";
import {useStore} from "../../../store/Store";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function ViewProfile() {

    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState(store.detail.name);
    const [surname, setSurname] = useState(store.detail.surname);
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const updateDetails = () => {
        const headers = {
            "Authorization" : "Bearer " + store.refresh_token
        }

        ProfileService.refresh_token(headers)
            .then((response) => {
                if(response.data.status == true){
                    dispatch({
                        type: "REFRESH_TOKEN",
                        payload: {
                            token: response.data.data.access_token,
                            expires_at: response.data.data.expires_at,
                            refresh_token: response.data.data.refresh_token,
                            roles: response.data.data.roles,
                            meta: response.data.meta,
                            detail: response.data.data.detail,
                            admin: false,
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const updateProfile = () => {
            const payload = {
                name: name,
                surname: surname,
            }

            AccountService.updateProfile(payload)
                .then((response) => {
                    if(response.data.status == true){
                        updateDetails();
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
    }

    return(
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : "white",
                border:'2em solid white',
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>
                <>
                    <List component="nav" aria-label="mailbox folders" >
                        <h1>Profile</h1>
                        <br/>
                         <Typography variant="subtitle1" noWrap>
                            {store.detail && store.detail.name + " " + store.detail.surname}
                        </Typography>
                    </List>

                        <Grid item md={2} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    value={name}
                                    label={'First Name'}
                                    name="name"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={event => { setName(event.target.value) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        value={surname}
                                        label={'Last Name'}
                                        name="name"
                                        margin="normal"
                                        variant="outlined"
                                        color="secondary"
                                        onChange={event => { setSurname(event.target.value) }}
                                    />
                                </FormControl>
                        </Grid>


                        <Grid item md={2} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    value={store.detail && store.detail.email}
                                    label={"E-Mail"}
                                    name="E-Mail"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={2} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    value={store.detail && store.detail.account_sid}
                                    label={"Account ID"}
                                    name="Last Name"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>

                    <Grid item md={2} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                value={store.detail && store.detail.api_key}
                                type={showPassword ? 'text' : 'password'}
                                label={"API Key"}
                                disabled={true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}  sx={{marginBottom:3 }}
                            />
                        </FormControl>
                    </Grid>
                    <br />
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Save'}
                                onClick={ () => updateProfile()} color={"--color-search"}
                            />
                        </SearchButtonContainer>
                    </Grid>
                </>
            </Box>
        </>
    )
}
