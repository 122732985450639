import { useRef, useState, useEffect } from 'react';
import i18n from 'i18next';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// app
import { useStore } from 'src/store/Store';
import { UserService } from 'src/api/services';

// ----------------------------------------------------------------------
const DEFAULT_LANG = "en-US";
// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [store, dispatch] = useStore();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLangChange = (lang) => {
    i18n.changeLanguage(lang.code);
    setSelectedLang(lang);
    handleClose();
    UserService.updateSelectedLanguage(store.user.id, { selected_language_id: lang.id });
    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        language: lang.code,
      }
    });
  };

  useEffect(() => {
    let lang;
    if (store.language)
      lang = store.supported_languages.find(i => i.code === store.language);

    if (!lang) {
      store.supported_languages.map((option) => {
        if (option.code === i18n.language) {
          lang = option;
        }
      });
    }
    if (!lang) {
      lang = store.supported_languages[0];
    }
    i18n.changeLanguage(lang?.code || DEFAULT_LANG);
    setSelectedLang(lang || DEFAULT_LANG);
    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        language: lang?.code || DEFAULT_LANG,
      }
    });
  }, [])

  return (
    <Box sx={{ display: store.supported_languages && store.supported_languages.length > 1 ? "block" : "none" }}>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 32,
          height: 32,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.background.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <img src={selectedLang.icon_url} alt={selectedLang.name} />
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {store.supported_languages.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.code === i18n.language}
              onClick={() => handleLangChange(option)}
              sx={{ py: 1, px: 2.5, padding: "5px", }}
            >
              <ListItemIcon>
                <Box component="img" sx={{ width: 32, height: 32 }} alt={option.name} src={option.icon_url} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.local_name}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </Box>
  );
}
