// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, FormControl, Stack, FormGroup, Checkbox, FormControlLabel} from '@mui/material';
import {TableFilterContainer } from 'src/components/table';
import {NumberService} from 'src/api/services';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette.secondary.dark,
}));

export default function UpdateEndpointForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();


    
    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const [name, setName] = useState("");
    const [priority, setPriority] = useState("");
    const [host, setHost] = useState("");
    const [weight, setWeight] = useState("");

    const [inbound, setInbound] = useState(false);
    const [outbound, setOutbound] = useState(false);

    const updateEndpoint = () => {
        const payload = {
            name:name,
            priority:priority, 
            weight:weight, 
            hostname:host,
            
        };

        NumberService.updateVoiceEndpoint( formData.id, payload)
            .then((response) => {
                if (response.data.status == true) {
                    setMessage("Voice endpoint updated");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                    successCallback();
                } else {
                    throw "Profile could not be updated";
                }
            })
            .catch((err) => {
                setMessage("Could not be updated");
                setSnackbarStatus(true);
                setModalStatus(false);
            })

    }

    useEffect(() => {
        setName(formData.name);
        setHost(formData.hostname);
        setWeight(formData.weight);
        setPriority(formData.priority);
    }, []);


    return (
        <>
            <TableFilterContainer>
                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={name}
                            label={t('name')}
                            name="name"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setName(event.target.value) }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={host}
                            label={"Host Address"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setHost(event.target.value) }}
                            disabled={true}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={priority}
                            label={"IB Priority"}
                            name="priority"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setPriority(event.target.value) }}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={weight}
                            label={"IB Weight"}
                            name="weight"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            onChange={event => { setWeight(event.target.value) }}
                        />
                    </FormControl>
                </Grid>



            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Update'}
                        onClick={() => { updateEndpoint()}} color={"--color-search"}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

