import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

const CountdownTimer = ({ validUntil }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(validUntil) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [validUntil]);

  return (
    <Typography variant="h6">
        {"Cart will expire in "}
      {timeLeft.days !== -1 ? (
        <>
          {timeLeft.minutes}m {timeLeft.seconds}s
        </>
      ) : (
        "Expired"
      )}
    </Typography>
  );
};

export default CountdownTimer;