import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Translations from "./changeRequests/Translations";
// ----------------------------------------------------------------------


export default function TranslationsSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Translations", component: <Translations /> },
    ];

    const TITLE =  "Translation Change Requests";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
