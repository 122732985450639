// ----------------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl, Box, CircularProgress, InputLabel, Select, MenuItem
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";
import {makeStyles} from '@mui/styles';
import * as NumberService from "../../../../api/services/Numbers";
import * as CommonService from "../../../../api/services/Common";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import BaseTextField from 'src/components/BaseTextField';
import BaseSelect from 'src/components/BaseSelect';
import { useStore } from 'src/store/Store';



const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function ScheduleDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const classes = useStyles();

    const [landline, setLandline] = useState("");
    const [mobile, setMobile] = useState("");
    const [payphone, setPayphone] = useState("");
    const [countries, setCountries] = useState([]);
    const [filterCountry, setFilterCountry] = useState(null);

    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day').add(1, 'day'));

    const [isLoaded, setIsLoaded] = useState(false);

    const [endpoints, setEndpoints] = useState([]);
    const [endpoint, setEndpoint] = useState(null);

    const [routingType, setRoutingType] = useState("");

    const [customerNotes, setCustomerNotes] = useState('');

    const [store, dispatch] = useStore();
    
    const advancedTranslationEnabled = store.portalSettings.services.access_management.advanced_translation ?? false;


    const setRoutingDropdown = (value) => {
        setRoutingType(value);
        if(value == 3){
            setEndpoint(null);
        }else if(value == 2){
            setFilterCountry(null);
        }
    }

    const getEndpoints = () => {
        NumberService.getEndpoints()
            .then((response) => {
                setEndpoints(response.data.data.items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }

    const getEnabledCountries = () => {
        CommonService.getCountries({outbound_enabled:true})
            .then((response) => {
                let items = [];
                response.data.data.map((item) => {
                    items.push({ iso_code_2digit: item.iso_code_2digit, name: item.name, id: item.id, code: item.code });
                });
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const removePharanthesis = (str) => {
        return str.replace(/ *\([^)]*\) */g, "");
    }

    useEffect(() => {
        getEndpoints();
        getEnabledCountries();
    }, []);

    useEffect(() => {
            const params = {did_id:formData.did_id}
            NumberService.numberDetail(params)
            .then((response) => {
                setLandline(response.data.data.translation_dnis.landline);
                setMobile(response.data.data.translation_dnis.mobile);
                setPayphone(response.data.data.translation_dnis.payphone);
                setData(response.data.data);
                setRoutingType(response.data.data.routing_type_id);
                setIsLoaded(true);
                if(response.data.data.routing_type_id == 3){
                    for (const idx in countries) {
                        console.log(removePharanthesis(countries[idx].name));
                    }

                    let country = countries.find(item => removePharanthesis(item.name) == response.data.data.tdm_country);

                    if(country){
                        console.log(country);
                        setFilterCountry(country.iso_code_2digit);
                    }
                }
                if(response.data.data.routing_type_id == 2){
                    setEndpoint(response.data.data.trunk_id);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }, [endpoints, countries]);


    function findNameById(id) {
        const foundObject = endpoints.find(item => item.id === id);

        if (foundObject) {
            return foundObject.name;
        } else {
            return null;
        }
    }


    const schedule = () => {
        const params = routingType == 3 ? {
                did_id:formData.did_id,
                landline:landline,
                mobile:mobile,
                payphone:payphone,
                tdm_country: filterCountry,
                route_type: 3,
                scheduled_at:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
                customer_notes: customerNotes ? customerNotes : ""
            } : {
            did_id:formData.did_id,
            landline:landline,
            mobile:mobile,
            payphone:payphone,
            trunk_id:endpoint,
            trunk_name: findNameById(endpoint),
            route_type: 2,
            scheduled_at:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
            customer_notes: customerNotes ? customerNotes : ""
        }

        if(advancedTranslationEnabled == false ){
            delete params.mobile;
            delete params.payphone;
            if(routingType == 3){
                delete params.tdm_country;
            }
        }

        if(filterCountry == null && endpoint == null){
            setMessage("IP or PSTN Country is mandatory");
            setSnackbarStatus(true);
        }else{
            NumberService.scheduleRequest(params)
                .then((response) => {
                    if(response.data.status == true){
                        setMessage("Translation change request has been successfully submitted");
                        setSnackbarStatus(true);
                        setModalStatus(false);
                    }else{
                        setMessage(response.data.meta.msg);
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                })
        }
    }



    return isLoaded == true ?  ( <>
           <TableFilterContainer>
            
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <div>Current Route</div>

                </Grid>
                <Grid item xs={6}>
                <div>Updated Route</div>

                </Grid>
                
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl fullWidth>
                           <BaseTextField
                               value={data.routing_type ? data.routing_type : " "}
                               label={"Route Type"}
                               margin="normal"
                               variant="outlined"
                               color="secondary"
                               disabled={true}
                           />
                       </FormControl>
                </Grid>
                
                <Grid item xs={6}>
                <FormControl fullWidth>
                           <InputLabel id="filter-provider-label">{"Routing Type"}</InputLabel>
                           <BaseSelect
                               label={"Routing Type"}
                               labelId="filter-provider-label"
                               name="provider"
                               color="secondary"
                               value={routingType}
                               onChange={event => { setRoutingDropdown(event.target.value) }}
                           >
                               {[
                                   {value: '2', text: 'IP'},
                                   advancedTranslationEnabled ? {value: '3', text: 'PSTN'} : null
                               ].filter(item => item != null).map((item, index) => {
                                   return <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>;
                               })}
                           </BaseSelect>
                       </FormControl>
                </Grid>
                
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                {data.routing_type_id == 3  ?  <FormControl fullWidth>
                           <BaseTextField
                               value={data.tdm_country ? data.tdm_country : " "}
                               label={"TDM Country"}
                               margin="normal"
                               variant="outlined"
                               color="secondary"
                               disabled={true}
                           />
                       </FormControl> : (<FormControl fullWidth>
                           <BaseTextField
                               value={data.trunk_name ? data.trunk_name : " "}
                               label={"Voice Endpoint"}
                               margin="normal"
                               variant="outlined"
                               color="secondary"
                               disabled={true}
                           />
                       </FormControl>)}
                </Grid>
                
                
                <Grid item xs={6}>
                {routingType == 2 ? (<FormControl fullWidth>
                           {endpoint == null && (<InputLabel id="filter-provider-label">{"Voice Endpoint"}</InputLabel>)}
                           <BaseSelect
                               name="provider"
                               color="secondary"
                               value={endpoint}
                               onChange={event => { setEndpoint(event.target.value) }}
                           >
                               {endpoints.map((item, index) => {
                                   return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                               })}
                           </BaseSelect>
                       </FormControl>) : advancedTranslationEnabled ? (<FormControl fullWidth>
                           {filterCountry == null && (<InputLabel id="filter-provider-label">{"TDM Country"}</InputLabel>)}
                           <BaseSelect
                               name="country"
                               color="secondary"
                               value={filterCountry}
                               onChange={event => { setFilterCountry(event.target.value) }}
                           >
                               {countries.map((item, index) => {
                                   console.log(filterCountry);
                                   return <MenuItem key={item.iso_code_2digit} value={item.iso_code_2digit}>{item.name}</MenuItem>;
                               })}
                           </BaseSelect>
                       </FormControl>) : null}
                </Grid>
                
            </Grid>
            
            <br />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                <div>Current Translations</div>
                </Grid>
                
                <Grid item xs={6}>
                <div>Updated Translations</div>
                </Grid>
                
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl fullWidth>
                           <BaseTextField
                               value={data.translation_dnis ? data.translation_dnis.landline : " "}
                               label={"Landline"}
                               margin="normal"
                               variant="outlined"
                               color="secondary"
                               disabled={true}
                           />
                       </FormControl>
                </Grid>
                
                <Grid item xs={6}>
                <FormControl fullWidth>
                           <BaseTextField
                               value={landline}
                               label={"Landline"}
                               margin="normal"
                               variant="outlined"
                               color="secondary"
                               onChange={setLandline}
                           />
                       </FormControl>                
                </Grid>
                
            </Grid>

            {advancedTranslationEnabled && (
                <>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <FormControl fullWidth>
                            <BaseTextField
                                value={data.translation_dnis ? data.translation_dnis.mobile : " "}
                                label={"Mobile"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>                
                    </Grid>
                    
                    <Grid item xs={6}>
                    <FormControl fullWidth>
                            <BaseTextField
                                value={mobile}
                                label={"Mobile"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setMobile}
                            />
                    </FormControl>                
                    </Grid>
                </Grid>
                </>
            )}
            
            {advancedTranslationEnabled && (
                <>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <FormControl fullWidth>
                            <BaseTextField
                                value={data.translation_dnis ? data.translation_dnis.payphone : " "}
                                label={"Payphone"}
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                                <BaseTextField
                                    value={payphone}
                                    label={"Payphone"}
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={setPayphone}
                                />
                        </FormControl>
                    </Grid>
                </Grid>
                </>
            )}
            <br/>
            <br/>
               <Grid item md={3} xs={12}>
                   <FormControl fullWidth>
                       
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disablePast
                            onChange={(date) => setFilterStart(date)}
                            value={filterStart}
                            label="Preferred Scheduled Date"
                            format="DD/MM/YYYY"
                        />
                    </LocalizationProvider>

                   </FormControl>
               </Grid>
               <br/>
               <FormControl fullWidth>
                    <BaseTextField
                        value={customerNotes}
                        label={t('Customer Reference (Optional)')}
                        name="customer_notes"
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        onChange={(value) => {
                            if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 15) {
                                setCustomerNotes(value);
                            }
                        }} 
                    />
                </FormControl>
                <br/>
           </TableFilterContainer>
           <br />
           <Grid sx={{ alignItems: "center" }} justifyContent="center" container spacing={1}>
               <BaseButton
                   label={'Close'}
                   onClick={() => setModalStatus(false)} color={"--color-search"}
               />
               <Box sx={{m:1}}/>
               <BaseButton
                   label={'Request Change'}
                   onClick={() => schedule()} color={"--color-primary"}
               />
           </Grid>
       </>) : (<CircularProgress></CircularProgress>);
}

