import React from 'react';
import { useState } from 'react';
// material
import {
    Stack,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, Typography, Grid, Box, Button, useTheme
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useTranslation } from 'react-i18next';
import {IdentityService} from 'src/api/services';
import BaseButton from 'src/components/buttons/BaseButton';
import {styled} from "@mui/system";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {getSelectOptions, IdentityType, IdentityVerificationStatus, PersonalIdentityType} from "../../../../constants";
import BaseSelect from 'src/components/BaseSelect';
import { useEffect } from 'react';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function EditPersonalIdentityForm({ setModalStatus, setSnackbarStatus, setMessage, successCallback, formData }) {

    const { t } = useTranslation();
    const [identityName, setIdentityName] = useState(formData[0].value);
    const [externalId, setExternalId] = useState(formData[1].value);
    const [email, setEmail] = useState(formData[2].value);
    const [identityType, setIdentityType] = useState(formData[3].value);
    const [verificationStatus, setVerificationStatus] = useState(formData[4].value);
    const [firstName, setFirstName] = useState(formData[7].value['first_name'] ? formData[7].value['first_name'] : null);
    const [lastName, setLastName] = useState(formData[7].value['last_name'] ? formData[7].value['last_name'] : null);
    const [companyName, setCompanyName] = useState(formData[7].value['company_name'] ? formData[7].value['company_name'] : null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(formData);
    }
    , []);

    const addIdentity =() => {
        console.log(formData);
        const payload = {
            id: formData[6].value,
            identity_name: identityName,
            compliancy_type: identityType == 'Personal' ? 2 : 1,
            contact_email: email,
            external_id: externalId,
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
        };
        IdentityService.updateIdentity(payload, formData[6].value)
        .then((response) => {
            if (response.data.meta.code === 200) {
                setMessage(t('Identity edited successfully'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw (t("could-not-be-edited"));
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-edited'));
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        });
    }

    const theme = useTheme();


    return (
        <Stack sx={{display: "flex"}} spacing={2}>
        <Typography sx={{ textAlign: 'left', color: theme.palette['--main-text-color'] }}>
            {'Identity Type'}
        </Typography>

    <TextField
        value={identityName}
        label={'Identity Name'}
        name="firstName"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setIdentityName(event.target.value) }}
    />
    
    <TextField
        value={externalId}
        label={'External/Site ID'}
        name="lastName"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setExternalId(event.target.value) }}
    />

    <TextField
        value={email}
        label={'Contact E-mail'}
        name="lastName"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setEmail(event.target.value) }}
    />
    
    {identityType == 'Personal' ? (<>
        <TextField
        value={firstName}
        label={'First Name'}
        name="telephone"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setFirstName(event.target.value) }}
    />      
    
    <TextField
        value={lastName}
        label={'Last Name'}
        name="telephone"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setLastName(event.target.value) }}
    />
    </>) : (<>
        <TextField
        value={companyName}
        label={'Company Name'}
        name="telephone"
        margin="normal"
        variant="outlined"
        color="secondary"
        onChange={event => { setCompanyName(event.target.value) }}
    />
    </>)}


    <Stack sx={{ display: "block", alignItems: "right"  }} direction="row" spacing={2}>
        <BaseButton
            type="submit"
            variant="contained"
            disabled={loading}
            onClick={() => setModalStatus(false)}
            >
            {t('cancel')}
        </BaseButton>
        <BaseButton
            type="submit"
            color="--color-primary"
            variant="contained"
            loading={loading}
            onClick={() => addIdentity()}
            >
            {t('Save')}
        </BaseButton>
    </Stack>
</Stack>
  );
}
