import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import BuyNumbers from "./buynumbers/BuyNumbers";
import BuyBlocks from './block/BuyBlocks';
// ----------------------------------------------------------------------


export default function BuyBlocksSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Buy Blocks", component: <BuyBlocks /> },
    ];
    
    const TITLE = "Buy Blocks";
    
    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
