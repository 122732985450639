import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import Deallocations from "./changeRequests/Deallocations";
// ----------------------------------------------------------------------


export default function DeallocationsSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: "Deallocation Requests", component: <Deallocations /> }
    ];

    const TITLE = "Deallocation Requests";

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
