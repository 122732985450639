import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {Grid, TextField, Box} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {CommonService} from 'src/api/services';
import { useTranslation } from 'react-i18next';
import Chart from "react-apexcharts";
import TableSkeleton from "../../components/table/TableSkeleton";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/Store";
import BaseCard from 'src/components/BaseCard';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

const SendButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


const TextFieldStyled = styled(TextField)(({ theme }) => ({
    minWidth: "400px",
    [theme.breakpoints.down(500)]: {
        minWidth: "225px",
    },
}));


export default function Dashboard() {
    const [loadingData, setLoadingData] = useState(true);
    const [totalCost, setTotalCost] = useState(0);
    const [store, dispatch] = useStore();

    const displaySMSUsage = store.portalSettings.services.portal_features.display_sms_usage_on_dashboard ?? false;
    const displayVoiceUsage = store.portalSettings.services.portal_features.display_voice_usage_on_dashboard ?? false;
    const displayWeeklyCost = store.portalSettings.services.portal_features.display_weekly_cost_on_dashboard ?? false;
    const displayInventoryPerCountry = store.portalSettings.services.portal_features.display_inventory_per_country_on_dashboard ?? false;



    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();


    const PIE_CHART_COLORS = [
        theme.palette['--color-primary'], // Item at index 0
        theme.palette['--color-primary-alpha-50'], // Item at index 1
        theme.palette['--color-primary-alpha-30'], // Item at index 2
        theme.palette['--color-primary-alpha-20'], // Item at index 3
        theme.palette.secondary.main, // Item at index 4 (assuming you want the main color of the secondary palette)
      ];

    const fontFamily = theme.typography.fontFamily;

    const BAR_CHART_COLORS = [theme.palette['--color-primary-alpha-50']];
      
    const STACKED_CHART_COLORS = [theme.palette['--color-primary'], theme.palette['--color-primary-alpha-20']];



    const [dashboardData, setDashboardData] = useState({
        weekly_sms_traffic: [],
        weekly_voice_traffic: [],
        balance: { cost: [] },
        top_countries: {
            series: [
                {
                    name: "Approved",
                    data: [0,0,0,0,0],
                    color: STACKED_CHART_COLORS[0],
                },
                {
                    name: "Pending",
                    data: [0,0,0,0,0],
                    color: STACKED_CHART_COLORS[1],
                },
            ],
        },
    });
    const [dashboardCountries, setDashboardCountries] = useState([]);

    const fetchDashboard = () => {
        CommonService.getDashboard()
            .then((response) => {
                if(response.data.status != false){
                    const countries = [];
                    const approvedCount = [];
                    const pendingCount = [];

                    response.data.data.top_countries.forEach(row => {
                        let c_data = row.split('-');
                        countries.push(c_data[0]);
                        approvedCount.push(+c_data[1]);
                        pendingCount.push(+c_data[2]);
                    });
                    const updatedData = response.data.data;
                    updatedData.top_countries.series = [];
                    updatedData.top_countries.series.push({data:approvedCount, name: "Approved", color: STACKED_CHART_COLORS[0] });
                    updatedData.top_countries.series.push({data:pendingCount, name: "Pending", color: STACKED_CHART_COLORS[1]});
                    updatedData.top_countries.xaxis = {categories:[]};

                    countries.forEach((value, index) => {
                        updatedData.top_countries.xaxis.categories.push(value);
                    });

                    setDashboardData(updatedData);
                    setDashboardCountries(countries);
                    let totalCost = 0;
                    updatedData.balance.cost.map((item) => {
                        totalCost += item.amount;
                    });

                    setTotalCost(totalCost);
                    setLoadingData(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchDashboard();
        return () => {
            setDashboardData({});
        }
    }, []);

    const apexChartOptions = useMemo(
        () => ({
          chart: {
            fontFamily: fontFamily,
          },
          title: {
            style: {
              fontFamily: fontFamily,
            },
          },
          xaxis: {
            labels: {
              style: {
                fontFamily: fontFamily,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontFamily: fontFamily,
              },
            },
          },
          legend: {
            fontFamily: fontFamily,
          },
            tooltip: {
                style: {
                fontFamily: fontFamily,
                },
            },
            dataLabels: {
                style: {
                fontFamily: fontFamily,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    horizontal: false,
                    columnWidth: '55%',
                },
            },
        }),
        [fontFamily]
      );
    

    const smsChartOptions = {
        ...apexChartOptions,
        title: {
            ...apexChartOptions.title,
            text: 'Weekly SMS Traffic',
            align: 'left',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                color: theme.palette['--main-text-color'],
                fontFamily: fontFamily
            },
        },
      colors: BAR_CHART_COLORS,
        chart: {
            ...apexChartOptions.chart,
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },

            },
        },
        plotOptions: {
            ...apexChartOptions.plotOptions,
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 10,
            },
        },
        dataLabels: {
            ...apexChartOptions.dataLabels,
            enabled: true,
            position: 'bottom',
            style: {
                fontSize: '12px',
                colors: ["#FFFFFF"]
            }
        },
        xaxis: {
            ...apexChartOptions.xaxis,
            type: 'datetime',
            categories: dashboardData.weekly_sms_traffic.map(item => item[0])
        },
        yaxis: {
            ...apexChartOptions.yaxis,
            title: {
                ...apexChartOptions.yaxis.title,
                text: 'Messages'
            }
        },
      
    };

    const smsChartSeries = [
        {
            name: 'Messages',
            data: dashboardData.weekly_sms_traffic.map(item => item[1])
        }
    ];

    const voiceChartOptions = {
        ...apexChartOptions,

        colors: BAR_CHART_COLORS,
        title: {
            ...apexChartOptions.title,
            text: 'Weekly Voice Traffic',
            align: 'left',
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                color: theme.palette['--main-text-color'],
                fontFamily: fontFamily
            },
        },
        chart: {
            ...apexChartOptions.chart,
            type: 'bar',
            height: 350,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },

            },
            animations: {
                enabled: true
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                horizontal: false,
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: true,
            position: 'bottom',
            style: {
                fontSize: '12px',
                colors: ["#FFFFFF"]
            }
        },
        xaxis: {
            ...apexChartOptions.xaxis,
            type: 'datetime',
            categories: dashboardData.weekly_voice_traffic.map(item => item[0])
        },
        yaxis: {
            ...apexChartOptions.yaxis,
            title: {
                ...apexChartOptions.yaxis.title,
                text: 'Minutes'
            }
        },
     
    };

    const voiceChartSeries = [
        {
            name: 'Minutes',
            data: dashboardData.weekly_voice_traffic.map(item => (item[1]/60.).toFixed(1))
        }
    ];

    const piecolors = ['#109CF1', '#109CF180', '#109CF14d', '#109CF133', '#182C5B'];

    let inventoryChartOptions = {
        ...apexChartOptions,
        series: dashboardData.top_countries.series,
        chart: {
            type: 'bar',
            height: 410,
            stacked: true,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },

            },
            animations: {
                enabled: true
            },
        },
        title:
            {
                ...apexChartOptions.title,
                text: 'Inventory Per Country',
                align: 'left',
                style: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: theme.palette['--main-text-color'],
                    fontFamily: fontFamily
                },
            },
        plotOptions:
            {
                bar:
                    {   ...apexChartOptions.plotOptions.bar,
                        borderRadius: 10,
                        barHeight: '99%',
                        horizontal: true,
                        dataLabels:
                            {
                                position: 'bottom'
                            },
                    }
            },
        dataLabels:
            {
                ...apexChartOptions.dataLabels,
                enabled: true,
                textAnchor: 'start',
                offsetX: 50,
                formatter: function (val, opt) {
                    if (opt.seriesIndex == 0) {
                        return opt.w.globals.labels[opt.dataPointIndex];
                    }
                },
                dropShadow:
                    {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 1.5,
                        opacity: 1
                    }
            },
        xaxis:
            {   
                ...apexChartOptions.xaxis,
                categories: dashboardCountries,
                labels:
                    {
                        ...apexChartOptions.xaxis.labels,
                        show: true
                    },
                axisBorder:
                    {
                        show: true,
                        offsetX: -15,
                    }
            },
        yaxis:
            {
                ...apexChartOptions.yaxis,
                show: true,
                labels:
                    {
                        ...apexChartOptions.yaxis.labels,
                        show: false
                    },
            },
        grid:
            {
                show: false
            },
        legend:
            {
                ...apexChartOptions.legend,
                show: true
            },
        stroke:
            {
                colors: ['transparent'],
                width: 1,
            }
    };


    let pieSeries = dashboardData.balance.cost.map(item => item["amount"]);
    let pieLabel = dashboardData.balance.cost.map(item => item["type"]);


    const pieOptions = {

        chart: {
            ...apexChartOptions.chart,
            width: '100%',
            type: 'pie',
            height: 400,
            redrawOnParentResize: true,
            animations: {
                enabled: true
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },

            },
        },
        colors: PIE_CHART_COLORS,
        labels: pieLabel,
        plotOptions:
            {
                pie:
                    {
                        dataLabels:
                            {
                                offset: -20
                            }
                    }
            },
        title:
            {
                text: 'Weekly Cost by Category Type',
                align: 'left',
                style: {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: theme.palette['--main-text-color'],
                    fontFamily: fontFamily
                },
            },
        dataLabels:
            {
                ...apexChartOptions.dataLabels,
                dropShadow:
                    {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 1.5,
                        opacity: 1
                    }
            },
        legend:
            {
                offsetY: 50,
                position: 'right',
                horizontalAlign: 'right',
                itemMargin:
                    {
                        horizontal: -10,
                        vertical: 10
                    },
            },

    }

    return (
        loadingData != true ? <>
            <Grid container spacing={2}>
                {displayVoiceUsage &&
                <Grid item xs={6}>
                    <BaseCard>
                    <TableFilterContainer>
                        <Chart options={voiceChartOptions} series={voiceChartSeries} type="bar" height={350} />
                    </TableFilterContainer>
                    </BaseCard>
                </Grid>
                }
                
                {displaySMSUsage ? <Grid item xs={6}>
                <BaseCard>

                    <TableFilterContainer>
                        <Chart options={smsChartOptions} series={smsChartSeries} type="bar" height={350} />
                    </TableFilterContainer>
                    </BaseCard>

                </Grid> : null}

                {displayInventoryPerCountry &&
                <Grid item xs={6}>
                <BaseCard>

                    <TableFilterContainer>
                        <Chart options={inventoryChartOptions} series={inventoryChartOptions.series} type="bar" height={350} />
                    </TableFilterContainer>
                    </BaseCard>

                </Grid> }

                {displayWeeklyCost &&
                <Grid item xs={6}>
                <BaseCard>

                    <TableFilterContainer>
                        <Chart options={pieOptions} series={pieSeries} type="pie" height={350} />

                        <Grid container>
                            <Grid item xs={12}>
                                <Box sx={{ textAlign: 'right' }}>Total Cost: {store.meta.currency} {totalCost} </Box>
                            </Grid>
                        </Grid>
                    </TableFilterContainer>
                    </BaseCard>

                </Grid>}
            </Grid>

        </> : <>
            <TableSkeleton />
        </>
    )
}
