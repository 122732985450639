import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Stack,
    Typography, Box, ListItemButton, ListItemIcon, IconButton
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData
} from 'src/constants/index';
import {NotificationService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import {Icon} from "@iconify/react";
import InboxIcon from '@mui/icons-material/Inbox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {MarkEmailRead, MarkEmailUnread} from "@mui/icons-material";
import NotificationInfoForm from "./NotificationInfoForm";
import DeleteNotificationForm from "./DeleteNotificationForm";
import BaseCard from 'src/components/BaseCard';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from '@mui/material/styles';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const ListItemStyle = styled(({ bgColor, ...props }) => <ListItemButton disableGutters {...props} />)(
    ({ theme, bgColor }) => ({
        ...theme.typography.body2,
        height: 52,
        position: 'relative',
        textTransform: 'capitalize',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2.5),
        alignItems: 'center',
        color: theme.palette.background.contrastText,
        backgroundColor: bgColor ? theme.palette['--sidebar-background-color-alpha-20'] : 'transparent',
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'primary'
});

const BoxStyle = styled(Box)({
    marginTop: "20px",
    marginBottom: "auto",
});
export default function ViewNotifications() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "subject", label: "Subject" },
        { key: "clean_message", label: "Message" },
        { key: "created_at", label: "Date" },
        { key: "action", label: t('actions') },
    ];
    
    const TABLE_FIELD_MAPPING = {
        subject: { key: "subject", index: 0 },
        clean_message: { key: "clean_message", index: 1 },
        message: { key: "message", index: 2, noRender: true },
        created_at: { key: "created_at", index: 3 },
        id: { key: "id", index: 4, noRender:true },
        notification_status: { key: "notification_status", index: 5, noRender:true },
    };
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [inboxCount, setInboxCount] = useState(0);


    const [notificationInfo, setNotificationInfo] = useState(false);
    const [deleteNotificationForever, setDeleteNotificationForever] = useState(false);

    const fetchNotifications = (page) => {
        setActivePage(page);
        const params = {
            notification_status: page == 0 ? 2 : page == 1 ? 1 : 3,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);

        NotificationService.getNotifications(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    item["6"] = {"key": "itemIndex", value: idx, noRender: true};
                    items.push(item);
                }
                setData(items);
                if(page == 0){
                    setInboxCount(response.data.data.count);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }




    useEffect(() => {
        fetchNotifications(0);
        return () => {
            setData([]);
        }
    }, [paginationData]);



    const formatRowData = (rowData) => {
        let formatted = [];
        console.log(rowData);
        rowData.map((d, idx) => {
            if (d.key === "created_at") {
                formatted.push({
                    ...d,
                    value: dayjs(d.value).format('DD/MM/YYYY HH:mm'),
                });
            }  else if (d.key === "clean_message") {
                formatted.push({
                    ...d,
                    value: d.value.length > 80 ? d.value.substr(0,80) + "..." : d.value,
                    onClick: () => modalHandler("notificationInfo", rowData[6]["value"])
                });
            } else {
                formatted.push({...d, onClick: () => modalHandler("notificationInfo", rowData[6]["value"])});
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "deleteNotificationForever") {
            setDeleteNotificationForever(!deleteNotificationForever);
        } else if (modalType === "notificationInfo") {
            setNotificationInfo(!notificationInfo);
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const deleteNotification = (index) => {
        NotificationService.deleteNotification(data[index][4]["value"], {"notification_status":3})
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Notification has been moved to trash.");
                    setSnackbarStatus(true);
                    fetchNotifications(activePage);
                } else { throw "Notification moving to trash failed"; }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
    }



    const unreadNotification = (index) => {
        NotificationService.unreadNotification(data[index][4]["value"], {"notification_status":1})
            .then((response) => {
                if (response.data.meta.code === 200) {
                    setMessage("Notification set as unread.");
                    setSnackbarStatus(true);
                    fetchNotifications(activePage);
                } else { throw "Failed to set as unread"; }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
    }
    const theme = useTheme();
    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent="start" spacing={2}>
                    <Box display="flex" alignItems="start" justifyContent="start" justifyItems="start" width={100}>
                        {data[index][5]["value"] === "Unread" ? (
                            <BaseButton
                                label="Read"
                                StartIcon={MarkEmailRead}
                                onClick={() => modalHandler("notificationInfo", index)}
                                color="--main-text-color"
                            />
                        ) : (
                            <BaseButton
                                label="Unread"
                                StartIcon={MarkEmailUnread}
                                onClick={() => unreadNotification(index)}
                                color="--main-text-color"
                            />
                        )}
                    </Box>
                    <BaseButton
                        label={'Trash'}
                        StartIcon={DeleteIcon}
                        onClick={() => deleteNotification(index)} color={"--main-text-color"}
                    />
                    <BaseButton
                        label={'Delete'}
                        StartIcon={DeleteForeverIcon}
                        onClick={() => modalHandler("deleteNotificationForever", index)} color={"--main-text-color"}
                    />
                </Stack>
            )
    }



    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseDialog title={"Update Notification"} dialogProperties={DIALOG_PROPERTIES} closeCallback={ () => fetchNotifications(activePage)} open={notificationInfo} setOpen={setNotificationInfo} children={<NotificationInfoForm successCallback={ () =>  fetchNotifications(activePage)} formType="add" formData={selectedRow} setModalStatus={setNotificationInfo} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={"Are you sure?"} open={deleteNotificationForever} setOpen={setDeleteNotificationForever} children={<DeleteNotificationForm successCallback={ () =>  fetchNotifications(activePage)} formData={selectedRow} setModalStatus={setDeleteNotificationForever} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>
                <Grid container>
                    <Grid item sx={{ width: '15%'}}>
                        <ListItemStyle  onClick={()=> fetchNotifications(0)} bgColor={ activePage == 0}
                        >
                            <ListItemIconStyle><InboxIcon/></ListItemIconStyle>
                            <Grid spacing={2} container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography>{inboxCount !== 0 ? 'Inbox (' + inboxCount.toString() + ')' : "Inbox"}</Typography>
                                </Grid>
                                <Grid item>
                                    <Box
                                        component={Icon}
                                        icon={<InboxIcon/>}
                                        sx={{ m:0, p:0, backgroundColor: '#FF0000'}}
                                    />
                                </Grid>
                            </Grid>
                        </ListItemStyle>

                        <ListItemStyle onClick={()=> fetchNotifications(1)} bgColor={ activePage == 1}
                        >
                            <ListItemIconStyle><DeleteOutlineIcon/></ListItemIconStyle>
                            <Grid spacing={2} container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography>{'Unread'}</Typography>
                                </Grid>
                                <Grid item>
                                    <Box
                                        component={Icon}
                                        icon={<DeleteOutlineIcon/>}
                                        sx={{ m:0, p:0}}
                                    />
                                </Grid>
                            </Grid>
                        </ListItemStyle>
                        <ListItemStyle onClick={()=> fetchNotifications(2)} bgColor={ activePage == 2}
                        >
                            <ListItemIconStyle><DeleteIcon/></ListItemIconStyle>
                            <Grid spacing={2} container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography>{'Trash'}</Typography>
                                </Grid>
                                <Grid item>
                                    <Box
                                        component={Icon}
                                        icon={<DeleteIcon/>}
                                        sx={{ m:0, p:0}}
                                    />
                                </Grid>
                            </Grid>
                        </ListItemStyle>
                    </Grid>
                    <Grid item sx={{ width: '85%' }}>
                        <BaseTable 
                            head={TABLE_HEAD}
                            data={[...data].map((d, idx) => { return formatRowData(d); })}
                            actionItemPrep={getActionItems}
                            select={true}
                            pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                            loadingData={loadingData}
                        />
                    </Grid>
                </Grid>
            </TableFilterContainer>
            </BaseCard>
            <br/>
        </>
    );
}
