import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    FormControl, Box, CircularProgress, InputLabel, Select, MenuItem
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import {makeStyles} from '@mui/styles';
import BaseButton from "../../../components/buttons/BaseButton";
import * as ChannelService from "../../../api/services/Channels";

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function RemoveDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    const removeChannel = () => {
        const payload = {
            product_id: formData.product_id
        }
        ChannelService.removeChannel(payload)
            .then((response) => {
                if(response.data.status == true){
                    setMessage("Remove request has been successfully submitted");
                    setSnackbarStatus(true);
                    setModalStatus(false);
                }else{
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                    successCallback();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }


    return  <>
           <TableFilterContainer>
                       <Grid item md={3} xs={12}>
                           <div align={'left'}>{"You are removing channel with product: " + formData.product}</div>
                       </Grid>
                       <br/>
                       <br/>
                       <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={1}>
                           <BaseButton sx={{marginRight:1}}
                               label={'Cancel'}
                               onClick={() => setModalStatus(false)}
                               color={"--color-primary"}
                           />
                           <BaseButton
                               label={'Remove Channel'}
                               onClick={() => removeChannel()}
                               color={"--color-danger"}
                           />
                       </Grid>
           </TableFilterContainer>
           </>;
}

