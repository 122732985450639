import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import InboundRates from './InboundRates';
import OutBoundVoiceRates from './OutboundVoiceRates';
import OutBoundSmsRates from './OutboundSMSRates';
import {useStore} from "../../../store/Store";

// ----------------------------------------------------------------------


export default function PricingSidebar() {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const externalSMSEnabled = store.portalSettings.services.access_management.external_sms ?? false;

    const TABS = externalSMSEnabled ? [
        { id: 0, label: "Inbound Rates", component: <InboundRates/> },
        { id: 1, label: t('Outbound Voice Rates'), component: <OutBoundVoiceRates /> },
        { id: 1, label: t('Outbound SMS Rates'), component: <OutBoundSmsRates /> },
    ] : [
        { id: 0, label: "Inbound Rates", component: <InboundRates/> },
        { id: 1, label: t('Outbound Voice Rates'), component: <OutBoundVoiceRates /> },
    ];

    const TITLE = 'Pricing';

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
