import React, {useState, useEffect} from 'react';
import {styled} from '@mui/system';
import {
    Grid,
    Stack,
    Typography, Box, IconButton
} from '@mui/material';
import {BaseTable, TableFilterContainer} from 'src/components/table';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import {rowArrayToObject} from 'src/utils/Util';
import {
    DefaultPaginationData,
    NumberStatus,
    SetType,
    getLabelByValue,
    Capability
} from 'src/constants/index';
import * as NumberService from "../../../api/services/Numbers";
import BaseSnackbar from "../../../components/BaseSnackbar";
import BaseButton from "../../../components/buttons/BaseButton";
import {useTranslation} from "react-i18next";
import DeleteEndpointForm from "./Forms/DeleteEndpointForm";
import UpdateEndpointForm from "./Forms/UpdateEndpointForm";
import AddSIPTrunkForm from "./Forms/AddSIPTrunkForm";
import BaseCard from 'src/components/BaseCard';
import EditIcon from "@mui/icons-material/Edit";
import {useTheme} from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";


const SearchButtonContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({theme}) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function VoiceEndpoints() {
    const {t} = useTranslation();
    const theme = useTheme();
    const TABLE_HEAD = [
        {key: "name", label: "Name"},
        {key: "hostname", label: "Host Adress"},
        {key: "port", label: "IB Port"},
        {key: "priority", label: "IB Priority"},
        {key: "weight", label: "Weight"},
        {key: "outbound_port", label: "Outbound Port"},
        {key: "provisioning_status", label: "Provisioning Status"},
        {key: "actions", label: "Actions"},
    ];

    const TABLE_FIELD_MAPPING = {
        //id: { key: "id", cellComponentType: "th", index: 0 },
        name: {key: "name", index: 0},
        hostname: {key: "hostname", index: 1},
        port: {key: "port", index: 2},
        priority: {key: "priority", index: 3},
        weight: {key: "weight", index: 4},
        outbound_port: {key: "outbound_port", index: 5},
        provisioning_status: {key: "provisioning_status", index: 6},
        id: { key: "id", index: 7, noRender:true },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [openAddSIPTrunk, setOpenAddSIPTrunk] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState("");
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterDirectionType, setDirectionType] = useState("");
    const [filterSetType, setSetType] = useState("");
    const [filterNumber, setNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [signallingIP, setSignallingIP] = useState("");

    const fetchNumbers = () => {
        const params = {
            country: filterCountry ? filterCountry : undefined,
            capability: filterCapability ? filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        NumberService.getEndpoints(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        let newMapping = TABLE_FIELD_MAPPING;
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    console.log(item);
                    items.push(item);
                }
                setData(items);
                setSignallingIP(response.data.data.signalling_ips);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }





    useEffect(() => {
        fetchNumbers();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()),
                    chipColor: d.value === 3 ? "--color-success" : "--color-warning",
                });
            } else if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(SetType(), d.value.toString()),
                });
            } else if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "edit") {
            setSearchNumbersDialogStatus(!openSearchNumbersDialog);
        } else if (modalType === "delete") {
            setDeallocateModalStatus(!openDeallocateModal);
        }

        if (index) {
            setSelectedRow(rowArrayToObject(data[index]))
        }
    }

    const getActionItems = (index) => {
            return (
                <Stack direction="row" spacing={2}>

                    <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <EditIcon
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-warning']}}
                            onClick={() => modalHandler("edit", index)} />
                    </IconButton>

                    <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <DeleteIcon
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-danger']}}
                            onClick={() => modalHandler("delete", index)} />
                    </IconButton>

                </Stack>
            )

    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <BaseDialog title={"Update Voice Interconnection"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<UpdateEndpointForm successCallback={fetchNumbers} formType="add" formData={selectedRow} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={"Add SIP Trunk"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openAddSIPTrunk} setOpen={setOpenAddSIPTrunk} children={<AddSIPTrunkForm successCallback={fetchNumbers} formType="add" formData={selectedRow} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} close={setOpenAddSIPTrunk} />} />
            <BaseModal title={"Are you sure?"} open={openDeallocateModal} setOpen={setDeallocateModalStatus} children={<DeleteEndpointForm successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setDeallocateModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>

                <br/>
                <SearchButtonContainer item md={12} xs={12}>
                    <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                        <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                        </TitleStyle>
                        </Box>
                        <BaseButton
                            label={'Add SIP Trunk'}
                            onClick={() => setOpenAddSIPTrunk(true)}
                        />
                    </Box>
                    <Box><strong>Signalling IP: {signallingIP}</strong></Box>
                </SearchButtonContainer>

            </TableFilterContainer>
            </BaseCard>
            <br/>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => {
                    return formatRowData(d);
                })}
                actionItemPrep={getActionItems}
                pagination={{
                    paginationData: {...paginationData, totalCount: totalCount},
                    setPaginationData: setPaginationData
                }}
                loadingData={loadingData}
            />
        </>
    );
}
