import { useState } from 'react';
import { useStore } from 'src/store/Store';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
    Box,
    List,
    Collapse,
    ListItemIcon,
    ListItemButton,
    Divider,
    Tooltip,
    Typography,
    Grid
} from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 52,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2.5),
      alignItems:'center',
    color: theme.palette["--sidebar-text-color"] ,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette["--color-primary"]
    }
  })
);


const ListItemIconStyle = styled(ListItemIcon)(({ theme }) => ({
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette["--color-primary"], // Set the color from the theme
}));


const BoxStyle = styled(Box)({
  marginTop: "20px",
  marginBottom: "auto",

});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: theme.palette["--color-primary"],
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette["--secondary-color"], 5),
  };

  const activeSubStyle = {
    color: theme.palette["--color-primary"],
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette["--secondary-color"], 5),
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

            <Grid spacing={2} container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
                    {info && info}
                </Grid>

                <Grid item>

                    <Box
                        component={Icon}
                        icon={open ?  arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ m:0, p:0}}
                    />


                </Grid>
            </Grid>

        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                    
                    <Typography sx={{ fontWeight: 600, marginLeft: theme.spacing(5) }}>{title}</Typography>
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <Tooltip title={title} placement="right-end">
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      </Tooltip>
      <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [store, dispatch] = useStore();
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <>
      <BoxStyle {...other}>
        <List >
          {navConfig.map((item) => {
            if (!item.adminOnly || (item.adminOnly && store.user.is_superuser) || (item.staffOnly && store.user.is_staff)) {
              let serviceStatusEnabled;
              if (item.requiredServices) {
                serviceStatusEnabled = 0;
                item.requiredServices.forEach(service => {
                  if (store.portalSettings.services && store.portalSettings.services[service]) {
                    serviceStatusEnabled += 1;
                  }
                })
              } else {
                serviceStatusEnabled = 1;
              }
              if (serviceStatusEnabled > 0) {
                return <>
                  <NavItem key={item.title} item={item} active={match} />
                  {item.addDividerAfter ? <Divider /> : null}
                </>;
              }
            } return null;
          })}
        </List>
      </BoxStyle>
    </>
  );
}
