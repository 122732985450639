import React from 'react';
import { useState, useEffect } from 'react';
import {Box, IconButton, Stack, useTheme} from '@mui/material';
import { DefaultPaginationData,
    getColorByValue,
    getLabelByValue,
    MembersStatus} from 'src/constants/index';
import { useTranslation } from 'react-i18next';
import { TableFilterContainer, BaseTable } from 'src/components/table';
import BaseButton from 'src/components/buttons/BaseButton';
import AddPersonalIdentityForm from './Forms/EditPersonalIdentity';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseModal from 'src/components/BaseModal';
import {CommonService, IdentityService} from 'src/api/services';
import BaseCard from 'src/components/BaseCard';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddAddressForm from "./Forms/AddAddressForm";
import EditAddressForm from './Forms/EditAddressForm';
import DeleteAddressDialog from './Forms/DeleteAddressDialog';
import BaseDialog from 'src/components/BaseDialog';

// ----------------------------------------------------------------------


export default function Addresses() {
    const {t} = useTranslation();
    const [openAddMemberModal, setAddMemberModalStatus] = useState(false);
    const [openEditMemberModal, setEditMemberModalStatus] = useState(false);
    const [openDeleteMemberModal, setDeleteMemberModalStatus] = useState(false);

    const [selectedMember, setSelectedMember] = useState([]);
    const [message, setMessage] = useState("");
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [loadingData, setLoadingData] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    const TABLE_HEAD = [
        {key: "name", label: t("Name"), alignRight: false},
        {key: "city", label: t("City"), alignRight: false},
        {key: "country", label: t("Country"), alignRight: false},
        {key: "postal Code", label: t("Postal Code"), alignRight: false},
        {key: "actions", label: t("Actions"), alignRight: false},

    ];

    const TABLE_FIELD_MAPPING = {
        "name": {index: 0, key: "name"},
        "address_line_1": {index: 1, key: "address_line_1", noRender: true},
        "address_line_2": {index: 2, key: "address_line_2", noRender: true},
        "city": {index: 3, key: "city"},
        "country": {index: 4, key: "country"},
        "postal_code": {index: 5, key: "postal_code"},
        "additional_info_notes": {index: 6, key: "additional_info_notes", noRender: true},
        "id": {index: 7, key: "id", noRender: true},
        "country_id": {index: 8, key: "country_id", noRender: true},
    };


    const fetchAddresses = () => {
        const params = {
            size: paginationData.rowsPerPage,
            page: paginationData.page + 1,
            identity_type: 1
        };
        setLoadingData(true);
        IdentityService.listAddresses(params)
            .then((response) => {
                if (!response.data || !response.data.data) {
                    throw(t("session-expired"));
                }
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })

    };

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["id"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchCountries()
    }, []);

    useEffect(() => {
        fetchAddresses();
        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(MembersStatus(), d.value.toString()), chipColor: getColorByValue(MembersStatus(), d.value.toString()),
                });
            }else {
                formatted.push(d);
            }
        })
        return formatted;
    };


    const modalHandler = (modalType, index) => {
        if (modalType === "addMember") {
            setAddMemberModalStatus(!openAddMemberModal);
        }
        if (modalType === "editAddress") {
            setEditMemberModalStatus(!openEditMemberModal);
            setSelectedMember(data[index]);
        }
        if (modalType === "deleteAddress") {
            setDeleteMemberModalStatus(!openDeleteMemberModal);
            setSelectedMember(data[index]);
        }
    };

    const theme = useTheme();

    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent='start' spacing={1}>
                        <>
                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <EditIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("editAddress", index)}/>
                            </IconButton>

                            <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                                <DeleteIcon
                                    sx = {{
                                        width:30,
                                        height:30,
                                        borderRadius:0.5,
                                        color: "white",
                                        backgroundColor: theme.palette['--color-success']}}
                                    onClick={() => modalHandler("deleteAddress", index)}/>
                            </IconButton>
                        </>
                </Stack>
            );
      };

      const DIALOG_PROPERTIES = {
        fullWidth: true,
        maxWidth: "md",
        scroll: "body",
    }

    return (
        <>
            <Box marginBottom={8}>
                <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
                <BaseCard>
                <TableFilterContainer >
                    <Box alignItems={"center"} sx={{
                        height: 50,
                        display: 'flex'
                        }}>
                        <BaseButton
                            label= {t("+ Add New Address")}
                            onClick={() => modalHandler("addMember")}
                        />
                    </Box>
                </TableFilterContainer>
                </BaseCard>
                <BaseDialog title={t("Add New Address")} open={openAddMemberModal} setOpen={setAddMemberModalStatus} children={<AddAddressForm countries={countries} successCallback={fetchAddresses} setModalStatus={setAddMemberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchAddresses} />

                <BaseModal title={t("Edit Address")} open={openEditMemberModal} setOpen={setEditMemberModalStatus} children={<EditAddressForm formData={selectedMember} countries={countries} successCallback={fetchAddresses} setModalStatus={setEditMemberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchAddresses}  />

                <BaseModal title={t("Delete Address")} open={openDeleteMemberModal} setOpen={setDeleteMemberModalStatus} children={<DeleteAddressDialog formData={selectedMember} countries={countries} successCallback={fetchAddresses} setModalStatus={setDeleteMemberModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage}/>} />
            </Box>

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
