import { useEffect, useState } from 'react';
import {
    Grid,
    FormControl,
    Box,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
    DefaultPaginationData,
    getLabelByValue,
    OrderStatus
} from 'src/constants/index';
import { BaseTable, TableFilterContainer } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import SearchButton from 'src/components/buttons/SearchButton';
import { useTranslation } from 'react-i18next';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import * as TransactionService from "../../../api/services/Transactions";
import BaseCard from "../../../components/BaseCard";
import BaseDateTimePicker from 'src/components/BaseDateTimePicker';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function Transactions() {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { key: "id", label: t('#') },
        { key: "type", label: 'Type' },
        { key: "amount", label: 'Amount' },
        { key: "transaction_at", label: 'Transaction At' },
        { key: "quantity", label: 'Quantity' },
        { key: "note", label: 'Note' },
    ];

    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        type: { key: "type", index: 1 },
        amount: { key: "amount", index: 2 },
        transaction_at: { key: "transaction_at", index: 3 },
        quantity: { key: "quantity", index: 4 },
        note: { key: "note", index: 5 },
        currency: { key: "currency", index: 6, noRender:true },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");


    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));
    const [filterEnd, setFilterEnd] = useState(dayjs(new Date()).endOf('day'));


    const fetchOrders = () => {
        const params = {
            status: filterStatus ? filterStatus : undefined,
            page: paginationData.page + 1,
            page_size: paginationData.rowsPerPage,
            start_datetime:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
            end_datetime:filterEnd.format('YYYY-MM-DDTHH:mm:ss'),
            size:10,
        };
        setLoadingData(true);
        TransactionService.getTransactions(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_FIELD_MAPPING.length).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })

    }

    useEffect(() => {
        fetchOrders();


        return () => {
            setData([]);
        }
    }, [paginationData]);

    const formatRowData = (rowData) => {
        let formatted = [];
        console.log(rowData);
        rowData.map((d, idx) => {
            if (d.key === "transaction_at") {
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            } else if (d.key === 'amount') {
                formatted.push({
                    ...d,
                    value: rowData[6]["value"] + " " + d.value,
                });
            }
            else {
                formatted.push(d);
            }
        })
        return formatted;
    }



    const getActionItems = (index) => {
        return null;
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BaseDateTimePicker onChange={(date)=> setFilterStart(date)} value={filterStart} label="Start Date-Time" />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BaseDateTimePicker onChange={(date)=> setFilterEnd(date)} value={filterEnd} label="End Date-Time" />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchOrders() }} />
                    </SearchButtonContainer>
                </Grid>
                <br/>
                <SearchButtonContainer item md={12} xs={12}>
                    <Box display="flex"  justifyContent="space-between" alignItems="center" alignContent="center" sx={{textAlign: "right"}}>
                        <Box><strong>Results:</strong> <TitleStyle>Total <strong>{totalCount}</strong> records found.
                        </TitleStyle>
                        </Box>

                    </Box>
                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />
            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
