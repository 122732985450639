import React, { useEffect } from 'react';
import {
    List,
    ListItemText,
    Divider,
    ListItem,
    Box
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import BaseSnackbar from 'src/components/BaseSnackbar';
import {useTheme} from '@mui/styles'
import * as OrderService from "../../../api/services/Orders";
import {useStore} from "../../../store/Store";
import { maskeDid } from 'src/utils/Util';

export default function ShowOrderDetail() {

    const { t } = useTranslation();
    const [data, setData] = useState({dids:[]});
    const { id } = useParams();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [store, dispatch] = useStore();
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const fetchOrderDetail = async () => {
        OrderService.getOrderDetail(id)
            .then((response) => {
                if (response.data.meta.code !== 200) {
                    navigate(`./not-found`);
                }
                if(response.data.data.dids == ""){
                    response.data.data.dids = [];
                }
                setData(response.data.data);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    useEffect(() => {
        fetchOrderDetail();
        return () => {
            setData([]);
        };
    }, [id]);

    const theme = useTheme();

    return(


        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : 'white',
                border:'3em solid white',
                borderColor: theme.palette['--card-background-color'],
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>
                <List component="nav" aria-label="mailbox folders" >
                    <h1>Order #{id}</h1>
                    <br/>
                    <ListItem >
                        <ListItemText primary={t("id")} />
                        {data.id}
                    </ListItem>
                    <Divider />
                    <ListItem selected className='ListItem' >
                        <ListItemText primary= {t("country")} />
                        {data.country}
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary={t("DID Type")} />
                        {data.did_type}
                    </ListItem>
                    <Divider />
                    <ListItem selected >
                        <ListItemText primary={t("Capability")} />
                        {data.capability}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("Requested Count")} />
                        {data.requested_count}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Purchased Count")} />
                        {data.purchased_count}
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={t("Requested At")} />
                        {data.requested_at ? dayjs(data.requested_at).format('DD/MM/YYYY HH:mm:ss') : null}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Finalized At")} />
                        {data.requested_at ? dayjs(data.requested_at).format('DD/MM/YYYY  HH:mm:ss') : null}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("Status")} />
                        {data.status}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Numbers")} />
                        <Box sx={{ 
                            alignItems: 'right',
                            justifyContent: 'right',
                            alignContent: 'right',
                            textAlign: 'right',
                            whiteSpace: 'normal', 
                            wordBreak: 'break-all', 
                            overflowWrap: 'break-word', 
                            width: '80%' 
                        }}>
                            {data.status === "Ready" ? data.dids.join(', ') : data.dids.map(did => maskeDid(did)).join(', ')}
                        </Box>
                    </ListItem>
                    {providersEnabled && (<><ListItem  >
                        <ListItemText primary={t("Provider")} />
                        {data.provider_code}
                    </ListItem>
                    </>)}

                </List>
            </Box>


        </>

    )
}
