import React, {useEffect} from 'react';
import { useState } from 'react';
// material
import {
    Stack,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Typography,
    Grid,
    Box,
    Button,
    useTheme,
    InputLabel,
    Select
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useTranslation } from 'react-i18next';
import {CommonService, IdentityService} from 'src/api/services';
import BaseButton from 'src/components/buttons/BaseButton';
import {styled} from "@mui/system";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {getSelectOptions, IdentityType, PersonalIdentityType} from "../../../../constants";
import {TableFilterContainer} from "../../../../components/table";
import BaseTextField from "../../../../components/BaseTextField";
import * as NumberService from "../../../../api/services/Numbers";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function EditSubcustomerForm({ setModalStatus, formData, setSnackbarStatus, setMessage, successCallback }) {
    const getValueFromFormData = (key) => {
        const filtered = formData.filter((item) => item.key === key);
        if(filtered.length > 0) {
            return filtered[0].value;
        }
        return "";
    }
    const { t } = useTranslation();
    const [subCustomerName, setSubCustomerName] = useState(getValueFromFormData("subcustomer_name"));
    const [filterAddress, setFilterAddress] = useState(getValueFromFormData("address_id"));
    const [filterTrunk, setFilterTrunk] = useState(getValueFromFormData("trunk_id"));
    const [filterIdentity, setFilterIdentity] = useState(getValueFromFormData("identity_id"));

    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [docType, setDocType] = useState(0);
    const [uploadProgressMap, setUploadProgressMap] = useState({});
    const [countries, setCountries] = useState([]);
    const [identities, setIdentities] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [trunks, setTrunks] = useState([]);


    const fetchIdentities = () => {
        const params = {
            pagination:2
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"],
                        label: item[1]["company_name"] != null ? item[1]["company_name"] + " (Company)" : item[1]["name"] + " (Individual)",
                        value: item[1]["id"] });
                })
                setIdentities(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data.items).forEach((item) => {
                    console.log(item);
                    items.push({
                        label: item[1]["address_line_1"] + ", " + item[1]["city"] + ", " + item[1]["country"],
                        value: item[1]["id"] });
                })
                setAddresses(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchTrunks = () => {
        NumberService.getEndpoints()
            .then((response) => {
                let items = [];
                Object.entries(response.data.data.items).forEach((item) => {
                    items.push({
                        label: item[1]["name"] + ", " + item[1]["hostname"],
                        value: item[1]["id"] });
                })
                setTrunks(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
            })
    }


    useEffect(() => {
        fetchTrunks();
        fetchIdentities();
        fetchAddresses();
    }, []);

    const addSubCustomer =() => {
        const payload = {
            name: subCustomerName,
            identity_id: filterIdentity,
            address_id: filterAddress,
            trunk_id: filterTrunk
        };

        for (const [key, value] of Object.entries(payload)) {
            if (value == "0") {
                payload[key] = null;
            }
        }

        if (payload.name === "" || payload.identity_id == null || payload.address_id === null || payload.trunk_id === null) {
            setMessage(t('Please fill mandatory fields'));
            setSnackbarStatus(true);
            return;
        }

        IdentityService.updateSubCustomer(payload, getValueFromFormData("id"))
        .then((response) => {
            if (response.data.meta.code === 200) {
                setMessage(t('Subcustomer added successfully'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw (t("could-not-be-added"));
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-added'));
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        });
    }

    const theme = useTheme();

    const handleFileChange = () => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile};
        setSelectedFile([fileWithLabel]);
    };

    const [isUploading, setIsUploading] = useState(false);
    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };


    const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "primary",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "primary",
        },
    }));

    return (
        <TableFilterContainer>

    <Stack  spacing={2} justifyContent="left" alignItems="left" alignContent={"left"} justifyItems={"left"}>
        <BaseTextField
            value={subCustomerName}
            label={'Sub Customer/Site Name'}
            name="firstName"
            margin="normal"
            variant="outlined"
            color="secondary"
            onChange={setSubCustomerName}
        />
        <FormControl fullWidth={true}>
                <InputLabel id="filter-status-label">{t('Identity')}</InputLabel>
            <Select
                label={'identity'}
                labelId="filter-identity-label"
                name="identity"
                value={filterIdentity}
                textAlign="left"
                alignItems="left"
                justifyContent="left"
                sx={{textAlign: "left", alignItems: "left", justifyContent: "left"}}
                onChange={event => {
                    setFilterIdentity(event.target.value)
                }}
            >
                {getSelectOptions(identities)}
            </Select>

    </FormControl>
    <FormControl>
    <InputLabel id="filter-status-label">{t('Address')}</InputLabel>
        <Select
            label={'Address'}
            labelId="filter-address-label"
            name="Address"
            value={filterAddress}
            textAlign="left"
            alignItems="left"
            justifyContent="left"
            sx={{textAlign: "left", alignItems: "left", justifyContent: "left"}}
            onChange={event => {
                setFilterAddress(event.target.value)
            }}
        >
            {getSelectOptions(addresses)}
        </Select>
    </FormControl>
        <FormControl>
            <InputLabel id="filter-status-label">{t('Trunk')}</InputLabel>
            <Select
                label={'Trunk'}
                labelId="filter-country-label"
                name="trunk"
                value={filterTrunk}
                textAlign="left"
                alignItems="left"
                justifyContent="left"
                sx={{textAlign: "left", alignItems: "left", justifyContent: "left"}}
                onChange={event => {
                    setFilterTrunk(event.target.value)
                }}
            >
                {getSelectOptions(trunks, ["0", "Any"])}
            </Select>

        </FormControl>
        <br/>

            <Stack sx={{ display: "block", alignItems: "right"  }} direction="row" spacing={2}>
                <BaseButton
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    onClick={() => setModalStatus(false)}
                    >
                    {t('cancel')}
                </BaseButton>
                <BaseButton
                    type="submit"
                    color="--color-primary"
                    variant="contained"
                    loading={loading}
                    onClick={() => addSubCustomer()}
                    >
                    {t('Save')}
                </BaseButton>
            </Stack>
        </Stack>
        </TableFilterContainer>
  );
}
