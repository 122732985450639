import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline, Backdrop, CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import shape from './shape';
import typography from './typography';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { useStore } from 'src/store/Store';
import { useEffect } from 'react';
import { fontFamily } from 'src/constants';
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};

// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:960px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1280px)': {
      fontSize: pxToRem(lg)
    }
  };
}


export default function ThemeConfig({ children }) {
  const [store, dispatch] = useStore();


  const themeOptionsWoColors = useMemo(
    () => ({
      shape: shape,
      typography: {
        fontFamily: store.fontFamily || 'Lato, sans-serif',
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        h1: {
          fontWeight: 700,
          lineHeight: 80 / 64,
          fontSize: pxToRem(39),
          ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
        },
        h2: {
          fontWeight: 700,
          lineHeight: 64 / 48,
          fontSize: pxToRem(31),
          ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
        },
        h3: {
          fontWeight: 700,
          lineHeight: 1.5,
          fontSize: pxToRem(23),
          ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
        },
        h4: {
          fontWeight: 700,
          lineHeight: 1.5,
          fontSize: pxToRem(19),
          ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
        },
        h5: {
          fontWeight: 700,
          lineHeight: 1.5,
          fontSize: pxToRem(17),
          ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
        },
        h6: {
          fontWeight: 600,
          lineHeight: 28 / 18,
          fontSize: pxToRem(16),
          ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
        },
        subtitle1: {
          fontWeight: 600,
          lineHeight: 1.5,
          fontSize: pxToRem(15)
        },
        subtitle2: {
          fontWeight: 600,
          lineHeight: 22 / 14,
          fontSize: pxToRem(13)
        },
        body1: {
          lineHeight: 1.5,
          fontSize: pxToRem(16)
        },
        body2: {
          lineHeight: 22 / 14,
          fontSize: pxToRem(14)
        },
        caption: {
          lineHeight: 1.5,
          fontSize: pxToRem(11)
        },
        overline: {
          fontWeight: 500,
          lineHeight: 1.5,
          fontSize: pxToRem(11),
          letterSpacing: 1.1,
          textTransform: 'uppercase'
        },
        button: {
          fontWeight: 600,
          lineHeight: 24 / 14,
          fontSize: pxToRem(13),
          textTransform: 'capitalize'
        }
      
      },
      shadows: shadows,
      customShadows: customShadows,
    }),
    [store.fontFamily]
  );

  const themeOptionsWithColors = useMemo(
    () => ({
      ...themeOptionsWoColors,
      palette: store.palette,
    }),
    [store.palette, themeOptionsWoColors]
  );

  const theme = createTheme(themeOptionsWithColors);
  theme.components = componentsOverride(theme);

  useEffect(() => {
    if (store.fontFamily) {
        const fontDetails = fontFamily.find(font => font.fontFamily === store.fontFamily);
        if (fontDetails) {
          const link = document.createElement('link');
          link.href = fontDetails.url;
          link.rel = 'stylesheet';
          document.head.appendChild(link);

          return () => {
            document.head.removeChild(link);
          };
        }
    }
  }, [store.fontFamily]);

  return (
    <>
    {store.manualUpdateTriggeredForPalette ? 
        <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
      :
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>}
    </>
  );
}
