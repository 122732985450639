import React, { useEffect } from 'react';
import {
    List,
    ListItemText,
    Divider,
    ListItem,
    Box
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PortingService } from 'src/api/services';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import BaseSnackbar from 'src/components/BaseSnackbar';
import {useTheme} from '@mui/styles'

export default function ShowPortOuts() {

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const { id } = useParams();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const fetchPortOutDetails = async () => {
        PortingService.listPortOutDetails(id)
            .then((response) => {
                if (response.data.meta.code !== 200) {
                    navigate(`./not-found`);
                }
                setData(response.data.data);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    useEffect(() => {
        fetchPortOutDetails();
        return () => {
            setData([]);
        };
    }, [id]);

    const theme = useTheme();

    return(


        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : 'white',
                border:'3em solid white',
                borderColor: theme.palette['--card-background-color'],
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>
                <List component="nav" aria-label="mailbox folders" >
                    <h1>Porting#{id}</h1>
                    <br/>
                    <ListItem selected className='ListItem' >
                        <ListItemText primary= {t("id")} />
                        {data.id}
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary={t("Porting Type")} />
                        {data.porting_type}
                    </ListItem>
                    <Divider />
                    <ListItem selected >
                        <ListItemText primary={t("Country")} />
                        {data.country}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("DID Type")} />
                        {data.did_type}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Numbers")} />
                        {data.numbers ? data.numbers.join(", ") : null}
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={t("Requested at")} />
                        {data.requested_at ? dayjs(data.requested_at).format('DD/MM/YYYY') : null}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Scheduled at")} />
                        {data.requested_at ? dayjs(data.requested_at).format('DD/MM/YYYY') : null}
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={t("Authorization Code")} />
                        {data.authorization_code}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Status")} />
                        {data.porting_status}
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t("Admin Notes")} />
                        <List>
                            {data.admin_comments ? data.admin_comments.map((comment, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={index+1+'. '+ comment} />
                                </ListItem>
                            )) : null}
                        </List>
                    </ListItem>
                    <ListItem selected>
                        <ListItemText primary={t("Customer Reference")} />
                        <List>
                        <ListItem>
                            <ListItemText primary={data.customer_notes} />
                        </ListItem>
                        </List>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t("Rejected Documents")} />
                        <List>
                            {data.required_docs_metadata ? data.required_docs_metadata.map((doc, index) => {
                                if (doc.rejected == true) {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemText primary={'Document: '+doc.input_definition.label} secondary={doc.filename} />
                                        </ListItem>
                                    )
                                    
                                }
                            }
                            ) : null}
                        </List>
                    </ListItem>
                   
                </List>
            </Box>


        </>

    )
}
