import React, { useEffect } from 'react';
import {
    List,
    Box, Grid, FormControl, TextField, Typography
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import {PortingService} from 'src/api/services';
import { useState } from 'react';
import BaseSnackbar from 'src/components/BaseSnackbar';
import BaseButton from "../../../components/buttons/BaseButton";
import {styled} from "@mui/system";
import {useStore} from "../../../store/Store";
import {useNavigate, useParams} from "react-router-dom";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

export default function TranslateNumber() {

    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [data, setData] = useState([{
        customer_translations: {
            landline: '',
            mobile: '',
            payphone: ''
        },
        did: ''
    }]);
    const [message, setMessage] = useState("");
    const [name, setName] = useState(store.detail.name);
    const [surname, setSurname] = useState(store.detail.surname);
    const [showPassword, setShowPassword] = useState(false);
    const [text, setTextField] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const fetchPortingDetails = async () => {
        PortingService.listPortOutDetails(id)
            .then((response) => {
                if (response.data.meta.code !== 200) {
                    navigate(`./not-found`);
                }
                const items = [];
                response.data.data.numbers.map((item, index) => {
                    items.push({
                            customer_translations: {
                                landline: '',
                                mobile: '',
                                payphone: ''
                            },
                            did: item
                        });
                });
                console.log(items)
                setData(items);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    useEffect(() => {
        fetchPortingDetails();
        return () => {
            setData([]);
        };
    }, [id]);



    const updateData = () => {
            PortingService.translate(id, data)
                .then((response) => {
                    if(response.data.status == true){
                        navigate('/porting/port-in');
                        setMessage("Request sent successfully.");
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
    }


    const handleTextFieldChange = (index, type, value) => {
        const newData = [...data];
        newData[index].customer_translations[type] = value;
        setData(newData);
        console.log(newData);
    };


    return(
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : "white",
                border:'2em solid white',
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>
                <>
                    <List component="nav" aria-label="mailbox folders" >
                        <h1>Porting Number Translation</h1>
                        <br/>
                    </List>

                        {data.map((item, index) => (
                                <Grid container justifyContent="center" alignContent="center" alignItems="center" paddingBottom={2} spacing={2}>
                                <Grid item md={3} xs={12}>
                                    <Typography variant="subtitle1" >
                                        {item.did}
                                    </Typography>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Landline"
                                            variant="outlined"
                                            value={item.customer_translations.landline}
                                            onChange={(e) => handleTextFieldChange(index, 'landline', e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Mobile"
                                            variant="outlined"
                                            value={item.customer_translations.mobile}
                                            onChange={(e) => handleTextFieldChange(index, 'mobile', e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Payphone"
                                            variant="outlined"
                                            value={item.customer_translations.payphone}
                                            onChange={(e) => handleTextFieldChange(index, 'payphone', e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                            </Grid>
                        ))}


                    <br />
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Save'}
                                onClick={ () => updateData()} color={"--color-search"}
                            />
                        </SearchButtonContainer>
                    </Grid>
                </>
            </Box>
        </>
    )
}
