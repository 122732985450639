import PortalLayout from 'src/layouts/PortalLayout';
import { useTranslation } from 'react-i18next';
import SubCustomers from "./subcustomers";


export default function SubCustomerSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('Subcustomers'), component: <SubCustomers /> },
    ];

    const TITLE = t('Subcustomers');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
